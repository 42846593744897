import { setModal } from 'actions';
import { message } from 'antd';
import { gql } from '@apollo/client';
import { checkAccess, UserPermissions } from '@xbcb/client-utils';
import { AccountType, RecordType } from '@xbcb/shared-types';
import {
  UsCbpEntrySummaryStatus,
  UsPostSummaryCorrectionConfirmationTransactionType,
  WorkOrderTaskType,
} from '@xbcb/work-order-types';
import { ModalKey } from 'types';
import { executeMutation } from 'libs/executeMutation';
import { transformUsConsumptionEntry } from 'libs/formTransforms';
import {
  queryUsPostSummaryCorrectionCbp,
  upsertUsPostSummaryCorrectionCbp,
  usPostSummaryCorrectionFields,
  usPostSummaryCorrectionFragments,
} from 'libs/sharedQueries';
import validateUsPostSummaryCorrection from 'libs/validateUsPostSummaryCorrection';
import { disableWorkOrderTaskCompletionButton } from 'libs/workOrderTaskCompletionButton';
import {
  isRecordCanceled,
  hasEntryNumber,
  hasEntrySummaryStatus,
  isRecordDeleted,
} from 'libs/workOrderConditions';
import UsPostSummaryCorrectionSecondaryHeader from 'components/UsPostSummaryCorrectionSecondaryHeader';
import { UsPostSummaryCorrection } from '../../loadableComponents';
import { AppRecordOnConfirmPayload } from '../../types';
import {
  findWorkOrderTaskAndModifyStepCompletion,
  appRecordAbiDataMenuItem,
  appRecordAssignWorkOrderTaskMenuItem,
} from '../utils';
import MOTMenuOptions from 'components/MOTMenuOptions';
import { WorkOrderRoute } from '.';
import { checkKebabMenuItemForWorkOrderTask } from '../utils/checkKebabMenuItemForWorkOrderTask';
import { completeWorkOrderTaskButton } from '../utils/completeWorkOrderTaskButton';
import { appRecordSendAbiMessage } from '../utils/appRecordSendAbiMessage';
import { updateIorOnEntry } from '../../../libs/updateIorOnEntry';

const changeMot = gql`
  mutation ChangeUsConsumptionEntryMot(
    $id: ID!
    $version: Int!
    $input: UpdateUsConsumptionEntryInput!
  ) {
    updateUsConsumptionEntry(id: $id, version: $version, input: $input) {
      record {
        id
        version
        conveyance {
          modeOfTransport
        }
      }
    }
  }
`;

const WORK_ORDER_TASK_TYPES_SHOW_UPDATE: WorkOrderTaskType[] = [
  WorkOrderTaskType.US_POST_SUMMARY_CORRECTION_FILING,
  WorkOrderTaskType.ACE_SYSTEM_ERROR_EXCEPTION,
];

const usPostSummaryCorectionRoute: WorkOrderRoute = {
  Page: UsPostSummaryCorrection,
  recordType: RecordType.US_POST_SUMMARY_CORRECTION,
  getTitleField: () => 'entryNumber',
  fields: usPostSummaryCorrectionFields,
  fragments: usPostSummaryCorrectionFragments,
  transformUpdateRecordInput: transformUsConsumptionEntry.toSchema,
  transformRecordToInput: transformUsConsumptionEntry.toForm,
  validateUpdate: validateUsPostSummaryCorrection,
  SecondaryHeaderContents: [UsPostSummaryCorrectionSecondaryHeader],
  kebabMenuItems: [
    appRecordAbiDataMenuItem,
    appRecordAssignWorkOrderTaskMenuItem,
    appRecordSendAbiMessage,
    {
      text: 'Query Entry',
      key: 'queryEntry',
      action: async ({ record }) => {
        await executeMutation({
          mutation: queryUsPostSummaryCorrectionCbp,
          variables: { usPostSummaryCorrectionId: record.id },
        });
      },
      show: ({
        existingRecord,
        currentUser,
        workOrderTaskType,
        workOrderTaskEvaluatedState,
      }) =>
        currentUser.accountType === AccountType.OPERATOR &&
        hasEntryNumber(existingRecord) &&
        checkKebabMenuItemForWorkOrderTask(
          workOrderTaskType,
          workOrderTaskEvaluatedState,
        ),
    },
    {
      text: 'Change MOT',
      key: 'changeMot',
      action: async ({ record, props }) => {
        const originalMot = record.conveyance.modeOfTransport;
        if (!props?.key) {
          void message.error(
            `Sorry, an error occurred, please try again later.`,
            5.0,
          );
        }
        await executeMutation({
          mutation: changeMot,
          variables: {
            id: record.id,
            version: record.version,
            input: {
              conveyance: {
                modeOfTransport: props!.key.toUpperCase(),
              },
            },
          },
          successMessage: `MOT changed from ${originalMot.toLowerCase()} to ${
            props!.key
          }`,
        });
      },
      show: ({
        existingRecord,
        currentUser,
        workOrderTaskType,
        workOrderTaskEvaluatedState,
      }) =>
        currentUser.accountType === AccountType.OPERATOR &&
        checkAccess(
          currentUser,
          RecordType.US_POST_SUMMARY_CORRECTION,
          UserPermissions.UPDATE,
        ) &&
        !isRecordCanceled(existingRecord) &&
        existingRecord?.summaryStatus !== UsCbpEntrySummaryStatus.FILED &&
        checkKebabMenuItemForWorkOrderTask(
          workOrderTaskType,
          workOrderTaskEvaluatedState,
        ),
      SubMenuOptions: MOTMenuOptions,
    },
  ],
  editModes: [
    {
      key: 'updateEntry',
      submitText: 'Update entry',
      cancelText: 'Cancel entry update',
      onConfirm: ({ dispatch, isValid, editModeParams }) =>
        new Promise<AppRecordOnConfirmPayload>((resolve) => {
          if (isValid) {
            dispatch(
              setModal({
                key: ModalKey.VALIDATE_US_ENTRY_SUMMARY,
                props: {
                  visible: true,
                  sendConfirmationDecision: resolve,
                  updatingEntry: true,
                  transactionType:
                    UsPostSummaryCorrectionConfirmationTransactionType.UPSERT_US_POST_SUMMARY_CORRECTION_SUMMARY_CBP,
                },
              }),
            );
          } else {
            return resolve({ canceled: true });
          }
        }),
      onSubmit: async ({ updatedRecord, editModeParams }) => {
        await executeMutation({
          mutation: upsertUsPostSummaryCorrectionCbp,
          variables: {
            usPostSummaryCorrectionId: updatedRecord.id,
          },
        });
      },
    },
  ],
  submitButtons: [
    completeWorkOrderTaskButton(),
    {
      key: 'submitEntry',
      text: 'Submit entry',
      show: ({ existingRecord, editMode, currentUser, workOrderTaskType }) => {
        return (
          (!workOrderTaskType ||
            workOrderTaskType ===
              WorkOrderTaskType.US_POST_SUMMARY_CORRECTION_FILING) &&
          !hasEntrySummaryStatus(existingRecord) &&
          editMode === 'draft' &&
          !isRecordDeleted(existingRecord) &&
          !isRecordCanceled(existingRecord) &&
          !currentUser.loading &&
          checkAccess(
            currentUser,
            RecordType.US_POST_SUMMARY_CORRECTION,
            UserPermissions.UPSERT_CBP_TRANSACTION,
          )
        );
      },
      disable: ({ existingRecord, workOrderTaskType }) => {
        if (workOrderTaskType) {
          return disableWorkOrderTaskCompletionButton(
            existingRecord,
            workOrderTaskType,
          );
        }
        return false;
      },
      preSubmit: async ({ existingRecord, form }): Promise<void> => {
        await updateIorOnEntry(
          { ior: { usIor: { id: existingRecord.ior.usIor.id } } },
          form,
          true, // Don't add US entry only fields
        );
      },
      onSubmit: async ({ updatedRecord, workOrderTaskType }) => {
        if (workOrderTaskType) {
          await findWorkOrderTaskAndModifyStepCompletion({
            workOrder: updatedRecord,
            workOrderTaskType,
          });
        }
        await executeMutation({
          mutation: upsertUsPostSummaryCorrectionCbp,
          variables: {
            usPostSummaryCorrectionId: updatedRecord.id,
          },
        });
      },
      onConfirm: ({ dispatch, isValid }) =>
        new Promise<AppRecordOnConfirmPayload>((resolve, reject) => {
          if (isValid) {
            dispatch(
              setModal({
                key: ModalKey.VALIDATE_US_ENTRY_SUMMARY,
                props: {
                  visible: true,
                  sendConfirmationDecision: resolve,
                  updatingEntry: false,
                  transactionType:
                    UsPostSummaryCorrectionConfirmationTransactionType.UPSERT_US_POST_SUMMARY_CORRECTION_SUMMARY_CBP,
                },
              }),
            );
          } else {
            return resolve({ canceled: true });
          }
        }),
    },
  ],
  editButtons: [
    {
      key: 'updateEntry',
      text: 'Update entry',
      show: ({ existingRecord, workOrderTaskType }) =>
        (!workOrderTaskType ||
          WORK_ORDER_TASK_TYPES_SHOW_UPDATE.includes(
            workOrderTaskType as WorkOrderTaskType,
          )) &&
        hasEntrySummaryStatus(existingRecord) &&
        !isRecordCanceled(existingRecord) &&
        !isRecordDeleted(existingRecord),
    },
  ],
  defaultEditMode: false,
  draftEditMode: ({ existingRecord }) => !existingRecord?.summaryStatus,
  getAdditionalWorkOrderInfo: ({ record }) => {
    const { entryNumber, summaryStatus } = record;

    return [
      {
        label: 'Entry #',
        value: entryNumber,
      },
    ];
  },
};

export default usPostSummaryCorectionRoute;
