import {
  WorkOrderTaskStatus,
  WorkOrderTaskSubStatus,
} from '@xbcb/work-order-types';
import { isCustomsEntryOrDeclaration } from '@xbcb/work-order-utils/dist/isCustomsEntryOrDeclaration';
import { fetchLatestRecordAndExecuteMutation } from 'libs/fetchLatestRecordAndExecuteMutation';
import { AppRecordSubmitButton } from 'routes';
import { RecordType } from '@xbcb/shared-types';
import { safeGetMessage } from '@xbcb/ui-utils';
import { updateWorkOrderTaskStatus } from 'libs/sharedQueries';
import { findMatchingWorkOrderTask } from 'libs/findMatchingWorkOrderTask';
import { WorkOrderTaskEvaluatedState } from 'libs/evaluateWorkOrderTaskState';

export const startWorkOrderTaskButton = (): AppRecordSubmitButton => {
  const workOrderButton: AppRecordSubmitButton = {
    key: 'openTask',
    text: ({ appRecordBundle }) => safeGetMessage(appRecordBundle, 'open_task'),
    show: ({ workOrderTaskEvaluatedState, existingRecord }) => {
      return (
        !isCustomsEntryOrDeclaration(existingRecord.id) &&
        workOrderTaskEvaluatedState ===
          WorkOrderTaskEvaluatedState.READY_TO_START
      );
    },
    skipValidation: () => true,
    skipUpdateRecord: () => true,
    onSubmit: async ({ updatedRecord, workOrderTaskType }) => {
      if (workOrderTaskType) {
        const workOrderTask = findMatchingWorkOrderTask(
          updatedRecord,
          workOrderTaskType,
        );
        await fetchLatestRecordAndExecuteMutation({
          id: workOrderTask?.id,
          fields: 'status',
          recordType: 'WORK_ORDER_TASK' as RecordType,
          mutation: updateWorkOrderTaskStatus,
          successMessage: `Work Order Task is now actively in progress`,
          constructVariablesWithIdVersion: (id, version) => ({
            id,
            version,
            input: {
              status: WorkOrderTaskStatus.IN_PROGRESS,
              subStatus: WorkOrderTaskSubStatus.ACTIVELY_IN_PROGRESS,
            },
          }),
        });
      }
    },
  };
  return workOrderButton;
};

export const stopWorkOrderTaskButton = (): AppRecordSubmitButton => {
  const workOrderButton: AppRecordSubmitButton = {
    key: 'pauseTask',
    text: ({ appRecordBundle }) =>
      safeGetMessage(appRecordBundle, 'pause_task'),
    show: ({ workOrderTaskEvaluatedState, existingRecord }) => {
      return (
        !isCustomsEntryOrDeclaration(existingRecord.id) &&
        workOrderTaskEvaluatedState === WorkOrderTaskEvaluatedState.STARTED
      );
    },
    skipValidation: () => true,
    skipUpdateRecord: () => true,
    onSubmit: async ({ updatedRecord, workOrderTaskType }) => {
      if (workOrderTaskType) {
        const workOrderTask = findMatchingWorkOrderTask(
          updatedRecord,
          workOrderTaskType,
        );
        await fetchLatestRecordAndExecuteMutation({
          id: workOrderTask?.id,
          fields: 'status',
          recordType: 'WORK_ORDER_TASK' as RecordType,
          mutation: updateWorkOrderTaskStatus,
          successMessage: `Work Order Task is no longer actively in progress`,
          constructVariablesWithIdVersion: (id, version) => ({
            id,
            version,
            input: {
              status: WorkOrderTaskStatus.IN_PROGRESS,
              subStatus: WorkOrderTaskSubStatus.PASSIVELY_IN_PROGRESS,
            },
          }),
        });
      }
    },
  };
  return workOrderButton;
};
