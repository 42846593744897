import { pick } from 'lodash';
import { AnyObject } from '@xbcb/shared-types';
import { transformWorkOrderBase } from './base';
import { transformLines } from './usConsumptionEntry/lines';

export * from './usConsumptionEntry/lines';

export const transformUsType86Entry = {
  toForm: ({ existingRecord }: { existingRecord: AnyObject }) => {
    const newRecord = transformWorkOrderBase.toForm({ existingRecord });
    if (newRecord.arrival?.inBond) {
      newRecord.arrival.isIt = true;
    }
    if (newRecord.exam?.containers) {
      const transformedContainers = newRecord.exam.containers.map(
        (container: { number: string }) => container.number,
      );
      newRecord.exam.containers = transformedContainers;
    }
    newRecord.invoices?.forEach((invoice: any) => {
      invoice.currency = invoice.value?.currency;
      invoice.products?.forEach((product: any) => {
        transformLines.toForm({ lines: product.lines });
      });
    });
    const continuousBonds = newRecord.ior?.usIor?.continuousBonds; // TODO refactor RecordSelect nesting to fix this.
    if (continuousBonds) {
      newRecord.ior.continuousBonds = continuousBonds;
    }
    return newRecord;
  },
  toSchema: ({
    input,
    existingRecord,
  }: {
    input: AnyObject;
    existingRecord: AnyObject;
  }) => {
    const cleanedOutput = transformWorkOrderBase.toSchema({
      input,
      existingRecord,
    });
    delete cleanedOutput.broker;
    delete cleanedOutput.arrival?.isIt;
    delete cleanedOutput.ior?.continuousBonds; // we don't transmit this field, but we need to fetch it and keep it in the form to know whether to render the Bond tab.
    delete cleanedOutput.badges;
    if (cleanedOutput.exam?.containers) {
      cleanedOutput.exam.containers = cleanedOutput.exam.containers.map(
        (examContainerNumber: string) => {
          const foundContainer = cleanedOutput.containers?.find(
            (container: { number: string }) =>
              container.number === examContainerNumber,
          );
          return foundContainer || { number: examContainerNumber };
        },
      );
    }
    // we do not have duns, unknownDuns, payerUnitNumber, pmsStatus & preferredPayments in Type86 ior, so skip
    delete cleanedOutput.ior?.duns;
    delete cleanedOutput.ior?.unknownDuns;
    delete cleanedOutput.ior?.payerUnitNumber;
    delete cleanedOutput.ior?.pmsStatus;
    delete cleanedOutput.ior?.preferredPaymentMethod;

    cleanedOutput.invoices?.forEach((invoice: any) => {
      delete invoice.currency;

      invoice.products?.forEach((product: any) => {
        delete product.totalReporting;
        delete product.product?.clientIdentifier;
        transformLines.toSchema({ lines: product.lines });
      });
    });
    if (cleanedOutput.ior?.usIor) {
      // usIor is an IdVersion input
      cleanedOutput.ior.usIor = pick(cleanedOutput.ior.usIor, [
        'id',
        'version',
      ]);
    }
    return cleanedOutput;
  },
};
