import { UsPgaFlag } from '__generated__/graphql';

export class PgaFlag {
  private static VNE_FLAGS = new Set([UsPgaFlag.EP3, UsPgaFlag.EP4]);
  private static PESTICIDES_FLAGS = new Set([UsPgaFlag.EP5, UsPgaFlag.EP6]);
  private static TSCA_FLAGS = new Set([
    UsPgaFlag.EP1,
    UsPgaFlag.EP2,
    UsPgaFlag.EP7,
    UsPgaFlag.EP8,
  ]);

  static subjectToVehiclesAndEngines(flag: UsPgaFlag): boolean {
    return this.VNE_FLAGS.has(flag);
  }

  static subjectToPesticides(flag: UsPgaFlag): boolean {
    return this.PESTICIDES_FLAGS.has(flag);
  }

  static subjectToTsca(flag: UsPgaFlag): boolean {
    return this.TSCA_FLAGS.has(flag);
  }
}
