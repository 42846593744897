import { formatRecordName, getRecordNameStringId } from '@xbcb/js-utils';
import { AccountType, RecordType } from '@xbcb/shared-types';
import { MessageBundle } from '@xbcb/ui-types';
import { safeGetMessage } from '@xbcb/ui-utils';

export const getLocalizedRecordName = (
  bundle: MessageBundle,
  recordType: RecordType,
  accountType: AccountType,
  plural = false,
) =>
  safeGetMessage(
    bundle,
    getRecordNameStringId({ recordType, accountType }),
    { plural },
    formatRecordName({
      recordType,
      accountType,
      pluralize: plural,
    }),
  );
