import { SearchQuery } from '@xbcb/shared-queries';
import {
  RecordType,
  SortOrder,
  KeywordSearchOperator,
} from '@xbcb/shared-types';
import type { SearchProcessingErrorTableInput } from '@xbcb/api-gateway-client';

type SearchProcessingErrorsQueryOptions = {
  variables: {
    input: SearchProcessingErrorTableInput;
  };
};

export const getSearchProcessingErrorsQueryOptions: (
  recordId: string,
) => SearchProcessingErrorsQueryOptions = (recordId: string) => ({
  variables: {
    input: {
      searchCriteria: {
        status: {
          values: ['RESOLVED'],
          operator: KeywordSearchOperator.NOT_EQUALS,
        },
        tags: {
          values: [
            {
              key: 'affectedRecordId',
              value: recordId,
            },
          ],
          operator: 'EQUALS',
        },
      },
      sortOptions: [{ field: 'createdTime', order: SortOrder.DESC }],
    },
  },
});

export const searchProcessingErrorsQuery = SearchQuery({
  recordName: RecordType.PROCESSING_ERROR,
  fields: 'name created { time source { version } } tags { key value }',
});
