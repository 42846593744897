import React, { useState } from 'react';
import styled from 'styled-components';
import { Form, message, Modal, Button } from 'antd';
import { v4 as uuidV4 } from 'uuid';
import { CBPNumberReasonSelect } from '@xbcb/form-item-components';
import { requestUsIorCbpAssignedNumber } from 'libs/sharedQueries';
import { useMutation } from '@apollo/client';
import { reportError, useModal, safeGetMessage } from '@xbcb/ui-utils';
import { ModalKey } from 'types';
import { useBundle } from '@amzn/react-arb-tools';

export const StyledForm = styled(Form)`
  .ant-form-item {
    width: 100%;
  }
`;

interface RequestCbpNumberModalProps {
  cbpNumberReasons?: string[];
}

const RequestCbpNumberModal: React.FC<RequestCbpNumberModalProps> = ({
  cbpNumberReasons,
}) => {
  const [partyBundle] = useBundle('components.Party');
  const [isLoading, setIsLoading] = useState(false);
  const [requestCbpNumber] = useMutation(requestUsIorCbpAssignedNumber);
  const [form] = Form.useForm();
  const { visible, closeModal, modalProps } = useModal(
    ModalKey.REQUEST_CBP_NUMBER,
  );

  const handleSubmit = async (evt: any) => {
    evt.preventDefault();
    setIsLoading(true);

    try {
      await form.validateFields();
    } catch (errorInfo) {
      void message.error(
        safeGetMessage(partyBundle, 'please_fill_out_the_form'),
      );
      setIsLoading(false);
      return;
    }
    try {
      await requestCbpNumber({
        variables: {
          idempotencyKey: uuidV4(),
          usIorId: modalProps.usIorId,
          // eslint-disable-next-line
          // @ts-ignore
          input: form.getFieldValue(),
        },
      });
      handleClose();
    } catch (e) {
      reportError(e);
    }
    setIsLoading(false);
  };

  const handleClose = () => {
    closeModal();
    form.resetFields();
  };

  return (
    <Modal
      maskClosable={true}
      open={visible}
      width={768}
      closable={true}
      destroyOnClose={true}
      footer={null}
      onCancel={handleClose}
    >
      <StyledForm form={form}>
        <CBPNumberReasonSelect
          form={form}
          disabled={isLoading}
          initialValue={cbpNumberReasons}
        />
        <div>
          <Button
            onClick={handleSubmit}
            loading={isLoading}
            htmlType="submit"
            size="large"
          >
            {safeGetMessage(partyBundle, 'request_cbp_number')}
          </Button>
        </div>
      </StyledForm>
    </Modal>
  );
};

export default RequestCbpNumberModal;
