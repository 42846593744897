import { Modal } from 'antd';
import { uncancelOneMutation } from '@xbcb/shared-queries';
import { AppRecordSubmitButton } from 'routes';
import { RecordType, RecordStatus, ObjectType } from '@xbcb/shared-types';
import { AppRecordOnConfirmPayload } from '../../types';
import { executeMutation } from 'libs/executeMutation';
import { checkAccess, UserPermissions } from '@xbcb/client-utils';
import { pascalCase } from 'change-case';
import { safeGetMessage } from '@xbcb/ui-utils';
import { getLocalizedRecordName } from 'libs/localizationHelpers';

export const uncancelWorkOrderButton = (
  recordType: RecordType,
): AppRecordSubmitButton => {
  const workOrderButton: AppRecordSubmitButton = {
    key: `uncancel${pascalCase(recordType)}`,
    text: ({ currentUser, appRecordBundle, recordNameBundle }) => {
      const recordName = getLocalizedRecordName(
        recordNameBundle,
        recordType,
        currentUser.accountType,
      );
      return safeGetMessage(appRecordBundle, 'reopen_record', { recordName });
    },
    show: ({ existingRecord, currentUser }) => {
      return (
        existingRecord?.status === RecordStatus.CANCELED &&
        !currentUser.loading &&
        checkAccess(currentUser, recordType, UserPermissions.UPDATE)
      );
    },
    skipValidation: () => true,
    skipUpdateRecord: () => true,
    onSubmit: async ({
      updatedRecord,
      currentUser,
      appRecordBundle,
      recordNameBundle,
    }) => {
      const uncancelWorkOrderMutation = uncancelOneMutation({
        recordName: ObjectType.WORK_ORDER,
      });
      const recordName = getLocalizedRecordName(
        recordNameBundle,
        recordType,
        currentUser.accountType,
      );
      await executeMutation({
        mutation: uncancelWorkOrderMutation,
        variables: {
          id: updatedRecord.id,
          version: updatedRecord.version,
        },
        successMessage: safeGetMessage(appRecordBundle, 'record_uncanceled', {
          recordName,
        }),
      });
    },
    onConfirm: ({
      currentUser,
      isValid,
      appRecordBundle,
      recordNameBundle,
      sharedBundle,
    }) =>
      new Promise<AppRecordOnConfirmPayload>((resolve, reject) => {
        if (isValid) {
          const recordName = getLocalizedRecordName(
            recordNameBundle,
            recordType,
            currentUser.accountType,
          );
          Modal.confirm({
            title: safeGetMessage(appRecordBundle, 'reopen_record', {
              recordName,
            }),
            content: safeGetMessage(appRecordBundle, 'confirm_uncancel', {
              recordName,
              isShipment: false,
            }),
            className: 'uncancel-work-order-modal',
            okText: safeGetMessage(sharedBundle, 'yes'),
            cancelText: safeGetMessage(sharedBundle, 'no'),
            onOk: async () => {
              resolve({ canceled: false });
            },
          });
        } else {
          return resolve({ canceled: true });
        }
      }),
  };
  return workOrderButton;
};
