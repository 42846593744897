import type { UsHts } from '@xbcb/api-gateway-client';
import { client } from '@xbcb/apollo-client';
import { formatHTS, isValidHtsNumber } from '@xbcb/hts-client-utils';
import { chunk } from 'lodash';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setHtsData } from 'actions';
import { useTypedSelector } from 'reducers';
import { DEFAULT_EFFECTIVE_DATE } from 'libs/dateUtils';
import { getAdditionalHtsFromResponse } from 'libs/htsHelpers';
import { getEffectiveUsHtssInput, EFFECTIVE_US_HTS } from 'libs/sharedQueries';
import { HtsData } from 'types';

const CHUNK_SIZE = 100;

export const query = async (
  htsNumbers: string[],
  effectiveDate: string,
): Promise<UsHts[]> => {
  const results: UsHts[] = [];
  const chunks: string[][] = chunk(htsNumbers, CHUNK_SIZE);
  for (const chunk of chunks) {
    const result = await client.query({
      query: EFFECTIVE_US_HTS,
      variables: {
        input: getEffectiveUsHtssInput(chunk, effectiveDate),
        effectiveDate,
      },
    });
    results.push(...(result?.data?.effectiveUsHtss ?? []));
  }
  return results;
};

export interface UseHtsQuery {
  htsDataLoading: boolean;
  queryEffectiveUsHtss: (
    htsNumbers: string[],
    effectiveDate?: string | null,
  ) => Promise<HtsData>;
  getEffectiveUsHtssData: (effectiveDate?: string) => HtsData;
}
export const useHtsQuery = (): UseHtsQuery => {
  const [htsDataLoading, setHtsDataLoading] = useState(false);
  const dispatch = useDispatch();
  const hierarchy = useTypedSelector(
    (state) => state.hts.usHtsHierarchy.htsHierarchy,
  );
  const data = useTypedSelector((state) => state.hts.usHtsData);

  const resolve = (returnValue: HtsData) => {
    setHtsDataLoading(false);
    return Promise.resolve(returnValue);
  };

  const queryEffectiveUsHtss = useCallback(
    async (htsNumbers: string[], effectiveDate?: string | null) => {
      setHtsDataLoading(true);
      // Only fetch from TDA for complete numbers
      const completeNumbers = htsNumbers.filter((number) =>
        isValidHtsNumber(number, hierarchy),
      );
      if (!completeNumbers.length) {
        return resolve({});
      }

      const formattedNumbers = completeNumbers.map(formatHTS);
      const safeEffectiveDate = effectiveDate ?? DEFAULT_EFFECTIVE_DATE;
      const cachedData = data[safeEffectiveDate] ?? {};

      // Build up a result starting from cached data
      let result = formattedNumbers.reduce((accum, current) => {
        const cachedValue = cachedData[current];
        if (cachedValue) {
          accum[current] = cachedValue;
        }
        return accum;
      }, {} as HtsData);

      const filteredNumbers = formattedNumbers.filter(
        (number) => !(number in cachedData),
      );
      if (filteredNumbers.length === 0) {
        // No need to query
        return resolve(cachedData);
      }

      const response = await query(filteredNumbers, safeEffectiveDate);
      result = getAdditionalHtsFromResponse(result, response);
      dispatch(setHtsData({ effectiveDate: safeEffectiveDate, data: result }));
      setHtsDataLoading(false);
      return result;
    },
    [hierarchy, dispatch, data],
  );

  const getEffectiveUsHtssData = useCallback(
    (effectiveDate?: string) => {
      const result = effectiveDate
        ? data[effectiveDate]
        : data[DEFAULT_EFFECTIVE_DATE];
      return result ?? {};
    },
    [data],
  );

  return {
    htsDataLoading,
    queryEffectiveUsHtss,
    getEffectiveUsHtssData,
  };
};

export type { HtsData };
