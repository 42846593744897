import React from 'react';
import { AppRecordSecondaryHeaderContentProps } from 'routes';
import { getBadgeIcons } from 'components/AppRecordBadge';
import { getWorkOrderBadges } from 'libs/getRecordBadges';
import { StyledDiv, StyledSpan } from './styles';
import { Tooltip } from 'antd';
import MOTIcon from 'components/MOTIcon';
import titleCase from 'libs/titleCase';

const CustomsEntrySecondaryHeader: React.FC<
  AppRecordSecondaryHeaderContentProps
> = ({ record }) => {
  const badgeIcons = getBadgeIcons(record, getWorkOrderBadges);
  const goodsShipment = record?.goodsShipment?.[0] || {};
  const consignment = goodsShipment.consignment?.[0] || {};
  const borderTransportationMean =
    consignment.borderTransportationMean?.[0] || {};

  const modeOfTransportation = borderTransportationMean?.identificationType;
  const motTitle = titleCase(modeOfTransportation);

  return (
    <StyledDiv>
      <Tooltip title={motTitle} placement="top">
        <StyledSpan>
          <MOTIcon mot={modeOfTransportation} size="18" />
        </StyledSpan>
      </Tooltip>
      {badgeIcons}
    </StyledDiv>
  );
};

export default CustomsEntrySecondaryHeader;
