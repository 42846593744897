import { RecordType, TagValue, TagKey } from '@xbcb/shared-types';
import { WorkOrderTab } from '@xbcb/ui-types';
import {
  DeCustomsEntryTaskType,
  EuCustomsEntryExceptionTaskType,
  FrCustomsEntryTaskType,
  GbCustomsEntryTaskType,
  NlCustomsEntryTaskType,
  WorkOrderTaskType,
} from '@xbcb/work-order-types';
import { isFfcdCustomsMilestonesEnabled } from 'libs/featureFlags/ffcdCustomMilestonesFeatureFlags';
import {
  JP,
  IN,
  NL,
  DEFAULT,
  defaultIorActivationTabs,
  iorActivationTaskTypeTabs,
  defaultEuEntryWorkOrderTabs,
  getBaseEuCustomsEntryConfiguration,
  defaultEuEntryWorkOrderTabsForFbaScotForwardingOps,
} from './helpers';
import type { WorkOrderGroupForwarder } from '@xbcb/api-gateway-client';

type WorkOrderTabConfig = Record<
  string, // Top-level key: DEFAULT or TagValue.FBA_SCOT_FORWARDING
  Record<
    string, // RecordType
    Record<
      string, // CountryCode or DEFAULT
      Record<
        string, // WorkOrderTaskType or DEFAULT
        WorkOrderTab[]
      >
    >
  >
>;

export const workOrderTabConfiguration: WorkOrderTabConfig = {
  [DEFAULT]: {
    [RecordType.US_POST_SUMMARY_CORRECTION]: {
      [DEFAULT]: {
        [DEFAULT]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Bond,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
          WorkOrderTab.Tasks,
          WorkOrderTab.Summary,
          WorkOrderTab.PSC,
        ],
        [WorkOrderTaskType.ACE_SYSTEM_ERROR_EXCEPTION]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Bond,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
        ],
        [WorkOrderTaskType.US_POST_SUMMARY_CORRECTION_FILING]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Bond,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
          WorkOrderTab.PSC,
        ],
      },
    },
    [RecordType.US_CONSUMPTION_ENTRY]: {
      [DEFAULT]: {
        [DEFAULT]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Bond,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
          WorkOrderTab.ExamOrHold,
          WorkOrderTab.Inbox,
          WorkOrderTab.Tasks,
          WorkOrderTab.Summary,
          WorkOrderTab.AuditLogs,
        ],
        [WorkOrderTaskType.ACE_SYSTEM_ERROR_EXCEPTION]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Bond,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
        ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_BUILD_TRANSPORTATION_AND_INVOICE]:
          [
            WorkOrderTab.Documents,
            WorkOrderTab.Bond,
            WorkOrderTab.Transportation,
            WorkOrderTab.CommercialInvoices,
            WorkOrderTab.Summary,
          ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_BUILD_INVOICE]: [
          WorkOrderTab.Documents,
          WorkOrderTab.CommercialInvoices,
        ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_CARGO_RELEASE_EXCEPTION]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Bond,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
        ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_CBP_EXAM]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Milestones,
          WorkOrderTab.Bond,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
          ...(isFfcdCustomsMilestonesEnabled ? [WorkOrderTab.ExamOrHold] : []),
          ...(isFfcdCustomsMilestonesEnabled
            ? [WorkOrderTab.CustomFields]
            : []),
          WorkOrderTab.Summary,
        ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_CBP_HOLD]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Milestones,
          WorkOrderTab.Bond,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
          WorkOrderTab.CustomFields,
          ...(isFfcdCustomsMilestonesEnabled
            ? [WorkOrderTab.ExamOrHold, WorkOrderTab.CustomFields]
            : []),
          WorkOrderTab.Summary,
        ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_COMPLETE_INVOICE]: [
          WorkOrderTab.Documents,
          WorkOrderTab.CommercialInvoices,
        ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_DOCUMENT_TAGGING]: [
          WorkOrderTab.Documents,
          WorkOrderTab.CommercialInvoices,
        ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_FILING]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
        ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_PGA_HOLD]: [
          WorkOrderTab.Milestones,
          WorkOrderTab.CommercialInvoices,
          ...(isFfcdCustomsMilestonesEnabled
            ? [WorkOrderTab.ExamOrHold, WorkOrderTab.CustomFields]
            : []),
        ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_PGA_REJECTION]: [
          WorkOrderTab.CommercialInvoices,
        ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_POST_SUMMARY_CORRECTION]: [
          WorkOrderTab.Bond,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
        ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_PROTEST]: [
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
        ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_SHIPMENT_INFO]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Transportation,
        ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_EXCEPTION]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Milestones,
          WorkOrderTab.Bond,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
          WorkOrderTab.Summary,
        ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_REVISION]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Bond,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
          WorkOrderTab.Summary,
        ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_COMPLETION_SUBMISSION]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
        ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_STATEMENT_PROCESSING_EXCEPTION]:
          [
            WorkOrderTab.Documents,
            WorkOrderTab.Bond,
            WorkOrderTab.Transportation,
            WorkOrderTab.CommercialInvoices,
            WorkOrderTab.Summary,
          ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_PAYMENT_PROCESSING_EXCEPTION]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Bond,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
          WorkOrderTab.Summary,
        ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_STATEMENT_DATE_CALCULATION_EXCEPTION]:
          [
            WorkOrderTab.Documents,
            WorkOrderTab.Bond,
            WorkOrderTab.Transportation,
            WorkOrderTab.CommercialInvoices,
            WorkOrderTab.Summary,
          ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_PGA_EXCEPTION]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Bond,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
        ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_CENSUS_WARNING]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
        ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_CBP_CANCELLATION]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Bond,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
          WorkOrderTab.Summary,
        ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_AUTO_FLIP_EXCEPTION]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
          WorkOrderTab.Summary,
        ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_REQUEST_FOR_INFORMATION]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
          WorkOrderTab.Summary,
          WorkOrderTab.CustomFields,
          WorkOrderTab.Milestones,
        ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_NOTICE_OF_PROPOSED_ACTION]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
          WorkOrderTab.Summary,
          WorkOrderTab.CustomFields,
          WorkOrderTab.Milestones,
        ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_NOTICE_OF_ACTION_TAKEN]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
          WorkOrderTab.Summary,
          WorkOrderTab.CustomFields,
          WorkOrderTab.Milestones,
        ],
        [WorkOrderTaskType.US_CONSUMPTION_ENTRY_LIQUIDATED_DAMAGES]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
          WorkOrderTab.Summary,
          WorkOrderTab.CustomFields,
          WorkOrderTab.Milestones,
        ],
      },
    },
    [RecordType.US_TYPE86_ENTRY]: {
      [DEFAULT]: {
        [DEFAULT]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
          WorkOrderTab.ExamOrHold,
          WorkOrderTab.Tasks,
          WorkOrderTab.Summary,
        ],
        [WorkOrderTaskType.US_TYPE86_ENTRY_COMPLETION_AND_SUBMISSION]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
          WorkOrderTab.Tasks,
          WorkOrderTab.Summary,
        ],
        [WorkOrderTaskType.US_TYPE86_ENTRY_EXCEPTION]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Milestones,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
          WorkOrderTab.Tasks,
          WorkOrderTab.Summary,
        ],
        [WorkOrderTaskType.US_TYPE86_ENTRY_CBP_HOLD]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Milestones,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
          WorkOrderTab.Tasks,
          ...(isFfcdCustomsMilestonesEnabled
            ? [WorkOrderTab.ExamOrHold, WorkOrderTab.CustomFields]
            : []),
          WorkOrderTab.Summary,
        ],
        [WorkOrderTaskType.US_TYPE86_ENTRY_CBP_EXAM]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Milestones,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
          WorkOrderTab.Tasks,
          ...(isFfcdCustomsMilestonesEnabled
            ? [WorkOrderTab.ExamOrHold, WorkOrderTab.CustomFields]
            : []),
          WorkOrderTab.Summary,
        ],
        [WorkOrderTaskType.US_TYPE86_ENTRY_PGA_HOLD]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Milestones,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
          WorkOrderTab.Tasks,
          ...(isFfcdCustomsMilestonesEnabled
            ? [WorkOrderTab.ExamOrHold, WorkOrderTab.CustomFields]
            : []),
          WorkOrderTab.Summary,
        ],
        [WorkOrderTaskType.US_TYPE86_ENTRY_PGA_EXCEPTION]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
          WorkOrderTab.Tasks,
          WorkOrderTab.Summary,
        ],
        [WorkOrderTaskType.US_TYPE86_ENTRY_AUTO_FLIP_EXCEPTION]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Transportation,
          WorkOrderTab.CommercialInvoices,
          WorkOrderTab.Summary,
        ],
      },
    },
    [RecordType.US_ISF]: {
      [DEFAULT]: {
        [DEFAULT]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Bond,
          WorkOrderTab.ISF,
          WorkOrderTab.Tasks,
          WorkOrderTab.Summary,
        ],
        [WorkOrderTaskType.US_ISF_EXCEPTION]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Bond,
          WorkOrderTab.ISF,
          WorkOrderTab.Summary,
        ],
        [WorkOrderTaskType.US_ISF_FILING]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Bond,
          WorkOrderTab.ISF,
          WorkOrderTab.Summary,
        ],
      },
    },
    [RecordType.US_IOR_CONTINUOUS_BOND_REQUEST]: {
      [DEFAULT]: {
        [DEFAULT]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Tasks,
          WorkOrderTab.Summary,
        ],
        [WorkOrderTaskType.ACQUIRE_US_IOR_CONTINUOUS_BOND]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Summary,
        ],
        [WorkOrderTaskType.US_IOR_CONTINUOUS_BOND_CHARGE_BOND_RENEWAL_EXCEPTION]:
          [WorkOrderTab.Documents, WorkOrderTab.Summary],
        [WorkOrderTaskType.US_IOR_CONTINUOUS_BOND_VERIFY_RENEWAL_EXCEPTION]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Summary,
        ],
      },
    },
    [RecordType.US_IOR_ACTIVATION]: {
      [DEFAULT]: {
        [DEFAULT]: defaultIorActivationTabs,
        [WorkOrderTaskType.US_IOR_ACTIVATION]: iorActivationTaskTypeTabs,
      },
    },
    [RecordType.GB_IOR_ACTIVATION]: {
      [DEFAULT]: {
        [DEFAULT]: defaultIorActivationTabs,
        [WorkOrderTaskType.GB_IOR_ACTIVATION]: iorActivationTaskTypeTabs,
      },
    },
    [RecordType.DE_IOR_ACTIVATION]: {
      [DEFAULT]: {
        [DEFAULT]: defaultIorActivationTabs,
        [WorkOrderTaskType.DE_IOR_ACTIVATION]: iorActivationTaskTypeTabs,
      },
    },
    [RecordType.NL_IOR_ACTIVATION]: {
      [DEFAULT]: {
        [DEFAULT]: defaultIorActivationTabs,
        [WorkOrderTaskType.NL_IOR_ACTIVATION]: iorActivationTaskTypeTabs,
      },
    },
    [RecordType.FR_IOR_ACTIVATION]: {
      [DEFAULT]: {
        [DEFAULT]: defaultIorActivationTabs,
        [WorkOrderTaskType.FR_IOR_ACTIVATION]: iorActivationTaskTypeTabs,
      },
    },
    [RecordType.IMPORTER_ENTITY_ACTIVATION]: {
      [DEFAULT]: {
        [DEFAULT]: defaultIorActivationTabs,
        [WorkOrderTaskType.IMPORTER_ENTITY_ACTIVATION]:
          iorActivationTaskTypeTabs,
      },
    },
    [RecordType.US_IN_BOND]: {
      [DEFAULT]: {
        [DEFAULT]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Transportation,
          WorkOrderTab.Tasks,
          WorkOrderTab.CbpInBonds,
          WorkOrderTab.Summary,
        ],
        [WorkOrderTaskType.US_IN_BOND_FILING]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Transportation,
          WorkOrderTab.CbpInBonds,
          WorkOrderTab.Summary,
        ],
      },
    },
    [RecordType.DELIVERY_ORDER]: {
      [DEFAULT]: {
        [DEFAULT]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Tasks,
          WorkOrderTab.DeliveryOrder,
          WorkOrderTab.Summary,
        ],
        [WorkOrderTaskType.DELIVERY_ORDER_CREATION]: [
          WorkOrderTab.Documents,
          WorkOrderTab.DeliveryOrder,
          WorkOrderTab.Summary,
        ],
      },
    },
    [RecordType.GB_CUSTOMS_ENTRY]: {
      [DEFAULT]: {
        [DEFAULT]: defaultEuEntryWorkOrderTabs,
        ...getBaseEuCustomsEntryConfiguration(GbCustomsEntryTaskType),
        ...getBaseEuCustomsEntryConfiguration(EuCustomsEntryExceptionTaskType),
        [WorkOrderTaskType.GB_CUSTOMS_ENTRY_REVIEW_DOCUMENTS]: [
          WorkOrderTab.Documents,
        ],
      },
    },
    [RecordType.NL_CUSTOMS_ENTRY]: {
      [DEFAULT]: {
        [DEFAULT]: defaultEuEntryWorkOrderTabs,
        ...getBaseEuCustomsEntryConfiguration(NlCustomsEntryTaskType),
        ...getBaseEuCustomsEntryConfiguration(EuCustomsEntryExceptionTaskType),
        [WorkOrderTaskType.NL_CUSTOMS_ENTRY_REVIEW_DOCUMENTS]: [
          WorkOrderTab.Documents,
        ],
      },
    },
    [RecordType.FR_CUSTOMS_ENTRY]: {
      [DEFAULT]: {
        [DEFAULT]: defaultEuEntryWorkOrderTabs,
        ...getBaseEuCustomsEntryConfiguration(FrCustomsEntryTaskType),
        ...getBaseEuCustomsEntryConfiguration(EuCustomsEntryExceptionTaskType),
        [WorkOrderTaskType.FR_CUSTOMS_ENTRY_REVIEW_DOCUMENTS]: [
          WorkOrderTab.Documents,
        ],
      },
    },
    [RecordType.CUSTOMS_DECLARATION]: {
      [DEFAULT]: {
        [DEFAULT]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Milestones,
          WorkOrderTab.Tasks,
        ],
      },
      [IN]: {
        [DEFAULT]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Milestones,
          WorkOrderTab.Tasks,
          WorkOrderTab.Summary,
        ],
      },
      [JP]: {
        [DEFAULT]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Transportation,
          WorkOrderTab.Milestones,
          WorkOrderTab.Charges,
          WorkOrderTab.Tasks,
        ],
        [WorkOrderTaskType.CUSTOMS_DECLARATION_UPLOAD_DRAFT_ENTRY_DOCUMENT]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Transportation,
          WorkOrderTab.Milestones,
        ],
      },
      [NL]: {
        [DEFAULT]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Transportation,
          WorkOrderTab.Milestones,
          WorkOrderTab.Charges,
          WorkOrderTab.Tasks,
        ],
        [WorkOrderTaskType.CUSTOMS_DECLARATION_DOCUMENT_REVIEW]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Transportation,
          WorkOrderTab.Milestones,
        ],
        [WorkOrderTaskType.CUSTOMS_DECLARATION_BUILD_TRANSPORTATION_AND_INVOICE]:
          [
            WorkOrderTab.Documents,
            WorkOrderTab.Transportation,
            WorkOrderTab.Milestones,
          ],
        [WorkOrderTaskType.CUSTOMS_DECLARATION_FILING]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Transportation,
          WorkOrderTab.Milestones,
          WorkOrderTab.Charges,
        ],
        [WorkOrderTaskType.CUSTOMS_DECLARATION_DUTY_CHARGE_EXCEPTION]: [
          WorkOrderTab.Documents,
          WorkOrderTab.Transportation,
          WorkOrderTab.Milestones,
          WorkOrderTab.Charges,
        ],
      },
    },
    [RecordType.DE_CUSTOMS_ENTRY]: {
      [DEFAULT]: {
        [DEFAULT]: defaultEuEntryWorkOrderTabs,
        ...getBaseEuCustomsEntryConfiguration(DeCustomsEntryTaskType),
        ...getBaseEuCustomsEntryConfiguration(EuCustomsEntryExceptionTaskType),
        [WorkOrderTaskType.DE_CUSTOMS_ENTRY_REVIEW_DOCUMENTS]: [
          WorkOrderTab.Documents,
        ],
      },
    },
  },
  [TagValue.FBA_SCOT_FORWARDING]: {
    [RecordType.GB_CUSTOMS_ENTRY]: {
      [DEFAULT]: {
        [DEFAULT]: defaultEuEntryWorkOrderTabsForFbaScotForwardingOps,
        [WorkOrderTaskType.GB_CUSTOMS_ENTRY_REVIEW_DOCUMENTS]: [
          WorkOrderTab.Documents,
        ],
      },
    },
    [RecordType.NL_CUSTOMS_ENTRY]: {
      [DEFAULT]: {
        [DEFAULT]: defaultEuEntryWorkOrderTabsForFbaScotForwardingOps,
        [WorkOrderTaskType.NL_CUSTOMS_ENTRY_REVIEW_DOCUMENTS]: [
          WorkOrderTab.Documents,
        ],
      },
    },
    [RecordType.FR_CUSTOMS_ENTRY]: {
      [DEFAULT]: {
        [DEFAULT]: defaultEuEntryWorkOrderTabsForFbaScotForwardingOps,
        [WorkOrderTaskType.FR_CUSTOMS_ENTRY_REVIEW_DOCUMENTS]: [
          WorkOrderTab.Documents,
        ],
      },
    },
    [RecordType.DE_CUSTOMS_ENTRY]: {
      [DEFAULT]: {
        [DEFAULT]: defaultEuEntryWorkOrderTabsForFbaScotForwardingOps,
        [WorkOrderTaskType.DE_CUSTOMS_ENTRY_REVIEW_DOCUMENTS]: [
          WorkOrderTab.Documents,
        ],
      },
    },
  },
};

export const getWorkOrderTabConfiguration = (
  recordType: RecordType,
  key = DEFAULT,
  countryCode = DEFAULT,
): Record<string, WorkOrderTab[]> => {
  return workOrderTabConfiguration[key][recordType][countryCode];
};

export const getForwarderSpecificKeyForTabConfiguration = (
  recordType: RecordType,
  forwarders?: WorkOrderGroupForwarder[],
): string | undefined => {
  if (forwarders && forwarders.length > 0) {
    const forwarderKeys = forwarders.reduce(
      (result: string[], forwarder: WorkOrderGroupForwarder) => {
        // Currently using the tag STRATEGIC_BUSINESS_UNIT to add forwarder level configuration to the tab config
        // In future, we can add more tag-based config or config based on other forwarder fields
        const strategicBusinessUnit = forwarder.forwarder?.tags?.find(
          (tag) => tag.key === TagKey.STRATEGIC_BUSINESS_UNIT,
        )?.value;
        if (
          strategicBusinessUnit &&
          workOrderTabConfiguration[strategicBusinessUnit] &&
          Boolean(workOrderTabConfiguration[strategicBusinessUnit][recordType])
        ) {
          result.push(strategicBusinessUnit);
        }
        return result;
      },
      [],
    );
    // Assumes that only a single forwarder key should match with the above criteria.
    // If multiple keys matched, then the first one encountered as per the code above gets picked up.
    // Multiple keys won't be picked up as per current or planned forwarder configurations though
    // so this is just a failsafe.
    return forwarderKeys.shift();
  }
  return;
};

/*
Get the key for work order tab configuration. Currently, it is as per forwarder level key or uses DEFAULT.
In future, we can add support for other keys before falling back to DEFAULT.
*/
export const getKeyForWorkOrderTabConfiguration = (
  recordType: RecordType,
  forwarders?: WorkOrderGroupForwarder[],
  countryCode: string = DEFAULT,
): string | undefined => {
  let workOrderTabConfigurationKey = getForwarderSpecificKeyForTabConfiguration(
    recordType,
    forwarders,
  );
  if (!workOrderTabConfigurationKey) {
    workOrderTabConfigurationKey = workOrderTabConfiguration[DEFAULT]?.[
      recordType
    ]?.[countryCode]
      ? DEFAULT
      : undefined;
  }
  return workOrderTabConfigurationKey;
};
