import { AppRecordKebabMenuItem } from '../../types';
import DutyCollectionPartyOptions from 'components/DutyCollectionPartyOptions';
import {
  UpdateShipmentInput,
  CreateInvoiceLinesRequestInput,
  IdInput,
  ShipmentShipperInput,
} from '@xbcb/api-gateway-client';
import {
  createOneMutation,
  updateOneMutation,
  getOneQuery,
} from '@xbcb/shared-queries';
import { InvoiceType } from '@xbcb/finance-types';
import { message } from 'antd';
import { RecordType, AccountType } from '@xbcb/shared-types';
import { client as apolloClient } from '@xbcb/apollo-client';
import { executeMutation } from 'libs/executeMutation';
import { checkKebabMenuItemForWorkOrderTask } from './checkKebabMenuItemForWorkOrderTask';
import { KebabMenuItemKey } from '@xbcb/ui-types';

export const appRecordModifyDutyCollectionParty: AppRecordKebabMenuItem = {
  key: KebabMenuItemKey.MODIFY_PREFERRED_DUTY_COLLECT_PARTY_TYPE,
  text: 'Modify Duty Collection Party',
  show: ({ currentUser, workOrderTaskType, workOrderTaskEvaluatedState }) =>
    currentUser.accountType === AccountType.OPERATOR &&
    checkKebabMenuItemForWorkOrderTask(
      workOrderTaskType,
      workOrderTaskEvaluatedState,
      KebabMenuItemKey.MODIFY_PREFERRED_DUTY_COLLECT_PARTY_TYPE,
    ),
  action: async ({ record, props }) => {
    if (!props) {
      void message.error('Invalid Collection Type');
      return;
    }
    const workOrderId = record?.id;
    const operatorId = record?.operator?.id;
    const shipperId = record?.group?.shipper?.id;
    const { id: shipmentId } = record?.group?.shipment;

    const updateShipmentMutation = updateOneMutation({
      recordName: RecordType.SHIPMENT,
    });
    const createInvoiceLinesMutation = createOneMutation({
      recordName: RecordType.INVOICE_LINES_REQUEST,
    });
    const getShipmentQuery = getOneQuery({
      recordName: RecordType.SHIPMENT,
    });

    void message.info('Processing Request');
    const shipmentResponse = await apolloClient.query({
      query: getShipmentQuery,
      variables: { id: record?.group?.shipment?.id },
    });
    const shipmentVersion = shipmentResponse.data.shipment.version;

    await executeMutation({
      mutation: updateShipmentMutation,
      variables: {
        version: shipmentVersion,
        id: shipmentId,
        input: {
          // TODO remove this after the sparse update of update shipment starts working
          shipper: {
            shipper: { id: shipperId } as IdInput,
          } as ShipmentShipperInput,
          preferredDutyCollectPartyType: props?.key,
        } as UpdateShipmentInput,
      },
      successMessage: `Duty collector party changed to ${props?.key}`,
    });

    await executeMutation({
      mutation: createInvoiceLinesMutation,
      variables: {
        input: {
          operation: 'AMEND',
          type: InvoiceType.WORK_ORDER_GROUP,
          operator: { id: operatorId },
          workOrder: { id: workOrderId },
        } as CreateInvoiceLinesRequestInput,
      },
      successMessage: `Invoice for work order ${workOrderId} Updated`,
    });
  },
  SubMenuOptions: DutyCollectionPartyOptions,
};
