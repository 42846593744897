import React from 'react';
import { Form, Input } from 'antd';
import { shouldUpdate, getCodes, safeGetMessage } from '@xbcb/ui-utils';
import { FormItem, Option } from '@xbcb/form-item-components';
import { ModalKey } from 'types';
import { FormInstance } from 'antd/lib/form';
import {
  WorkOrderMilestoneName,
  nonCancellableWorkOrderMilestones as shipmentNonCancellableMilestones,
} from '@xbcb/work-order-types';
import { useCurrentUser } from 'libs/hooks';
import type { UsConsumptionEntry, WorkOrder } from '@xbcb/api-gateway-client';
import { RecordType, AccountType } from '@xbcb/shared-types';
import { getRecordType } from '@xbcb/core';
import {
  cbpReasonCodeToCbmsEnum,
  referenceNames,
} from 'libs/deleteUsConsumptionEntryRelease';
import CancelModalBase from 'components/CancelModalBase';
import { DataCySuffix } from '@xbcb/ui-types';
import CancelReasonsDropdown from 'components/CancelReasonsDropdown';
import { getLocalizedRecordName } from 'libs/localizationHelpers';
import { useBundle } from '@amzn/react-arb-tools';

interface CancelWorkOrderModalProps {
  record: WorkOrder;
}

const CancelWorkOrderModal: React.FC<CancelWorkOrderModalProps> = ({
  record,
}) => {
  const [bundle] = useBundle('components.CancelWorkOrderModal');
  const [recordNameBundle] = useBundle('types.shared.enums.RecordName');
  const { id, milestones } = record;
  const reasonNamePath = ['reason'];
  const recordType = getRecordType(id) as RecordType;
  const recordName = getLocalizedRecordName(
    recordNameBundle,
    recordType,
    AccountType.OPERATOR,
  );
  // if usIsf is filed, we allow to cancel shipment but no usIsf itself
  const nonCancellableWorkOrderMilestonesOccured = milestones?.some(
    (milestone) =>
      [
        ...shipmentNonCancellableMilestones,
        WorkOrderMilestoneName.US_ISF_FILED,
      ].includes(milestone.name as WorkOrderMilestoneName),
  );

  // If payment is authorized, we do not allow to cancel
  const entryPaymentAuthorized =
    recordType === RecordType.US_CONSUMPTION_ENTRY &&
    Boolean((record as UsConsumptionEntry)?.dailyStatement?.authorizationTime);
  // If entry is filed, we need to delete summary and release from CBP
  const entryFiled =
    recordType === RecordType.US_CONSUMPTION_ENTRY &&
    nonCancellableWorkOrderMilestonesOccured;
  const allowCancelEntryFromCbp = !entryPaymentAuthorized && entryFiled;

  const cbpDeleteReasonCodes = getCodes()?.CBP?.CATAIR?.release?.deleteReason;
  // According to https://app.asana.com/0/1172440442930369/1201466170315635, we only want to display three reasons
  // for external customers, operators should be able to see all reason codes
  const externalCustomerViewDeleteReasonCodes = ['02', '03', '04'];
  const { accountType } = useCurrentUser();
  const deleteReasonCodes =
    accountType !== AccountType.OPERATOR
      ? externalCustomerViewDeleteReasonCodes
      : Object.keys(cbpDeleteReasonCodes);
  const deleteReasonOptions = deleteReasonCodes.reduce(
    (acc: React.ReactElement[], cbpReasonCode, index) => {
      acc.push(
        <Option
          key={index}
          value={
            cbpReasonCodeToCbmsEnum[
              cbpReasonCode as keyof typeof cbpReasonCodeToCbmsEnum
            ]
          }
        >
          {cbpDeleteReasonCodes[cbpReasonCode]}
        </Option>,
      );
      return acc;
    },
    [],
  );

  const Content = ({ form }: { form: FormInstance }) => (
    <>
      {!nonCancellableWorkOrderMilestonesOccured ? (
        <CancelReasonsDropdown
          reasonNamePath={reasonNamePath}
          recordType={recordType}
          accountType={accountType}
          dataCySuffix={DataCySuffix.WORK_ORDER}
          instructionText={safeGetMessage(bundle, 'confirm_cancel_record', {
            recordName,
          })}
        />
      ) : allowCancelEntryFromCbp ? (
        <CancelReasonsDropdown
          reasonNamePath={reasonNamePath}
          recordType={recordType}
          accountType={accountType}
          dataCySuffix={DataCySuffix.WORK_ORDER}
          instructionText={safeGetMessage(bundle, 'entry_filed_warning')}
          customCancelReasonOptions={deleteReasonOptions}
          extraContent={
            <Form.Item shouldUpdate={shouldUpdate([reasonNamePath])} noStyle>
              {() => {
                return (
                  Object.keys(referenceNames).includes(
                    form.getFieldValue(reasonNamePath),
                  ) && (
                    <FormItem
                      label={
                        referenceNames[
                          form.getFieldValue(
                            reasonNamePath,
                          ) as keyof typeof referenceNames
                        ]
                      }
                      name={['reference']}
                      rules={[{ required: true, message: ' ' }]}
                    >
                      <Input />
                    </FormItem>
                  )
                );
              }}
            </Form.Item>
          }
        />
      ) : (
        <>
          <p>
            {safeGetMessage(bundle, 'cannot_cancel_reason', {
              recordName,
              entryPaymentAuthorized,
            })}
          </p>
        </>
      )}
    </>
  );
  return (
    <CancelModalBase
      modalKey={ModalKey.CANCEL_WORK_ORDER}
      modalTitle={safeGetMessage(bundle, 'cancel_record', { recordName })}
      className={'cancel-work-order-modal'}
      ModalContent={Content}
      // we want to allow entry cancellation if external entry is filed but payment is not authorized
      allowCancel={
        !nonCancellableWorkOrderMilestonesOccured || allowCancelEntryFromCbp
      }
      constructConfirmationData={({ form }) => {
        const { reason, reference } = form.getFieldsValue();
        return {
          cancellationReason: reason,
          cancelEntryCbpReference: reference,
        };
      }}
    />
  );
};

export default CancelWorkOrderModal;
