import { KebabMenuItemKey } from '@xbcb/ui-types';
import { setModal } from 'actions';
import AppRecordMemoKebabSubMenuOptions from 'components/AppRecordMemoKebabSubMenuOptions';
import { ModalKey } from 'types';
import { AppRecordKebabMenuItem } from '../../types';
import { checkKebabMenuItemForWorkOrderTask } from './checkKebabMenuItemForWorkOrderTask';
import { isRecordDeleted } from 'libs/workOrderConditions';
import { safeGetMessage } from '@xbcb/ui-utils';

export const appRecordMemoMenuItem: AppRecordKebabMenuItem = {
  text: ({ appRecordBundle }) => safeGetMessage(appRecordBundle, 'memos'),
  key: KebabMenuItemKey.MEMOS,
  action: ({ dispatch, props }) => {
    dispatch(
      setModal({
        key: ModalKey.APP_RECORD_MEMO,
        props: {
          ...props,
          visible: true,
        },
      }),
    );
  },
  show: ({ existingRecord, workOrderTaskEvaluatedState, workOrderTaskType }) =>
    !isRecordDeleted(existingRecord) &&
    checkKebabMenuItemForWorkOrderTask(
      workOrderTaskType,
      workOrderTaskEvaluatedState,
      KebabMenuItemKey.MEMOS,
    ),
  SubMenuOptions: AppRecordMemoKebabSubMenuOptions,
};
