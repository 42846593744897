import React, { ReactNode, useState } from 'react';
import { gql } from '@apollo/client';
import { List, Comment, Modal, Input, Form, message } from 'antd';
import { capitalCase } from 'change-case';
import type {
  WorkOrderTaskEscalation,
  WorkOrderTaskEscalationType,
  WorkOrderTask,
  TaskEscalationReplyDetailsInput,
  TaskEscalationReplyDetails,
  WorkOrder,
  OperatorUser,
} from '@xbcb/api-gateway-client';
import {
  StyledCard,
  StyledTooltip,
  StyledTypographyText,
  StyledButton,
  StyledTextCodeDiv,
  StyledAuthorDiv,
  StyledReplyButton,
  clickableTextStyle,
  verticalLineStyles,
  StyledReplyBoxDiv,
  StyledTag,
} from './styles';
import {
  TaskEscalationType,
  WorkOrderTaskSubStatus,
  WorkOrderTaskType,
} from '@xbcb/work-order-types';
import moment from 'moment';
import { find } from 'lodash';
import { useModal, useMutation } from '@xbcb/ui-utils';
import { ModalKey } from '@xbcb/ui-types';
import { FormItem } from '@xbcb/form-item-components';
import {
  getWorkOrderQuery,
  updateWorkOrderTaskStatus,
} from 'libs/sharedQueries';
import { fetchLatestRecordAndExecuteMutation } from 'libs/fetchLatestRecordAndExecuteMutation';
import { OperatorUserTeamRole, RecordType } from '@xbcb/shared-types';

type EscalationMemoProps = {
  tasks: WorkOrderTask[];
  workOrderTaskType?: WorkOrderTaskType;
  currentUser: OperatorUser;
  record: WorkOrder;
};

const escalationTagMessage = {
  DEFAULT: 'Escalation',
  [TaskEscalationType.ESCALATED_TO_TEAM_LEAD]: 'Escalation To Team Lead',
  [TaskEscalationType.ESCALATED_TO_SUBJECT_MATTER_EXPERT]: 'Escalation To SME',
};

const enum EscalationStatus {
  OPEN = 'OPEN',
  RESOLVED = 'RESOLVED',
}

const momentFormatString = 'MMMM Do YYYY, h:mm:ss a';

const replyWorkOrderTaskEscalationMutation = gql`
  mutation ReplyWorkOrderTaskEscalation(
    $input: TaskEscalationReplyDetailsInput!
    $taskId: ID!
    $version: Int!
  ) {
    replyWorkOrderTaskEscalation(
      input: $input
      taskId: $taskId
      version: $version
    ) {
      workOrderTask {
        id
      }
    }
  }
`;

const sortByTimeStamp = (data: WorkOrderTaskEscalation[]) => {
  const escalationTimes = data?.map((escalation, index) => ({
    position: index,
    time: moment(escalation.escalationDetails?.escalatedTime),
  }));
  // Sort in descending order
  const sortedEscalationTimes = escalationTimes.sort(
    (escalationA, escalationB) => escalationB.time.diff(escalationA.time),
  );
  const sortedTaskEscalations = sortedEscalationTimes.map(
    (escalationTime) => data[escalationTime.position],
  );
  return sortedTaskEscalations;
};

const EscalationComments: React.FC<{
  children?: ReactNode;
  author: string;
  details: string;
  time: string;
  categoryCode?: string;
  subCategoryCode?: string;
  isResolutionComment?: boolean;
}> = ({
  children,
  author,
  details,
  time,
  categoryCode,
  isResolutionComment,
}) => {
  const createdTimeMoment = moment(time);
  return (
    <Comment
      author={<StyledAuthorDiv>{author}</StyledAuthorDiv>}
      content={
        <>
          <StyledTextCodeDiv>{categoryCode}</StyledTextCodeDiv>
          <div>{details}</div>
        </>
      }
      datetime={
        <StyledTooltip title={createdTimeMoment.format(momentFormatString)}>
          <span>
            {isResolutionComment ? `resolved` : `created`}:{' '}
            {createdTimeMoment.fromNow()}
          </span>
        </StyledTooltip>
      }
    >
      {children}
    </Comment>
  );
};

const EscalationList: React.FC<{
  currentUserId: string;
  dataSource: WorkOrderTaskEscalation[];
  task: WorkOrderTask | undefined;
  isReplyButtonVisible: boolean;
  isEscalationsExpanded?: boolean;
  record: WorkOrder;
}> = ({
  currentUserId,
  dataSource,
  task,
  isReplyButtonVisible,
  record,
  isEscalationsExpanded,
}) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isReplyBoxVisible, setIsReplyBoxVisible] = useState(false);
  const [isRepliesExpanded, setIsRepliesExpanded] = useState(false);

  const toggleReplyBox = () => {
    if (isReplyBoxVisible) {
      form.resetFields();
    }
    setIsReplyBoxVisible(!isReplyBoxVisible);
  };
  const toggleReplies = () => {
    setIsRepliesExpanded(!isRepliesExpanded);
  };

  const taskId = task?.id;
  const version = task?.version;

  const [resolve] = useMutation(replyWorkOrderTaskEscalationMutation as any, {
    refetchQueries: () => [
      {
        query: getWorkOrderQuery,
        variables: { id: record.id, version: record.version },
      },
    ],
    awaitRefetchQueries: true,
  });

  const handleSubmit = async (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const input: TaskEscalationReplyDetailsInput = {
        details: form.getFieldValue('detail') as string,
        replyTime: moment.utc(Date.now()).toISOString(),
        repliedBy: { id: currentUserId },
      };
      await resolve({
        variables: {
          taskId,
          input,
          version,
        },
      });

      // TODO: move the substatus logic to the backend instead of doing it from the UI
      await fetchLatestRecordAndExecuteMutation({
        id: taskId as string,
        fields: 'subStatus',
        recordType: RecordType.WORK_ORDER_TASK,
        successMessage: `Work order task subStatus changed to ${WorkOrderTaskSubStatus.PENDING_INFORMATION}`,
        mutation: updateWorkOrderTaskStatus,
        constructVariablesWithIdVersion: (id, version) => ({
          id,
          version,
          input: {
            subStatus: WorkOrderTaskSubStatus.PENDING_INFORMATION,
          },
        }),
      });
    } catch (e) {
      reportError(e);
      void message.error(`Sorry, an error occurred: ${(e as any).message}`);
    } finally {
      setIsLoading(false);
      toggleReplyBox();
      if (isRepliesExpanded) {
        toggleReplies();
      }
    }
  };

  const SortedReplies: React.FC<{ replies: TaskEscalationReplyDetails[] }> = ({
    replies,
  }) => {
    const sortedReplies = [...replies].sort((a, b) =>
      moment(b.replyTime).diff(a.replyTime),
    );

    return (
      <div style={verticalLineStyles}>
        {sortedReplies.map((reply) => (
          <Comment
            author={<StyledAuthorDiv>{reply.repliedBy.name}</StyledAuthorDiv>}
            content={<div>{reply.details}</div>}
            datetime={
              <StyledTooltip
                title={moment(reply.replyTime).format(momentFormatString)}
              >
                <span>
                  {`replied: `}
                  {moment(reply.replyTime).fromNow()}
                </span>
              </StyledTooltip>
            }
          ></Comment>
        ))}
      </div>
    );
  };

  const repliesBlock = (replyDetails: TaskEscalationReplyDetails[]) => (
    <>
      {!isEscalationsExpanded && (
        <a onClick={toggleReplies} style={clickableTextStyle}>
          {`${replyDetails.length} ${
            replyDetails.length === 1 ? 'reply' : 'replies'
          }`}
        </a>
      )}
      {isRepliesExpanded && <SortedReplies replies={replyDetails} />}
    </>
  );

  return (
    <List
      dataSource={sortByTimeStamp(dataSource)}
      renderItem={(item) => {
        const { escalationDetails, resolutionDetails, replyDetails } = item;

        const escalationType = escalationDetails?.escalationType || 'DEFAULT';
        const escalationStatus = resolutionDetails
          ? EscalationStatus.RESOLVED
          : EscalationStatus.OPEN;

        return (
          <Form form={form}>
            <List.Item>
              <div>
                <StyledTag
                  color={
                    resolutionDetails
                      ? 'success'
                      : task?.subStatus ===
                        WorkOrderTaskSubStatus.PENDING_INFORMATION
                      ? 'warning'
                      : 'error'
                  }
                >
                  {`${escalationTagMessage[escalationType]} - ${
                    !resolutionDetails &&
                    task?.subStatus ===
                      WorkOrderTaskSubStatus.PENDING_INFORMATION
                      ? capitalCase(task.subStatus)
                      : capitalCase(escalationStatus)
                  }`}
                </StyledTag>

                {escalationDetails && (
                  <EscalationComments
                    author={escalationDetails.escalatedBy.name}
                    details={escalationDetails.details}
                    time={escalationDetails.escalatedTime}
                    categoryCode={capitalCase(
                      escalationDetails.escalationReasons.reasonCategory,
                    )}
                  >
                    {!resolutionDetails && isReplyButtonVisible && (
                      <>
                        <StyledReplyButton
                          size={'small'}
                          onClick={toggleReplyBox} // Step 2: Call the toggle function
                        >
                          Reply
                        </StyledReplyButton>
                        {isReplyBoxVisible && (
                          <StyledReplyBoxDiv>
                            <FormItem
                              name={['detail']}
                              rules={[
                                {
                                  required: true,
                                  message: 'Detail is required',
                                },
                              ]}
                            >
                              <Input.TextArea
                                maxLength={10000}
                                style={{ marginTop: '10px' }}
                              />
                            </FormItem>
                            <div>
                              <StyledButton
                                onClick={toggleReplyBox}
                                size="small"
                              >
                                Cancel
                              </StyledButton>
                              <StyledButton
                                onClick={handleSubmit}
                                loading={isLoading}
                                size="small"
                                htmlType="submit"
                                key="replyWorkOrderTaskEscalation"
                              >
                                Submit
                              </StyledButton>
                            </div>
                          </StyledReplyBoxDiv>
                        )}
                      </>
                    )}
                    {replyDetails &&
                      !(dataSource.length > 1) &&
                      repliesBlock(replyDetails)}
                    {replyDetails && isEscalationsExpanded && (
                      <SortedReplies replies={replyDetails} />
                    )}
                    {resolutionDetails && (
                      <EscalationComments
                        author={resolutionDetails.resolvedBy.name}
                        details={resolutionDetails.details}
                        time={resolutionDetails.resolutionTime}
                        categoryCode={capitalCase(
                          resolutionDetails.resolutionReasons[0].toString(),
                        )}
                        isResolutionComment
                      />
                    )}
                  </EscalationComments>
                )}
              </div>
            </List.Item>
          </Form>
        );
      }}
    />
  );
};

const EscalationMemo: React.FC<EscalationMemoProps> = ({
  tasks,
  workOrderTaskType,
  currentUser,
  record,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const workOrderTask = find(
    tasks,
    (task) => task.definition.workOrderTaskType === workOrderTaskType,
  );
  const taskEscalations: WorkOrderTaskEscalation[] =
    (workOrderTask?.escalations ||
      tasks?.reduce((acc: WorkOrderTaskEscalation[], task: WorkOrderTask) => {
        const escalations = task?.escalations;
        if (escalations) acc.push(...escalations);
        return acc;
      }, [])) ??
    [];
  const totalEscalations = taskEscalations.length;
  // TODO: Rename after memo integration
  const memoTitle = `Escalations (${totalEscalations})`;

  const showModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const subjectMatterExpertIds =
    workOrderTask?.assignmentTeam?.subjectMatterExpertTeam?.operatorUsers?.edges.map(
      (edge) => edge.node?.id,
    );
  const teamLeadIds =
    workOrderTask?.assignmentTeam?.operatorUsers?.edges.flatMap((edge) =>
      edge.relationship?.groups
        ?.filter(
          (group) =>
            group.roles.includes(OperatorUserTeamRole.PRIMARY_TEAM_LEAD) ||
            group.roles.includes(OperatorUserTeamRole.SECONDARY_TEAM_LEAD),
        )
        .map(() => edge.node?.id),
    );
  const isTeamLead = teamLeadIds?.includes(currentUser.id);
  const isSubjectMatterExpert = subjectMatterExpertIds?.includes(
    currentUser.id,
  );
  const isResolvedButtonDisabled = (
    escalationType?: WorkOrderTaskEscalationType,
    escalatedToId?: string,
  ) => {
    return !(
      currentUser.id === escalatedToId ||
      isTeamLead ||
      (escalationType ===
        TaskEscalationType.ESCALATED_TO_SUBJECT_MATTER_EXPERT &&
        isSubjectMatterExpert)
    );
  };
  const partialDataList = sortByTimeStamp(taskEscalations).slice(0, 1);
  const resolutionDetails = partialDataList[0]?.resolutionDetails;
  const escalationDetails = partialDataList[0]?.escalationDetails;
  const { openModal } = useModal(ModalKey.RESOLUTION_WORK_ORDER_TASK);
  const handleOpenModal = () => {
    openModal();
  };
  return !totalEscalations ? (
    <></>
  ) : (
    <>
      <Modal
        title="Escalations"
        open={isModalOpen}
        footer={null}
        onCancel={closeModal}
      >
        <EscalationList
          currentUserId={currentUser.id as string}
          dataSource={taskEscalations}
          task={workOrderTask}
          isReplyButtonVisible={true}
          isEscalationsExpanded={true}
          record={record}
        />
      </Modal>
      <StyledCard
        title={memoTitle}
        actions={
          totalEscalations > 1
            ? [
                <StyledTypographyText onClick={showModal}>
                  View all escalations
                </StyledTypographyText>,
              ]
            : []
        }
      >
        <EscalationList
          currentUserId={currentUser.id as string}
          dataSource={partialDataList}
          task={workOrderTask}
          isReplyButtonVisible={false}
          record={record}
        />
        {!resolutionDetails && (
          <StyledButton size={'small'} onClick={showModal}>
            Reply
          </StyledButton>
        )}
        {!resolutionDetails && (
          <StyledButton
            size={'small'}
            disabled={isResolvedButtonDisabled(
              escalationDetails?.escalationType,
              escalationDetails?.escalatedTo?.id,
            )}
            onClick={handleOpenModal}
          >
            Resolve
          </StyledButton>
        )}
      </StyledCard>
    </>
  );
};

export default EscalationMemo;
