import React, { useState, useEffect } from 'react';
import { RawHTML } from '@xbcb/static-text-components';
import { promiseTimeout } from '@xbcb/js-utils';
import { get } from 'lodash';
import { butter } from '@xbcb/ui-utils';
import { StyledButterContentLoaderDiv, StyledSpin } from './styles';

export interface ButterContentProps {
  code: string;
  whiteSpinner?: boolean;
}

const ButterContent = ({ code, whiteSpinner = false }: ButterContentProps) => {
  const [content, setContent] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    // This is to used to cancel in-flight fetch requests when the component is unmounted before the fetch is done.
    // It prevents the React error in the console related to cleaning up async functions in useEffect.
    const abortController = new AbortController();

    const fetchContent = async () => {
      try {
        const response = await promiseTimeout(
          butter('content', 'retrieve', [`tooltips[key=${code}]`]),
          5000,
        );

        if (response) {
          const content = get(response, 'data.data.tooltips[0].content');
          if (!content) {
            // Fallback to butterKey
            setContent(code);
          } else {
            setContent(content);
          }
          setIsLoaded(true);
        } else {
          throw new Error('No response from Butter');
        }
      } catch (e) {
        setError(true);
      }
    };
    setIsLoaded(false);
    void fetchContent();
    return () => abortController.abort();
  }, [code]);

  if (error) return <div>There was a problem loading this content.</div>;
  if (!isLoaded)
    return (
      <StyledButterContentLoaderDiv>
        <StyledSpin $whiteSpinner={whiteSpinner} />
      </StyledButterContentLoaderDiv>
    );
  return <RawHTML html={content} />;
};
export default ButterContent;
