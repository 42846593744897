import { message } from 'antd';
import { gql } from '@apollo/client';
import { memoFragments } from '@xbcb/client-queries';
import { AccountType, RecordType } from '@xbcb/shared-types';
import {
  workOrderTaskFragments,
  workOrderTaskStepFragments,
} from '@xbcb/work-order-queries';
import type { UsInBond as ApigUsInBond } from '@xbcb/api-gateway-client';
import { WorkOrderStatus, WorkOrderType } from '@xbcb/work-order-types';
import { transformUsInBond } from 'libs/formTransforms';
import { getUsInBondDraftEditMode } from 'libs/getUsInBondDraftEditMode';
import { usInBondFragments } from 'libs/sharedQueries';
import { UsInBond } from '../../loadableComponents';
import {
  appRecordAbiDataMenuItem,
  appRecordAssignWorkOrderTaskMenuItem,
  appRecordMemoMenuItem,
} from '../utils';
import { WorkOrderRoute } from '.';
import UsInBondSecondaryHeader from 'components/UsInBondSecondaryHeader';
import { markWorkOrderButton } from '../utils/markWorkOrdersReadyButton';
import { markWorkOrderHotButton } from '../utils/markWorkOrderHotButton';
import { checkAccess, UserPermissions } from '@xbcb/client-utils';
import MOTMenuOptions from 'components/MOTMenuOptions';
import { executeMutation } from 'libs/executeMutation';
import { isRecordCanceled } from 'libs/workOrderConditions';
import { checkKebabMenuItemForWorkOrderTask } from '../utils/checkKebabMenuItemForWorkOrderTask';
import { appRecordSendAbiMessage } from '../utils/appRecordSendAbiMessage';

const fields = `
  ...usInBondFields
  deadline
  group {
    documents {
      id
    }
    memos {
      ...memoFields
    }
  }
  tasks {
    ...workOrderTaskFields
    assignee {
      id
      name
    }
    steps {
      id
      name
      description
      isCompletedBySystem
      isFinalConfirmation
    }
    definition {
      id
      workOrderTaskType
    }
  }
`;

const changeMot = gql`
  mutation ChangeUsInBondMot(
    $id: ID!
    $version: Int!
    $input: UpdateUsInBondInput!
  ) {
    updateUsInBond(id: $id, version: $version, input: $input) {
      record {
        id
        version
        modeOfTransport
      }
    }
  }
`;
const usInBondRoute: WorkOrderRoute = {
  Page: UsInBond,
  recordType: RecordType.US_IN_BOND,
  fields,
  fragments: `${usInBondFragments}${workOrderTaskFragments}${workOrderTaskStepFragments}${memoFragments}`,
  SecondaryHeaderContents: [UsInBondSecondaryHeader],
  transformUpdateRecordInput: transformUsInBond.toSchema,
  kebabMenuItems: [
    appRecordAbiDataMenuItem,
    appRecordAssignWorkOrderTaskMenuItem,
    appRecordMemoMenuItem,
    appRecordSendAbiMessage,
    {
      text: 'Change MOT',
      key: 'changeMot',
      action: async ({ record, props }): Promise<void> => {
        const inBond: ApigUsInBond = record;
        const { id, version, modeOfTransport: originalMot } = inBond;
        if (!props?.key) {
          void message.error(
            `Sorry, an error occurred, please try again later.`,
            5.0,
          );
          return;
        }
        await executeMutation({
          mutation: changeMot,
          variables: {
            id,
            version,
            input: {
              modeOfTransport: props.key.toUpperCase(),
            },
          },
          successMessage: `MOT changed from ${originalMot.toLowerCase()} to ${
            props.key
          }`,
        });
      },

      show: ({
        existingRecord,
        currentUser,
        workOrderTaskType,
        workOrderTaskEvaluatedState,
      }) => {
        const inBond: ApigUsInBond = existingRecord;
        return (
          currentUser.accountType === AccountType.OPERATOR &&
          checkAccess(
            currentUser,
            RecordType.US_IN_BOND,
            UserPermissions.UPDATE,
          ) &&
          !isRecordCanceled(inBond) &&
          inBond.status === WorkOrderStatus.IN_PROGRESS &&
          checkKebabMenuItemForWorkOrderTask(
            workOrderTaskType,
            workOrderTaskEvaluatedState,
          )
        );
      },
      SubMenuOptions: MOTMenuOptions,
    },
  ],
  draftEditMode: ({ existingRecord }) =>
    getUsInBondDraftEditMode(existingRecord?.cbpInBonds),
  submitButtons: [
    markWorkOrderButton(WorkOrderType.UsInBond),
    markWorkOrderHotButton(WorkOrderType.UsInBond),
  ],
};

export default usInBondRoute;
