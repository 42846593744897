import React, { useEffect, useState, useRef } from 'react';
import { Checkbox, Skeleton, message } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import { debounce } from 'lodash';
import { useApolloClient } from '@apollo/client';
import { AnyObject, RecordType } from '@xbcb/shared-types';
import { modifyWorkOrderTaskStepsCompletionMutation } from '@xbcb/shared-queries';
import type { WorkOrder, WorkOrderTask } from '@xbcb/api-gateway-client';
import { isCustomsEntryOrDeclaration } from '@xbcb/work-order-utils/dist/isCustomsEntryOrDeclaration';
import {
  WorkOrderTaskStatus,
  WorkOrderTaskSubStatus,
} from '@xbcb/work-order-types';
import {
  updateWorkOrderTaskStatus,
  workOrderTaskFields as fields,
  workOrderTaskFragment as fragments,
} from 'libs/sharedQueries';
import {
  StyledDiv,
  StyledDescriptionDiv,
  StyledWorkOrderTaskStepDiv,
} from './styles';
import { fetchLatestRecordAndExecuteMutation } from 'libs/fetchLatestRecordAndExecuteMutation';
import {
  evaluateWorkOrderTaskState,
  WorkOrderTaskEvaluatedState,
} from 'libs/evaluateWorkOrderTaskState';
import { createDataCyValue, DataCyPrefix } from '@xbcb/ui-types';
import { fetchLatestRecord } from '@xbcb/apollo-client';
import { useBundle } from '@amzn/react-arb-tools';
import { safeGetMessage } from '@xbcb/ui-utils';

export type WorkOrderTaskStepsProps = {
  record: WorkOrder;
  loading?: boolean;
  // TODO switch to apollo codegen type
  workOrderTask: any;
  workOrderRecordType: RecordType;
};

export type ModifiedWorkOrderTaskStep = {
  isCompleted: boolean;
  stepId: string;
};

type Steps = Record<string, boolean>;

const mapSteps = (steps: AnyObject[]) =>
  steps.reduce((acc, step) => {
    acc[step.id] = Boolean(step.completed);
    return acc;
  }, {} as Steps);

const getStepsInput = (steps: Steps): ModifiedWorkOrderTaskStep[] =>
  Object.entries(steps).map(([key, value]) => {
    return { stepId: key, isCompleted: value };
  });

export const modifyWorkOrderTaskStepsCompletion =
  modifyWorkOrderTaskStepsCompletionMutation({
    fields,
    fragments,
  });

const WorkOrderTaskSteps = ({
  record,
  loading,
  workOrderTask,
  workOrderRecordType,
}: WorkOrderTaskStepsProps): JSX.Element => {
  if (loading) return <Skeleton active />;
  return (
    <WorkOrderTaskStepList
      record={record}
      workOrderTask={workOrderTask}
      workOrderRecordType={workOrderRecordType}
    />
  );
};

/**
 * This is a separate component because of the rules of hooks.
 * We're guaranteed to have an array of WOT steps here,
 * so we can initialize useState variables accordingly.
 */

const WorkOrderTaskStepList: React.FC<WorkOrderTaskStepsProps> = ({
  record,
  workOrderTask,
  workOrderRecordType,
}) => {
  const [workOrderTaskStepsBundle] = useBundle('components.WorkOrderTaskSteps');

  const { id: taskId, status, steps = [] } = workOrderTask;
  const client = useApolloClient();

  const [updatedSteps, setUpdatedSteps] = useState<Steps>(mapSteps(steps));
  const [loading, setLoading] = useState(false);

  const updateSteps = async (
    updatedSteps: ModifiedWorkOrderTaskStep[],
    taskId: string,
    originalSteps: WorkOrderTask['steps'],
  ) => {
    setLoading(true);
    try {
      const { version } = await fetchLatestRecord({
        fields: 'version',
        recordType: RecordType.WORK_ORDER_TASK,
        id: taskId,
      });
      await client.mutate({
        mutation: modifyWorkOrderTaskStepsCompletion,
        variables: {
          taskId,
          version,
          steps: updatedSteps,
        },
      });
      // void message.success('Updated work order task steps');
    } catch {
      void message.error(
        safeGetMessage(
          workOrderTaskStepsBundle,
          'unable_to_mark_steps_complete',
        ),
      );
      setUpdatedSteps(mapSteps(originalSteps));
    } finally {
      setLoading(false);
    }
  };

  const debounced = useRef(debounce(updateSteps, 1000));

  // Update WOT sub status from ACTIVELY_IN_PROGRESS to PASSIVELY_IN_PROGRESS
  // when this component unmounts
  useEffect(() => {
    return () => {
      const updateTaskStatus = async () => {
        if (!taskId) return;
        await fetchLatestRecordAndExecuteMutation({
          id: taskId,
          fields: 'status subStatus',
          recordType: 'WORK_ORDER_TASK' as RecordType,
          mutation: updateWorkOrderTaskStatus,
          successMessage: safeGetMessage(
            workOrderTaskStepsBundle,
            'task_no_longer_in_progress',
          ),
          constructVariablesWithIdVersion: (id, version) => ({
            id,
            version,
            input: {
              status: WorkOrderTaskStatus.IN_PROGRESS,
              subStatus: WorkOrderTaskSubStatus.PASSIVELY_IN_PROGRESS,
            },
          }),
          shouldSkipMutation: (latestRecord: AnyObject) => {
            return (
              latestRecord.status !== WorkOrderTaskStatus.IN_PROGRESS ||
              latestRecord.subStatus !==
                WorkOrderTaskSubStatus.ACTIVELY_IN_PROGRESS
            );
          },
        });
      };
      void updateTaskStatus();
    };
  }, []);

  return (
    <StyledDiv>
      {steps.map(
        (
          {
            description,
            id: stepId,
            isCompletedBySystem,
            isFinalConfirmation,
          }: {
            completed?: any;
            description?: string;
            id: string;
            isCompletedBySystem?: boolean;
            isFinalConfirmation?: boolean;
          },
          idx: number,
        ) => {
          if (isFinalConfirmation) return null;
          const handleCheckboxChange = async (e: CheckboxChangeEvent) => {
            const {
              target: { checked },
            } = e;
            const updated = {
              ...updatedSteps,
              [stepId]: checked,
            };
            setUpdatedSteps(updated);
            const stepsInput = getStepsInput(updated);

            void debounced.current(stepsInput, taskId, steps);
          };

          // For EU Work Orders, don't apply task buttons and don't disable control.
          const disableCheckBox = isCustomsEntryOrDeclaration(record.id)
            ? isCompletedBySystem ||
              [
                WorkOrderTaskStatus.COMPLETED,
                WorkOrderTaskStatus.CANCELED,
                WorkOrderTaskStatus.BLOCKED,
              ].includes(status)
            : isCompletedBySystem ||
              evaluateWorkOrderTaskState(workOrderTask) !==
                WorkOrderTaskEvaluatedState.STARTED;

          return (
            <StyledWorkOrderTaskStepDiv
              isTop={idx === 0}
              key={stepId}
              data-testid={'work-order-task-step'}
            >
              <Checkbox
                data-cy={createDataCyValue(
                  DataCyPrefix.WORK_ORDER_TASK_STEPS,
                  'checkbox',
                )}
                data-testid={stepId}
                checked={updatedSteps[stepId]}
                disabled={disableCheckBox || loading}
                onChange={handleCheckboxChange}
              />
              <StyledDescriptionDiv>{description}</StyledDescriptionDiv>
            </StyledWorkOrderTaskStepDiv>
          );
        },
      )}
    </StyledDiv>
  );
};

export default WorkOrderTaskSteps;
