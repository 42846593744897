import { WorkOrderTaskTypeGroup } from '@xbcb/ui-types';
import {
  EuCustomsEntryExceptionTaskType,
  WorkOrderTaskStatus,
  WorkOrderTaskType,
} from '@xbcb/work-order-types';
import { isCustomsEntryOrDeclaration } from '@xbcb/work-order-utils/dist/isCustomsEntryOrDeclaration';
import { DocumentTag } from '@xbcb/document-types';
import {
  WorkOrder,
  DocumentTag as apiGDocumentTag,
} from '@xbcb/api-gateway-client';
import { TagKey, TagValue } from '@xbcb/shared-types';
import { findMatchingWorkOrderTask } from 'libs/findMatchingWorkOrderTask';
import { determineWorkOrderTaskTypeGroup } from 'libs/workOrderTaskTypeGroups';
import { WorkOrderTaskEvaluatedState } from 'libs/evaluateWorkOrderTaskState';

const completionAllowedCustomsEntryOrDeclarationTaskTypes = [
  WorkOrderTaskType.GB_CUSTOMS_ENTRY_REVIEW_DOCUMENTS,
  WorkOrderTaskType.DE_CUSTOMS_ENTRY_REVIEW_DOCUMENTS,
  WorkOrderTaskType.NL_CUSTOMS_ENTRY_REVIEW_DOCUMENTS,
  WorkOrderTaskType.FR_CUSTOMS_ENTRY_REVIEW_DOCUMENTS,
  ...Object.keys(EuCustomsEntryExceptionTaskType),
  WorkOrderTaskType.CUSTOMS_DECLARATION_DOCUMENT_REVIEW,
  WorkOrderTaskType.CUSTOMS_DECLARATION_BUILD_TRANSPORTATION_AND_INVOICE,
  WorkOrderTaskType.CUSTOMS_DECLARATION_FILING,
  WorkOrderTaskType.CUSTOMS_DECLARATION_DUTY_CHARGE_EXCEPTION,
];

export const findWorkOrderTaskAndTaskTypeGroup = (
  workOrder: WorkOrder,
  workOrderTaskType: WorkOrderTaskType,
) => {
  const workOrderTask = findMatchingWorkOrderTask(workOrder, workOrderTaskType);
  const workOrderTaskTypeGroup =
    determineWorkOrderTaskTypeGroup(workOrderTaskType);
  return { workOrderTask, workOrderTaskTypeGroup };
};

// work orders contain all the documentTags specified
const areDocumentsPresentOnWorkOrder = (
  workOrder: WorkOrder,
  documentTags: apiGDocumentTag[],
) =>
  documentTags.every((documentTag) =>
    (workOrder.group?.documents || [])
      .filter(({ deleted }) => !deleted)
      .map(({ documentTags }) => documentTags)
      .flat()
      .includes(documentTag),
  );

export const showWorkOrderTaskCompletionButton = (
  workOrder: WorkOrder,
  workOrderTaskType?: WorkOrderTaskType,
  workOrderTaskEvaluatedState?: WorkOrderTaskEvaluatedState,
) => {
  if (!workOrderTaskType) return false;
  const { workOrderTask, workOrderTaskTypeGroup } =
    findWorkOrderTaskAndTaskTypeGroup(workOrder, workOrderTaskType);
  const containsSystemOnlySteps =
    workOrderTaskTypeGroup === WorkOrderTaskTypeGroup.SYSTEM_ONLY;
  const isCompletedOrCanceled = [
    WorkOrderTaskStatus.COMPLETED,
    WorkOrderTaskStatus.CANCELED,
  ].includes(workOrderTask?.status);

  const customsEntryOrDeclaration = isCustomsEntryOrDeclaration(workOrder?.id);
  const shouldSkipSubStatusCheck = customsEntryOrDeclaration;
  const isActivelyInProgress =
    workOrderTaskEvaluatedState === WorkOrderTaskEvaluatedState.STARTED;
  // For EU/UK and CuDe tasks, it's easier to list tasks allowed for manual completion.
  // TODO: determine if a task can be manually completed by the last step configured.
  const isCompletionAllowed = customsEntryOrDeclaration
    ? completionAllowedCustomsEntryOrDeclarationTaskTypes.includes(
        workOrderTaskType,
      )
    : true;
  return (
    !isCompletedOrCanceled &&
    !containsSystemOnlySteps &&
    (shouldSkipSubStatusCheck || isActivelyInProgress) &&
    isCompletionAllowed
  );
};

export const eu1PDocumentsPresent = (
  workOrder: WorkOrder,
  workOrderTaskType: WorkOrderTaskType,
): {
  documentsPresent: boolean;
  reason?: string;
} => {
  if (workOrderTaskType !== WorkOrderTaskType.GB_CUSTOMS_ENTRY_REVIEW_DOCUMENTS)
    return {
      documentsPresent: true,
    };
  const is1PEuBroker = workOrder.group.customsBroker?.tags.some(
    ({ key, value }) =>
      key === TagKey.STRATEGIC_BUSINESS_UNIT &&
      value === TagValue.WONDERWALL_BROKER,
  );
  const documentPresentOnWorkOrder = areDocumentsPresentOnWorkOrder(workOrder, [
    DocumentTag.PACKING_LIST,
    DocumentTag.APPROVED_DOCUMENT,
  ]);
  const documentsPresent = is1PEuBroker ? documentPresentOnWorkOrder : true;
  return {
    documentsPresent,
    reason: !documentsPresent
      ? 'Please check if both packing list and approve document tags are present'
      : '',
  };
};
export const disableWorkOrderTaskCompletionButton = (
  workOrder: WorkOrder,
  workOrderTaskType: WorkOrderTaskType,
): boolean => {
  const { workOrderTask } = findWorkOrderTaskAndTaskTypeGroup(
    workOrder,
    workOrderTaskType,
  );
  // changed logic to only disable work order task if it is completed
  return workOrderTask.status === WorkOrderTaskStatus.COMPLETED;
};
