import { gql } from '@apollo/client';
import { importerFragment } from 'components/Importer';

export const queryImporter = gql`
  query Importer($id: ID!) {
    importer(id: $id) {
      ...importerFields
    }
  }
  ${importerFragment}
`;

export const updateImporter = gql`
  mutation UpdateImporter(
    $id: ID!
    $version: Int!
    $input: UpdateImporterInput!
  ) {
    updateImporter(input: $input, version: $version, id: $id) {
      record {
        id
        version
      }
    }
  }
`;
