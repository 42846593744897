import styled from 'styled-components';

export const StyledDiv = styled.div`
  font-size: ${({ theme }) => theme.font.size.large};

  .anticon:not(:first-child) {
    margin-left: ${({ theme }) => theme.spacing.space_4};
  }
`;

export const StyledSpan = styled.span`
  margin-left: ${({ theme }) => theme.spacing.space_4};
`;
