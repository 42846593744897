import React, { useEffect, useState } from 'react';
import { first, get, lowerCase } from 'lodash';
import { constantCase } from 'change-case';
import { getIsIorActivated, isAGLForwarder } from '@xbcb/party-utils';
import {
  AccountType,
  RecordType,
  ModeOfTransport,
  AnyObject,
} from '@xbcb/shared-types';
import {
  createOneMutation,
  getSearchResultFromResponse,
  getRecordFromResponse,
} from '@xbcb/shared-queries';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form, Button, Checkbox } from 'antd';
import { getRecordType } from '@xbcb/core';
import {
  ANNumber,
  CountrySelect,
  HiddenField,
  PickDate,
  SimpleSelect,
} from '@xbcb/form-item-components';
import { shouldUpdate, maxLength, getEnv } from '@xbcb/ui-utils';
import { WorkOrderType } from '@xbcb/work-order-types';
import { formatRecordName, pollWithDelay } from '@xbcb/js-utils';
import MultipleSelectInput from 'components/MultipleSelectInput';
import { InlineTooltip } from '@xbcb/feedback-components';
import {
  CssSize,
  DataCyPrefix,
  DataCySuffix,
  createDataCyValue,
} from '@xbcb/ui-types';
import withModal from 'components/withModal';
import { useSelector } from 'react-redux';
import { ModalKey } from 'types';
import { transformShipment } from 'libs/formTransforms';
import {
  useCurrentUser,
  useCustomsBrokerId,
  useGlobalMileForwarder,
} from 'libs/hooks';
import handleRecordCreate from 'libs/handleRecordCreate';
import MasterBill from 'components/MasterBill';
import MotSelect from 'components/MotSelect';
import PreferredDutyCollectPartyTypeSelect from 'components/PreferredDutyCollectPartyTypeSelect';
import RecordSelect from 'components/RecordSelect';
import ShipperSelect from 'components/ShipperSelect';
import validateCreateShipment from 'libs/validateCreateShipment';
import { useHistory } from 'react-router-dom';
import {
  getAllowedWorkOrders,
  customerDefaultWorkOrdersConfig,
  DestinationCountry,
  GET_FORWARDER_DETAILS_QUERY,
  GET_IOR_DETAILS_QUERY,
  GET_SHIPMENT_WORKORDERS_QUERY,
  GET_SHIPPER_DETAILS_QUERY,
  SEARCH_PENDING_US_IOR_BOND_REQS_QUERY,
  SEARCH_SHIPMENTS_QUERY,
} from './constants';
import { clearFields } from 'libs/clearFields';
import {
  StyledAlert,
  StyledAnchor,
  StyledBillCard,
  StyledClientId,
  StyledClientIdCard,
  StyledForm,
  StyledFormInlineDiv,
  StyledIorCard,
  StyledIorWarningCard,
  StyledManualCreationFeeCard,
  StyledManualCreationFeePrompt,
  StyledModal,
  StyledMotCard,
  StyledPartyCard,
} from './styles';

const { stage } = getEnv();

interface CreateShipmentModalProps {
  closeModal: any;
  openModal: any;
}

const CreateShipmentModal: React.FC<CreateShipmentModalProps> = ({
  closeModal,
  openModal,
}) => {
  const { visible }: { visible: boolean } = useSelector((state) =>
    get(state, `ui.modals.${ModalKey.CREATE_SHIPMENT}`, {}),
  );
  const user = useCurrentUser();
  const { operator, accountType, account } = user;
  const [form] = Form.useForm();
  const history = useHistory();
  const createMutation = createOneMutation({
    recordName: RecordType.SHIPMENT,
    fields: `
      id
    `,
  });

  const [createRecord, { loading, called: shipmentCreationInvoked }] =
    useMutation(createMutation);

  const customsBrokerId = useCustomsBrokerId();
  const globalMileForwarder = useGlobalMileForwarder();
  const globalMileForwarderId = globalMileForwarder?.id;

  const isOperatorUser = accountType === AccountType.OPERATOR;
  const isForwarderUser = accountType === AccountType.FORWARDER;
  const isShipperUser = accountType === AccountType.SHIPPER;

  const destinationCountry = DestinationCountry.US;

  const forwarderPath = ['forwarder'];
  const shipperPath = ['shipper', 'shipper'];
  const iorPath = ['ior', 'ior'];
  const forwarderIdPath = [...forwarderPath, 'id'];
  const shipperIdPath = [...shipperPath, 'id'];
  const iorIdPath = [...iorPath, 'id'];
  const motPath = ['modeOfTransport'];
  const requestedWosPath = ['requestedWorkOrders'];
  const houseBillsPath = ['houseBills'];
  const masterBillPath = ['masterBill'];
  const clientIdentifierPath = ['clientIdentifier'];
  const destinationCountryPath = ['destinationCountry'];
  const sourceCountryPath = ['sourceCountry'];
  const customsBrokerIdPath = ['usCustomsBroker', 'id'];
  const chargeManualCreationFeePath = ['chargeManualCreationFee'];

  const getForwarderId = () =>
    form.getFieldValue(forwarderIdPath) || (isForwarderUser && account?.id);
  const getShipperId = () =>
    form.getFieldValue(shipperIdPath) || (isShipperUser && account?.id);
  const getIorId = () => form.getFieldValue(iorIdPath);

  const [getIor, getIorResponse] = useLazyQuery(GET_IOR_DETAILS_QUERY);

  const [searchPendingIorBondReqs, searchPendingIorBondReqsResponse] =
    useLazyQuery(SEARCH_PENDING_US_IOR_BOND_REQS_QUERY);

  const [getShipper, getShipperResponse] = useLazyQuery(
    GET_SHIPPER_DETAILS_QUERY,
  );

  const [getForwarder, getForwarderResponse] = useLazyQuery(
    GET_FORWARDER_DETAILS_QUERY,
  );

  const [searchShipmentsByClientId, searchShipmentsByClientIdResponse] =
    useLazyQuery(SEARCH_SHIPMENTS_QUERY);

  const [searchShipmentsByMasterBill, searchShipmentsByMasterBillResponse] =
    useLazyQuery(SEARCH_SHIPMENTS_QUERY);

  const [searchShipmentsByHouseBill, searchShipmentsByHouseBillResponse] =
    useLazyQuery(SEARCH_SHIPMENTS_QUERY);

  const { refetch: fetchShipmentWoIds } = useQuery(
    GET_SHIPMENT_WORKORDERS_QUERY,
    {
      skip: true, // should be invoked only on submit.
    },
  );

  const [isIorActivated, setIsIorActivated] = useState(false);
  const getCustomUrl = async (record: AnyObject) => {
    const getIsfIdFromShipment = async () => {
      const response = await fetchShipmentWoIds({
        id: record.id,
      });
      const result = getRecordFromResponse(
        response,
        'get',
        RecordType.SHIPMENT,
      );
      const isfWo: any = first(
        result?.workOrderGroup?.workOrders?.filter(
          (wo: any) => getRecordType(wo?.id) === RecordType.US_ISF,
        ),
      );

      if (!isfWo) {
        throw Error('ISF not yet found'); // throwing error to invoke retries.
      }
      return isfWo.id;
    };

    const isIsfSelected = form
      .getFieldValue(requestedWosPath)
      ?.includes(WorkOrderType.UsIsf);
    // If the ISF is selected and chosen IOR is activated, redirect to ISF page instead. Else the default behavior.
    if (isIsfSelected && isIorActivated) {
      try {
        const isfId = await pollWithDelay(getIsfIdFromShipment, 500, 10);
        return `/us-isfs/${isfId}?tab=ISF`;
      } catch (err) {
        // if isf is not found even after retries, digest the exception so that client is atleast redirected to shipment page.
      }
    }
    return `/shipments/${record.id}`; // default usecase
  };

  const getShipmentSearchResults = (response: any) =>
    getSearchResultFromResponse(response, 'search', RecordType.SHIPMENT)
      ?.results || [];

  const clientIdDuplicateShipmentExists = () =>
    form.getFieldValue(clientIdentifierPath) &&
    !searchShipmentsByClientIdResponse.loading &&
    getShipmentSearchResults(searchShipmentsByClientIdResponse).length > 0;

  const masterBillDuplicateShipmentExists = () =>
    form.getFieldValue(masterBillPath) &&
    !searchShipmentsByMasterBillResponse.loading &&
    getShipmentSearchResults(searchShipmentsByMasterBillResponse).length > 0;

  const houseBillDuplicateShipmentExists = () =>
    form.getFieldValue(houseBillsPath)?.length > 0 &&
    !searchShipmentsByHouseBillResponse.loading &&
    getShipmentSearchResults(searchShipmentsByHouseBillResponse).length > 0;

  const isSubscribedShipper = Boolean(
    !getShipperResponse.loading &&
      getRecordFromResponse(getShipperResponse, 'get', RecordType.SHIPPER)
        ?.billingDetails?.subscriptionPlan,
  );

  const iorHasPendingBondRequest = Boolean(
    !searchPendingIorBondReqsResponse.loading &&
      getSearchResultFromResponse(
        searchPendingIorBondReqsResponse,
        'search',
        RecordType.US_IOR_CONTINUOUS_BOND_REQUEST,
      )?.results?.length,
  );

  const baseSearchCriteria = {
    operatorId: {
      operator: 'EQUALS',
      values: [operator?.id],
    },
    deletedTime: {
      operator: 'DOES_NOT_EXIST',
    },
  };

  const constructSearchPendingIorBondReqsInput = (iorId: string) => {
    return {
      variables: {
        input: {
          searchCriteria: {
            ...baseSearchCriteria,
            usIorId: {
              operator: 'EQUALS',
              values: [iorId],
            },
            status: {
              operator: 'NOT_EQUALS',
              values: ['COMPLETED'],
            },
          },
        },
      },
    };
  };

  const constructSearchShipmentInput = (field: string, values: string[]) => {
    return {
      variables: {
        input: {
          searchCriteria: {
            ...baseSearchCriteria,
            [field]: {
              operator: 'ONE_OF',
              values,
            },
            ...(getShipperId() &&
              isSubscribedShipper && {
                shipperId: { operator: 'EQUALS', values: [getShipperId()] },
              }),
            ...(getForwarderId() &&
              !isSubscribedShipper && {
                forwarderId: { operator: 'EQUALS', values: [getForwarderId()] },
              }),
          },
        },
      },
    };
  };

  const createDuplicateShipmentAlert = (
    searchResponse: any,
    isError: boolean,
  ) => {
    const results = getShipmentSearchResults(searchResponse);

    const shipmentAnchorTags = results.map((shipment: any) => (
      <li>
        <StyledAnchor href={`/shipments/${shipment.id}`} target="_blank">
          {shipment.friendlyId || shipment.id}
        </StyledAnchor>
      </li>
    ));
    return (
      <StyledAlert
        message={
          <div>
            Duplicate shipment(s)
            <ul>{shipmentAnchorTags}</ul>
          </div>
        }
        type={isError ? 'error' : 'warning'}
        showIcon
        closable={!isError}
      />
    );
  };

  useEffect(() => {
    // Need to fetch shipper details for a shipper user since shipperId is hidden field.
    if (isShipperUser) {
      void getShipper({
        variables: {
          id: getShipperId(),
        },
      });
    }
    // Need to fetch forwarder details for a forwarder user since forwarderId is hidden field.
    if (isForwarderUser) {
      void getForwarder({
        variables: {
          id: getForwarderId(),
        },
      });
    }
  }, [isShipperUser, isForwarderUser]);

  const forwarderSelect = (
    <Form.Item shouldUpdate={shouldUpdate([shipperIdPath])} noStyle>
      {() => {
        return (
          getShipperId() && (
            <div>
              <StyledFormInlineDiv>
                <h4>
                  {`Forwarder${isSubscribedShipper ? ' (optional)' : ''}`}
                </h4>
                {isSubscribedShipper && (
                  <InlineTooltip title="Select the forwarder to give them visibility to the shipment" />
                )}
              </StyledFormInlineDiv>
              <RecordSelect
                localNamePath={forwarderPath}
                fullNamePath={forwarderPath}
                recordType={RecordType.FORWARDER}
                $itemSize={CssSize.SHORT}
                form={form}
                hideLabel
                required={!isSubscribedShipper}
                disabled={loading}
                dataCySuffix={DataCySuffix.FORWARDER_ID}
                onChange={(forwarderId?: string) => {
                  if (forwarderId) {
                    void getForwarder({
                      variables: {
                        id: forwarderId,
                      },
                    });
                  }
                }}
                selectOneRecordByDefault={!isSubscribedShipper}
                // A subscribed Shipper is not mandated to select a forwarder.
                disableSelectionForSingleRecord={!isSubscribedShipper}
                additionalSearchCriteria={
                  // for now disable filters for operators
                  !isOperatorUser
                    ? {
                        shipperId: {
                          values: [getShipperId()],
                          operator: 'EQUALS',
                        },
                      }
                    : {}
                }
              />
            </div>
          )
        );
      }}
    </Form.Item>
  );

  const getManualShipmentCreationFee = () => {
    if (!isOperatorUser) {
      return; // Not required, since its only visible to operators.
    }
    const response = isSubscribedShipper
      ? getRecordFromResponse(getShipperResponse, 'get', RecordType.SHIPPER)
      : getRecordFromResponse(
          getForwarderResponse,
          'get',
          RecordType.FORWARDER,
        );
    const valueFromCustomer = first(
      response?.billingDetails?.customFees?.filter(
        (fee: any) => fee.type === 'MANUAL_CREATION_FEE',
      ) as any[],
    )?.value?.value;
    return valueFromCustomer || 15; // default
  };

  const getDefaultWorkOrdersForMot = (
    val: ModeOfTransport,
  ): WorkOrderType[] => {
    const partyResponse =
      isShipperUser || isSubscribedShipper
        ? // Use shipper config for shipper user or if shipper has a plan.
          getRecordFromResponse(getShipperResponse, 'get', RecordType.SHIPPER)
        : // Else use forwarder config (forwarder user / operator user / forwarder has a plan)
          getRecordFromResponse(
            getForwarderResponse,
            'get',
            RecordType.FORWARDER,
          );

    const defaultWorkOrdersConfig =
      partyResponse?.defaultWorkOrdersConfig || customerDefaultWorkOrdersConfig;
    const defaultWorkOrdersConfigForCountry =
      defaultWorkOrdersConfig[lowerCase(destinationCountry)];
    // Search by specific mot first.
    let defaultWorkOrdersConfigForMot = first(
      defaultWorkOrdersConfigForCountry?.filter((config: any) =>
        config.modesOfTransport?.includes(val),
      ),
    ) as any;
    // If no config found, search the default config (without mot.
    if (!defaultWorkOrdersConfigForMot) {
      defaultWorkOrdersConfigForMot = first(
        defaultWorkOrdersConfigForCountry?.filter(
          (config: any) => !config.modesOfTransport,
        ),
      );
    }
    return defaultWorkOrdersConfigForMot?.workOrderTypes || [];
  };

  return (
    <StyledModal
      destroyOnClose
      maskClosable={loading}
      onCancel={() => closeModal()}
      open={visible}
      width={665}
      footer={[
        <Form.Item
          shouldUpdate={shouldUpdate([clientIdentifierPath, houseBillsPath])}
          noStyle
        >
          {() => (
            <Button
              key="submit"
              type="primary"
              disabled={
                clientIdDuplicateShipmentExists() ||
                houseBillDuplicateShipmentExists()
              }
              loading={shipmentCreationInvoked || loading} // display loading as soon as create shipment is invoked, since we might search isf after it.
              onClick={async () =>
                handleRecordCreate({
                  form,
                  history,
                  recordType: RecordType.SHIPMENT,
                  transformCreateRecordInput: transformShipment.toCreateSchema,
                  validateCreate: validateCreateShipment,
                  createRecord,
                  clean: false,
                  closeModal,
                  customRedirectUrl: getCustomUrl,
                  user,
                })
              }
            >
              Create
            </Button>
          )}
        </Form.Item>,
      ]}
    >
      <StyledForm form={form} name="createShipmentForm">
        <StyledPartyCard>
          {(isOperatorUser || isForwarderUser) && (
            <div>
              <StyledFormInlineDiv>
                <h4>
                  {formatRecordName({
                    recordType: RecordType.SHIPPER,
                    accountType: user.accountType,
                  })}
                </h4>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://help.inlt.com/customs-brokerage/who-is-the-importer-of-record"
                >
                  <QuestionCircleOutlined />
                </a>
              </StyledFormInlineDiv>

              <ShipperSelect
                form={form}
                noCardWrap
                multiple={false}
                disabled={loading}
                namePath={shipperPath}
                onChange={(shipperId?: string) => {
                  if (shipperId) {
                    void getShipper({
                      variables: {
                        id: shipperId,
                      },
                    });
                  }
                  if (!isForwarderUser) {
                    clearFields({
                      form,
                      fullNamePath: forwarderPath,
                      fields: ['id'],
                    })();
                  }
                  clearFields({
                    form,
                    fullNamePath: iorPath,
                    fields: ['id'],
                  })();
                }}
                selectOneRecordByDefault
                disableSelectionForSingleRecord
                additionalSearchCriteria={
                  isForwarderUser
                    ? {
                        forwarderId: {
                          values: [getForwarderId()],
                          operator: 'EQUALS',
                        },
                      }
                    : {} // Should be safe since operator should be able to view all shippers.
                }
              />
            </div>
          )}
          {isShipperUser && (
            <HiddenField
              localNamePath={shipperIdPath}
              fullNamePath={shipperIdPath}
              value={getShipperId()}
              form={form}
            />
          )}

          {(isOperatorUser || (isShipperUser && !isSubscribedShipper)) &&
            forwarderSelect}

          {isForwarderUser && (
            <HiddenField
              localNamePath={forwarderIdPath}
              fullNamePath={forwarderIdPath}
              value={getForwarderId()}
              form={form}
            />
          )}
        </StyledPartyCard>

        <StyledIorCard>
          <Form.Item shouldUpdate={shouldUpdate([shipperIdPath])} noStyle>
            {() => {
              return (
                getShipperId() && (
                  <div>
                    <h4>Importer of record</h4>
                    <RecordSelect
                      localNamePath={iorPath}
                      fullNamePath={iorPath}
                      recordType={RecordType.US_IOR}
                      $itemSize={CssSize.SHORT}
                      form={form}
                      hideLabel
                      required
                      disabled={loading}
                      onChange={(iorId: string | undefined) => {
                        if (iorId) {
                          void getIor({
                            variables: {
                              id: iorId,
                            },
                          });
                          void searchPendingIorBondReqs(
                            constructSearchPendingIorBondReqsInput(iorId),
                          );
                        }
                      }}
                      selectOneRecordByDefault
                      disableSelectionForSingleRecord
                      additionalSearchCriteria={
                        // for now disable filters for operators
                        !isOperatorUser
                          ? {
                              shipperId: {
                                values: [getShipperId()],
                                operator: 'EQUALS',
                              },
                            }
                          : {}
                      }
                    />
                  </div>
                )
              );
            }}
          </Form.Item>

          <Form.Item shouldUpdate={shouldUpdate([iorIdPath])} noStyle>
            {() => {
              if (!getIorId() || getIorResponse.loading) {
                return;
              }
              const ior = getRecordFromResponse(
                getIorResponse,
                'get',
                RecordType.US_IOR,
              );
              setIsIorActivated(getIsIorActivated({ ior, customsBrokerId }));
              return (
                <StyledIorWarningCard>
                  {ior && !isIorActivated && (
                    <StyledAlert
                      message={
                        <div>
                          <a
                            href={`/us-iors/${ior.id}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Importer of record
                          </a>{' '}
                          not active
                        </div>
                      }
                      type="warning"
                      showIcon
                      closable
                    />
                  )}
                  {ior &&
                    !ior.continuousBonds?.present &&
                    !searchPendingIorBondReqsResponse.loading && (
                      <StyledAlert
                        message={
                          iorHasPendingBondRequest ? (
                            'Continuous bond in process, we will file entry immediately upon approval'
                          ) : (
                            <div>
                              Importer does not have a continuous bond, click{' '}
                              <StyledAnchor
                                href="/us-ior-continuous-bond-requests"
                                target="_blank"
                              >
                                here
                              </StyledAnchor>{' '}
                              to request a continuous bond or we will purchase a
                              single entry bond when entry is filed
                            </div>
                          )
                        }
                        type="warning"
                        showIcon
                        closable
                      />
                    )}
                </StyledIorWarningCard>
              );
            }}
          </Form.Item>
        </StyledIorCard>
        <StyledMotCard>
          <div>
            <h4>Mode of transport</h4>
            <MotSelect
              form={form}
              required
              disabled={loading}
              initialValue={ModeOfTransport.OCEAN}
              onChange={(val: ModeOfTransport) => {
                form.setFields([
                  {
                    name: requestedWosPath,
                    value: getDefaultWorkOrdersForMot(val),
                  },
                ]);
              }}
            />
          </div>
          <Form.Item shouldUpdate={shouldUpdate([motPath])} noStyle>
            {() => {
              const mot: ModeOfTransport = form.getFieldValue(motPath);

              return (
                <div>
                  <h4>Services required</h4>

                  <SimpleSelect
                    loading={loading}
                    showAction={['click', 'focus']}
                    mode="multiple"
                    form={form}
                    required
                    localNamePath={requestedWosPath}
                    fullNamePath={requestedWosPath}
                    optionLabelProp="children"
                    allowClear
                    filterOption={() => true}
                    disabled={!mot}
                    map={(type: WorkOrderType) =>
                      // WorkOrderType needs to be constantCase to be converted
                      // to RecordType
                      formatRecordName({
                        recordType: constantCase(type) as RecordType,
                        accountType,
                      })
                    }
                    maxTagCount={2}
                    initialValue={getDefaultWorkOrdersForMot(mot)}
                    selectOptions={getAllowedWorkOrders(
                      user,
                      destinationCountry,
                      mot,
                    )}
                  />
                </div>
              );
            }}
          </Form.Item>
        </StyledMotCard>
        <Form.Item shouldUpdate={shouldUpdate([motPath])} noStyle>
          {() => {
            const mot: ModeOfTransport = form.getFieldValue(motPath);

            const getBillHeading = (
              billType: 'Master' | 'House',
              mot: ModeOfTransport,
            ) =>
              mot === ModeOfTransport.AIR
                ? `${billType} air waybill (optional)`
                : `${billType} bill of lading (optional)`;

            const maskHouseBill = (e: any) => {
              if (!e) {
                return e;
              }
              // 'mot' only applies to UsConsumptionEntry, but all ISF are ocean shipments so they should be length 16
              const maxLength = mot === ModeOfTransport.AIR ? 12 : 16;
              const values = e.map((val: string) =>
                val.toUpperCase().replace(' ', '').substr(0, maxLength),
              );
              return values;
            };

            return (
              <StyledBillCard>
                <div>
                  <h4>{getBillHeading('Master', mot)}</h4>
                  <MasterBill
                    mot={mot}
                    fullNamePath={masterBillPath}
                    localNamePath={masterBillPath}
                    form={form}
                    label={getBillHeading('Master', mot)}
                    hideLabel
                    debounce
                    onChange={(masterBill: string | undefined) =>
                      masterBill &&
                      searchShipmentsByMasterBill(
                        constructSearchShipmentInput('masterBill', [
                          masterBill,
                        ]),
                      )
                    }
                  />
                  <Form.Item
                    shouldUpdate={shouldUpdate([houseBillsPath])}
                    noStyle
                  >
                    {() =>
                      (masterBillDuplicateShipmentExists() &&
                        createDuplicateShipmentAlert(
                          searchShipmentsByMasterBillResponse,
                          false,
                        )) ||
                      undefined
                    }
                  </Form.Item>
                </div>
                <div>
                  <h4>{getBillHeading('House', mot)}</h4>
                  <MultipleSelectInput
                    getValueFromEvent={maskHouseBill}
                    debounce
                    form={form}
                    loading={loading}
                    localNamePath={houseBillsPath}
                    fullNamePath={houseBillsPath}
                    placeholder="AMZN1234567"
                    disabled={!mot}
                    maxTagCount={1}
                    onChange={(houseBills: any, option: any) => {
                      houseBills?.length &&
                        searchShipmentsByHouseBill(
                          constructSearchShipmentInput('houseBill', houseBills),
                        );
                    }}
                  />
                  <Form.Item
                    shouldUpdate={shouldUpdate([houseBillsPath])}
                    noStyle
                  >
                    {() =>
                      (houseBillDuplicateShipmentExists() &&
                        createDuplicateShipmentAlert(
                          searchShipmentsByHouseBillResponse,
                          true,
                        )) ||
                      undefined
                    }
                  </Form.Item>
                </div>
              </StyledBillCard>
            );
          }}
        </Form.Item>
        <Form.Item shouldUpdate={getForwarderId()} noStyle>
          {() => {
            if (getForwarderId()) {
              const forwarder = getRecordFromResponse(
                getForwarderResponse,
                'get',
                RecordType.FORWARDER,
              );
              const initialValue = isAGLForwarder(forwarder) ? 'CN' : undefined;
              return (
                <CountrySelect
                  disabled={loading}
                  $inline
                  required
                  localNamePath={sourceCountryPath}
                  label="Source country"
                  initialValue={initialValue}
                  dataCySuffix={DataCySuffix.SOURCE_COUNTRY}
                />
              );
            }
            return;
          }}
        </Form.Item>
        <StyledClientIdCard>
          <StyledClientId>
            <div>
              <h4>Reference number (optional)</h4>
              <ANNumber
                placeholder="Shipment reference number"
                form={form}
                fullNamePath={clientIdentifierPath}
                $itemSize={CssSize.TINY_SHORT}
                mask={maxLength} // Just to get around masking.
                max={24}
                label={''}
                debounce
                onChange={(clientIdentifier: string) =>
                  clientIdentifier &&
                  searchShipmentsByClientId(
                    constructSearchShipmentInput('clientIdentifier', [
                      clientIdentifier,
                    ]),
                  )
                }
              />
              <Form.Item
                shouldUpdate={shouldUpdate([clientIdentifierPath])}
                noStyle
              >
                {() =>
                  (clientIdDuplicateShipmentExists() &&
                    createDuplicateShipmentAlert(
                      searchShipmentsByClientIdResponse,
                      true,
                    )) ||
                  undefined
                }
              </Form.Item>
            </div>
            <div>
              <h4>Estimated arrival time</h4>
              <PickDate
                required={true}
                fullNamePath={['arrival', 'time']}
                localNamePath={['arrival', 'time']}
                disabledDate={() => false}
                form={form}
                dataCy={createDataCyValue(
                  DataCyPrefix.ARRIVAL_ETA,
                  DataCySuffix.DATE_SELECT,
                )}
              />
            </div>
          </StyledClientId>
          {isOperatorUser && (
            <Form.Item shouldUpdate={shouldUpdate([forwarderIdPath])} noStyle>
              {() => {
                return (
                  getForwarderId() === globalMileForwarderId && (
                    <div>
                      <h4>Preferred duty collect party type</h4>
                      <PreferredDutyCollectPartyTypeSelect
                        form={form}
                        disabled={loading}
                      />
                    </div>
                  )
                );
              }}
            </Form.Item>
          )}

          {isShipperUser && isSubscribedShipper && forwarderSelect}
        </StyledClientIdCard>

        {isOperatorUser && (
          <StyledManualCreationFeeCard>
            <Form.Item
              name={chargeManualCreationFeePath}
              getValueFromEvent={(e: any) => e.target.checked}
              noStyle
            >
              <Checkbox disabled={loading}>
                Charge manual shipment creation fee.
              </Checkbox>
            </Form.Item>
            <Form.Item
              shouldUpdate={shouldUpdate([chargeManualCreationFeePath])}
              noStyle
            >
              {() => {
                if (form.getFieldValue(chargeManualCreationFeePath)) {
                  return (
                    <StyledManualCreationFeePrompt>
                      {`The customer will be charged an additional $${getManualShipmentCreationFee()}`}
                    </StyledManualCreationFeePrompt> // Fetch the value
                  );
                }
                return;
              }}
            </Form.Item>
          </StyledManualCreationFeeCard>
        )}

        <HiddenField
          localNamePath={customsBrokerIdPath}
          fullNamePath={customsBrokerIdPath}
          value={customsBrokerId}
          form={form}
        />
        <HiddenField
          localNamePath={destinationCountryPath}
          fullNamePath={destinationCountryPath}
          value={destinationCountry}
          form={form}
        />
      </StyledForm>
    </StyledModal>
  );
};

export default withModal(ModalKey.CREATE_SHIPMENT)(CreateShipmentModal);
