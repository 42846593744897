import { executeMutation } from 'libs/executeMutation';
import { message } from 'antd';
import { WorkOrderType, UsCbpEntryReleaseStatus } from '@xbcb/work-order-types';
import { AccountType, AnyObject, RecordType } from '@xbcb/shared-types';
import { checkAccess, UserPermissions } from '@xbcb/client-utils';
import { isRecordCanceled, isRecordReplaced } from 'libs/workOrderConditions';
import { checkKebabMenuItemForWorkOrderTask } from '../utils/checkKebabMenuItemForWorkOrderTask';
import { flipWorkOrder as flipWorkOrderMutation } from 'libs/sharedQueries';
import { createFlipSubMenuOptions } from 'libs/createFlipSubMenuOptions';
import { uiStageToBackendStage } from '@xbcb/ui-types';
import { getEnv } from '@xbcb/ui-utils';
import { AppRecordKebabMenuItem } from 'routes/types';
import {
  classifyUsCustomsFiling,
  workOrderTypeToUsFilingType,
} from 'libs/usEntryCustomsClassificationTransformers';
import type {
  UsConsumptionEntry,
  UsType86Entry,
} from '@xbcb/api-gateway-client';
import {
  PATHFINDER_FEATURE_FLAG_SERVICE,
  PathfinderFeatures,
} from '@xbcb/feature-flags';
import { constantCase } from 'change-case';
import moment from 'moment';
const { stage } = getEnv();

const isPathfinderPhase1Enabled = PATHFINDER_FEATURE_FLAG_SERVICE.isEnabled(
  PathfinderFeatures.PHASE_1,
  {
    stage: uiStageToBackendStage[stage],
  },
);
const isPathfinderPhase2Enabled = PATHFINDER_FEATURE_FLAG_SERVICE.isEnabled(
  PathfinderFeatures.PHASE_2,
  {
    stage: uiStageToBackendStage[stage],
  },
);

const checkAccessForFlipMenu = (
  currentUser: any,
  currentWorkOrder: WorkOrderType,
  outputWorkOrderTypes: WorkOrderType[],
) =>
  // only allow operators to flip entries
  currentUser.accountType === AccountType.OPERATOR &&
  (isPathfinderPhase1Enabled
    ? // if pathfinder enabled, we only need to check access for creating
      // the output ("flip to") work orders and updating (cancelling) the
      // old work order
      outputWorkOrderTypes.every((wot) =>
        checkAccess(
          currentUser,
          workOrderTypeToRecordType(wot),
          UserPermissions.CREATE,
        ),
      ) &&
      checkAccess(
        currentUser,
        workOrderTypeToRecordType(currentWorkOrder),
        UserPermissions.UPDATE,
      )
    : // in old functionality (pre-Pathfinder), we flip entries by updating
      // the shipment record
      checkAccess(currentUser, RecordType.SHIPMENT, UserPermissions.UPDATE));

const workOrderTypeToRecordType = (wo: WorkOrderType) =>
  RecordType[constantCase(wo) as keyof typeof RecordType];

/**
 * Util to map UsConsumptionEntry and UsType86Entry to their corresponding page paths
 * @param {WorkOrderType} woType the WorkOrderType to turn into a page path
 * @return {string} woType's page path
 */
const typeToPagePath = (woType: WorkOrderType): string => {
  return woType === WorkOrderType.UsConsumptionEntry
    ? 'us-consumption-entries'
    : 'us-type86-entries';
};

const isSelectedOutputWorkOrderTypeValid = async (
  selectedOutputType: WorkOrderType,
  record: UsConsumptionEntry | UsType86Entry,
): Promise<boolean> => {
  try {
    // We want to ignore ad/cvd and fee validation on a WO flip
    const classifications = await classifyUsCustomsFiling(record, {
      ignoreAdCvdAndFees: true,
    });
    const expectedFilingType = workOrderTypeToUsFilingType(selectedOutputType);
    if (
      !classifications?.some(
        (classification) => classification.filingType === expectedFilingType,
      )
    ) {
      void message.error(
        `Validation failed, can't flip WO to ${selectedOutputType} because it is not eligible`,
      );
      return false;
    }
  } catch (error) {
    void message.error(`Error trying to validate WO flip, ${error}`);
    return false;
  }
  return true;
};

export const createFlipWorkOrderMenuItem = (args: {
  currentWorkOrderType: WorkOrderType;
  outputWorkOrderTypes: WorkOrderType[];
  validateFilingTypeClassificationBeforeFlip: boolean;
}): AppRecordKebabMenuItem => {
  const {
    currentWorkOrderType: currentWorkOrder,
    outputWorkOrderTypes,
    validateFilingTypeClassificationBeforeFlip: validateBeforeFlip,
  } = args;
  return {
    text: 'Flip Entry',
    key: 'flipWorkOrder',
    action: async ({ record, props = {}, history }) => {
      const { id: shipmentId, version: shipmentVersion } =
        record.group.shipment;
      const { id } = record;
      if (!props.key) {
        void message.error(
          `Sorry, an error occurred, please try again later.`,
          5.0,
        );
        return;
      }
      const selectedOutputType =
        WorkOrderType[props.key as keyof typeof WorkOrderType];
      const time = moment().format('YYYY-MM-DD-HH:mm');
      const idempotencyKey = id + '_TO_' + selectedOutputType + time;
      if (
        isPathfinderPhase2Enabled &&
        validateBeforeFlip &&
        !(await isSelectedOutputWorkOrderTypeValid(selectedOutputType, record))
      ) {
        return;
      }
      await executeMutation({
        mutation: flipWorkOrderMutation,
        variables: isPathfinderPhase1Enabled
          ? // use new WOA flip api
            {
              id,
              idempotencyKey,
              outputType: selectedOutputType,
            }
          : // use old shipment -> entry transform
            {
              id: shipmentId,
              version: shipmentVersion,
              input: {
                requestedWorkOrders: [props.key],
              },
            },
        onSuccess: async (data: AnyObject) => {
          const flippedEntryId = data?.flipWorkOrder?.record?.id;
          const pagePath = typeToPagePath(selectedOutputType);
          history.push(`/${pagePath}/${flippedEntryId}`); // redirect the operator to the new entry
        },
        successMessage: `Entry changed from ${currentWorkOrder} to ${selectedOutputType}`,
      });
    },
    show: ({
      existingRecord,
      currentUser,
      workOrderTaskType,
      workOrderTaskEvaluatedState,
    }) =>
      // only allow flipping from T86 pre-Pathfinder launch
      (currentWorkOrder === WorkOrderType.UsType86Entry ||
        isPathfinderPhase1Enabled) &&
      checkAccessForFlipMenu(
        currentUser,
        currentWorkOrder,
        outputWorkOrderTypes,
      ) &&
      !isRecordCanceled(existingRecord) &&
      !isRecordReplaced(existingRecord) &&
      existingRecord?.releaseStatus !== UsCbpEntryReleaseStatus.FILED &&
      checkKebabMenuItemForWorkOrderTask(
        workOrderTaskType,
        workOrderTaskEvaluatedState,
      ),
    SubMenuOptions: createFlipSubMenuOptions(outputWorkOrderTypes),
  };
};
