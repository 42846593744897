import React from 'react';
// import IncotermsSelect from 'components/IncotermsSelect';
import TeamDataModal from 'components/TeamDataModal';
import MIDInput from 'components/MID';
import FEINumber from 'components/FEINumber';
import PartyForm from 'components/PartyForm';
import { getCodes, useModal, safeGetMessage } from '@xbcb/ui-utils';
import { mid as generateMid } from '@xbcb/party-utils';
import { RecordType } from '@xbcb/shared-types';
import { ModalKey } from 'types';
import { RecordProps } from 'routes';
import { message } from 'antd';
import { useBundle } from '@amzn/react-arb-tools';

const Supplier: React.FC<RecordProps> = ({
  disabled,
  editMode,
  readOnly,
  form,
  hideDocs,
}) => {
  const [partyBundle] = useBundle('components.Party');
  const midFieldName = ['complianceDetails', 'us', 'mid'];
  const handleMidFocus = () => {
    try {
      const formValues = form.getFieldsValue();
      const address = formValues.addresses?.mailing
        ? formValues.addresses?.mailing
        : formValues.addresses?.physical;
      const mid = generateMid(address, formValues.name, getCodes());
      form.setFields([{ name: midFieldName, value: mid }]);
      // Intentionally validating so that it does not appear red / invalid in
      // the form after the autocomplete if the mid is actually valid
      void form.validateFields([midFieldName]);
    } catch (e) {
      void message.error(safeGetMessage(partyBundle, 'failed_to_generate_mid'));
    }
  };

  const { closeModal } = useModal(ModalKey.TEAM_DATA);

  return (
    <>
      <TeamDataModal closeModal={closeModal} recordType={RecordType.SUPPLIER} />
      <PartyForm
        recordType={RecordType.SUPPLIER}
        form={form}
        disabled={disabled}
        readOnly={readOnly}
        addressRequired
        hideDocs={hideDocs}
        hideBusinessStructure={true}
        additionalCompanyInfo={
          <>
            <MIDInput
              handleMidFocus={handleMidFocus}
              form={form}
              readOnly={readOnly}
              disabled={disabled}
              field={midFieldName}
            />
            <FEINumber
              form={form}
              readOnly={readOnly}
              disabled={disabled}
              $inline
              field={['complianceDetails', 'us', 'feiNumber']}
            />
            {/* TODO use this (or move to the necessary place) once we are ready to include relationships */}
            {/* {!editMode && (
            <IncotermsSelect readOnly={readOnly} disabled={disabled} />
          )} */}
          </>
        }
      />
    </>
  );
};

export default Supplier;
