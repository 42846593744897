import React from 'react';
import { gql } from '@apollo/client';
import { AppRecordSubmitButton } from 'routes';
import { ModalKey } from 'types';
import { safeGetMessage, setModal } from '@xbcb/ui-utils';
import { AppRecordOnConfirmPayload } from '../../types';
import {
  EscalationReasonSubCategoryType,
  TaskEscalationType,
  WorkOrderTaskStatus,
} from '@xbcb/work-order-types';
import { executeMutation } from 'libs/executeMutation';
import {
  EscalationReasonCategory,
  WorkOrder,
  WorkOrderTask,
  WorkOrderTaskEscalationInput,
} from '@xbcb/api-gateway-client';
import {
  AnyObject,
  RecordType,
  SubjectMatterCategory,
} from '@xbcb/shared-types';
import { isCustomsEntryOrDeclaration } from '@xbcb/work-order-utils/dist/isCustomsEntryOrDeclaration';
import moment from 'moment';
import { message } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';
import { fetchLatestRecordAndExecuteMutation } from 'libs/fetchLatestRecordAndExecuteMutation';
import {
  updateWorkOrderTaskStatus,
  getWorkOrderQuery,
} from 'libs/sharedQueries';
import { WorkOrderTaskEvaluatedState } from 'libs/evaluateWorkOrderTaskState';
import { getEscalatedToOperatorUser } from 'libs/getEscalatedTo';

const escalateWot = gql`
  mutation EscalateWorkOrderTask(
    $input: WorkOrderTaskEscalationInput!
    $taskId: ID!
    $version: Int!
  ) {
    escalateWorkOrderTask(input: $input, taskId: $taskId, version: $version) {
      workOrderTask {
        id
        version
      }
    }
  }
`;

export const escalateWorkOrderTaskButton = (): AppRecordSubmitButton => {
  const workOrderButton: AppRecordSubmitButton = {
    key: 'escalate',
    text: ({ appRecordBundle }) => (
      <div data-cy="escalate-button">
        {<ArrowUpOutlined />} {safeGetMessage(appRecordBundle, 'escalate')}
      </div>
    ),
    show: ({
      workOrderTaskType,
      workOrderTaskEvaluatedState,
      existingRecord,
    }) => {
      return (
        !isCustomsEntryOrDeclaration(existingRecord.id) &&
        workOrderTaskEvaluatedState === WorkOrderTaskEvaluatedState.STARTED &&
        !!workOrderTaskType
      );
    },

    onConfirm: ({ dispatch, existingRecord }) =>
      new Promise<AppRecordOnConfirmPayload>((resolve) => {
        dispatch(
          setModal({
            key: ModalKey.ESCALATE_WORK_ORDER_TASK,
            props: {
              visible: true,
              workOrders: [existingRecord],
              sendConfirmationDecision: resolve,
            },
          }),
        );
      }),

    skipValidation: () => true,
    skipUpdateRecord: () => true,
    onSubmit: async ({
      workOrderTaskType,
      updatedRecord,
      currentUser,
      history,
      confirmationData,
    }) => {
      const workOrder: WorkOrder = updatedRecord;
      const {
        details = '',
        category = '',
        subCategory = '',
      } = confirmationData || {};
      const subjectMatterCategories = Object.keys(SubjectMatterCategory);
      const escalationType = subjectMatterCategories.includes(category)
        ? TaskEscalationType.ESCALATED_TO_SUBJECT_MATTER_EXPERT
        : TaskEscalationType.ESCALATED_TO_TEAM_LEAD;
      const isEscalatedToSme =
        escalationType ===
        TaskEscalationType.ESCALATED_TO_SUBJECT_MATTER_EXPERT;

      let task: WorkOrderTask | undefined;
      if (workOrderTaskType) {
        task = workOrder.tasks.find(
          ({ definition }: WorkOrderTask) =>
            definition?.workOrderTaskType === workOrderTaskType,
        );
      }
      if (!task) {
        void message.error(`Sorry, no task found`, 5.0);
        return;
      }

      const escalatedTo = await getEscalatedToOperatorUser(
        task,
        isEscalatedToSme,
        category,
        subCategory,
      );

      if (!escalatedTo) {
        return;
      }

      const escalateInput: WorkOrderTaskEscalationInput = {
        escalationDetails: {
          details: details,
          escalatedTime: moment.utc(Date.now()).toISOString(),
          escalatedTo: { id: escalatedTo.id },
          escalatedBy: { id: currentUser?.id },
          escalationReasons: {
            reasonCategory: category as EscalationReasonCategory,
            reasonSubCategory: subCategory
              ? (subCategory as EscalationReasonSubCategoryType)
              : undefined,
          },
          escalationType,
        },
      };

      const successMessage = `${
        escalatedTo.name
      } will be notified to look into this task.${
        isEscalatedToSme
          ? ' You will be sent back to Assignment Workspace.'
          : ''
      }`;

      await executeMutation({
        mutation: escalateWot,
        successMessage,
        variables: {
          taskId: task?.id,
          version: task?.version,
          input: escalateInput,
        },
        refetchQueries: [
          {
            query: getWorkOrderQuery,
            variables: { id: workOrder.id, version: workOrder.version },
          },
        ],
      });

      // This mutation is added so that the UI re-renders with the updated task status.
      await fetchLatestRecordAndExecuteMutation({
        id: task?.id,
        fields: 'status',
        recordType: RecordType.WORK_ORDER_TASK,
        successMessage: `Work Order Task is no longer in progress`,
        mutation: updateWorkOrderTaskStatus,
        constructVariablesWithIdVersion: (id, version) => ({
          id,
          version,
          input: {
            status: WorkOrderTaskStatus.ESCALATED,
            subStatus: null,
          },
        }),
        // Skip in case escalation failed
        shouldSkipMutation: (latestRecord: AnyObject) => {
          return latestRecord.status !== WorkOrderTaskStatus.ESCALATED;
        },
      });

      // Redirect to assignment workspace if escalationType is ESCALATED_TO_SUBJECT_MATTER_EXPERT
      if (isEscalatedToSme) {
        history.push('/assignment-workspace');
      }
    },
  };
  return workOrderButton;
};
