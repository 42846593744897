import { gql } from '@apollo/client';
import {
  userFragments,
  userWorkingHoursFields,
  userWorkDayFields,
  operatorUserPermissions,
  shipperUserPermissions,
  forwarderUserPermissions,
} from '@xbcb/client-queries';

const documentFragments = `fragment documentFields on Document {
  preview {
    downloadLink
  }
  documentTags
  created {
    time
  }
}`;
const forwarderUserFields = `{
  operator {
    id
    version
    name
  }
  documents {
    ...documentFields
  }
  forwarder {
    id
    name
    globalLogisticsProfileId
    poaMethod
    documents {
      ...documentFields
    }
    mailFrom {
      fromEmail
      fromTitle
      fromSignature
    }
    billingDetails {
      subscriptionPlan
    }
  }
  cargowise {
    cargowiseId
  }
  ${forwarderUserPermissions}
}`;

const shipperUserFields = `{
  operator {
    id
    version
    name
  }
  documents {
    ...documentFields
  }
  shipper {
    id
    name
    globalLogisticsProfileId
    documents {
      ...documentFields
    }
    billingDetails {
      subscriptionPlan
    }
  }
  ${shipperUserPermissions}
}`;

const currentUserFields = `{
  ...userFields
  ...recordFields
  teams {
    id
    tags {
      key
      value
    }
  }
  ... on OperatorUser {
    documents {
      ...documentFields
    }
    customsBroker {
      id
      name
      tags {
        key
        value
      }
    }
    dailyGoal
    assumedUser {
      id
      name
      root
      ... on ShipperUser ${shipperUserFields}
      ... on ForwarderUser ${forwarderUserFields}
    }
    operator {
      id
      version
      name
    }
    workingHours {
      ...userWorkingHoursFields
    }
    ${operatorUserPermissions}
    assignmentTeams {
      edges {
        node {
          id
          name
          backupTeam {
            id
          }
          workOrderTaskTypes
        }
        relationship {
          roles
        }
      }
        nodes {
          id
        }
    }
    subjectMatterExpertTeams {
      edges {
        node {
          id
        }
      }
    }
    businessSupportTeams {
      nodes {
        id
      }
    }
  }
  ... on ShipperUser ${shipperUserFields}
  ... on ForwarderUser ${forwarderUserFields}
}`;

export const Login = gql`
  query Login($cognitoSub: ID) {
    login(cognitoSub: $cognitoSub) {
      user ${currentUserFields}
      intercomHash
    }
  }
  ${userFragments}
  ${documentFragments}
  ${userWorkDayFields}
  ${userWorkingHoursFields}
`;

export const AuthenticationContext = gql`
query AuthenticationContext {
	authenticationContext {
		authenticatedUserContext {
		  intercomHash
			... on FederatedUserContext {
				user { 
          __typename
					... on FederatedShipperUser {
						root
						operator { id }
					  shipper { id }
					}
				}  
			}
			... on UserContext {
        user ${currentUserFields}
			}
		}
	}
}
  ${userFragments}
  ${documentFragments}
  ${userWorkDayFields}
  ${userWorkingHoursFields}
`;
