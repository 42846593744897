import { AccountType } from '@xbcb/shared-types';
import {
  FederatedShipperUser,
  ForwarderUser,
  OperatorUser,
  ShipperUser,
} from '__generated__/graphql';

export const getUserAccountType = (
  user?: OperatorUser | ForwarderUser | ShipperUser | FederatedShipperUser,
): AccountType | undefined => {
  return (
    user?.__typename &&
    {
      ShipperUser: AccountType.SHIPPER,
      ForwarderUser: AccountType.FORWARDER,
      OperatorUser: AccountType.OPERATOR,
      FederatedShipperUser: AccountType.SHIPPER,
    }[user.__typename]
  );
};
