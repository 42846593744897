import { useLazyQuery } from '@apollo/client';
import { Form } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd/lib/form';
import React, { memo, useCallback, useState } from 'react';
import type { AdCvdCase, UsPrincipalAdCvdCase } from '@xbcb/api-gateway-client';
import { FormItem } from '@xbcb/form-item-components';
import { getRecordFromResponseV2 } from '@xbcb/shared-queries';
import { RecordType } from '@xbcb/shared-types';
import { NamePath } from '@xbcb/ui-types';
import NotFoundButton from 'components/NotFoundButton';
import { SEARCH_ADCVD_CASES } from 'libs/sharedQueries';
import { getCascaderOptions, CascaderOption } from './helpers';
import { StyledCascader, StyledContainer } from './styles';

const getValue = (selectedOptions: any): undefined | string[] => {
  if (!selectedOptions?.length) return undefined;
  if (selectedOptions.length === 1) {
    return selectedOptions[0].isLeaf ? selectedOptions[0].value : undefined;
  } else {
    return selectedOptions[1].value;
  }
};

export interface LineItemCaseSelectProps {
  currentCase?: AdCvdCase;
  principalAdCvdCases: UsPrincipalAdCvdCase[];
  caseType: 'ad' | 'cv';
  fullNamePath: NamePath;
  form: FormInstance;
  queryCaseNumber: (caseNumber: string) => void;
}

export const adCvdCaseToCascaderOption = (
  adCvdCase: AdCvdCase,
): CascaderOption => ({
  isLeaf: true,
  value: adCvdCase.id,
  label: adCvdCase.caseNumber,
  children: [],
});

const displayRender = (
  labels: string[],
  selectedOptions: CascaderOption[] | undefined,
) =>
  labels.map((label, i) => {
    const option = selectedOptions?.[i];
    if (!option) return label;
    return (
      <span key={option.value}>
        {label.includes(' (') ? label.replace(/ \(.*\)/, '') : label}
      </span>
    ); // strip the manufacturer information off if we've added it.  Kind've a hack but we're limited by what info is available on CascaderOption
  });

const LineItemCaseSelect: React.FC<LineItemCaseSelectProps> = ({
  principalAdCvdCases,
  currentCase,
  caseType,
  form,
  fullNamePath,
  queryCaseNumber,
}) => {
  const { setFields } = form;
  const [searchText, setSearchText] = useState('');
  const [searchAdCvdCases, response] = useLazyQuery(SEARCH_ADCVD_CASES);

  const searchResults: AdCvdCase[] =
    getRecordFromResponseV2({
      response,
      crudOrSearchType: 'table',
      recordName: RecordType.AD_CVD_CASE,
    })?.results ?? [];

  const options: CascaderOption[] =
    getCascaderOptions(principalAdCvdCases) ?? [];

  const searchOptions = searchResults.map(adCvdCaseToCascaderOption) ?? [];
  const selectedOption = currentCase
    ? adCvdCaseToCascaderOption(currentCase)
    : null;

  const combinedOptions: CascaderOption[] = [...searchOptions, ...options];
  if (selectedOption) {
    combinedOptions.push(selectedOption);
  }
  const handleChange = (_: (string | number)[], selectedOptions: any) => {
    const value = getValue(selectedOptions);
    setFields([{ name: [...fullNamePath, 'id'], value }]);
  };

  const getSelectedOption = (caseId?: string) => {
    /**
     * Cascader expects essentially a path of values leading to the selected option.
     * Since all we have when loading a previously saved record is the case ID, we need to
     * build up the path ourselves.
     */
    for (const principalCase of combinedOptions) {
      const principalCaseId = principalCase.value;
      if (principalCaseId === caseId) {
        // Previous search result was saved to the form
        return [caseId];
      }
      const selectedId = principalCase.children?.find(
        (childCase) => childCase.value === caseId,
      )?.value;
      if (selectedId) {
        return [principalCaseId, selectedId];
      }
    }
    // Not found
    return caseId ? [caseId] : undefined;
  };

  const filter = (inputValue: string, path: CascaderOption[]) =>
    path.some(
      (option) =>
        (option.label as string)
          .toLowerCase()
          .indexOf(inputValue.toLowerCase()) > -1,
    );
  const handleClick = useCallback(() => {
    queryCaseNumber(searchText);
  }, [queryCaseNumber, searchText]);

  const handleSearch = (value: string) => {
    setSearchText(value);
    if (value.length === 10) {
      // Do a search for a complete number only, as per requirements
      void searchAdCvdCases({
        variables: {
          input: {
            searchCriteria: {
              caseNumber: {
                values: [value],
                operator: 'EQUALS',
              },
            },
          },
        },
      });
    }
  };

  const val = Form.useWatch([...fullNamePath, 'id'], form);
  const selectedValue = getSelectedOption(val);

  return (
    <StyledContainer>
      <FormItem label={`${caseType.toLocaleUpperCase()} Case Number`}>
        <StyledCascader
          allowClear
          showSearch={{ filter }}
          value={selectedValue}
          displayRender={displayRender}
          options={combinedOptions}
          onChange={handleChange}
          onSearch={handleSearch}
          placeholder="Select"
          suffixIcon={<SearchOutlined />}
          notFoundContent={
            <NotFoundButton
              buttonText="Query from CBP"
              notFoundText="Case number not found"
              onClick={handleClick}
            />
          }
          changeOnSelect
          expandTrigger="hover"
        />
      </FormItem>
    </StyledContainer>
  );
};

export default memo(LineItemCaseSelect);
