import React from 'react';
import { Form } from 'antd';
import { CssSize, NamePath } from '@xbcb/ui-types';
import { shouldUpdate } from '@xbcb/ui-utils';
import { StyledHiddenFormItem } from './styles';
import { HiddenField } from '@xbcb/form-item-components';
import MoneyInput from 'components/MoneyInput';
import { get } from 'lodash';
import { FormInstance } from 'antd/lib/form';

interface CurrencyFieldProps {
  form: FormInstance;
  // TODO: names for namePath need to be changed
  fullNamePath: NamePath;
  localNamePath?: NamePath;
  max?: number;
  readOnly?: boolean;
  disabled?: boolean;
  onChange?: (value: number | undefined) => void;
  step?: number;
  $itemSize?: CssSize | string;
  label?: React.ReactNode;
  precision?: number;
  required?: boolean;
  currency?: string;
  debounce?: boolean;
  currencyNamePath?: NamePath;
  min?: number;
}

const CurrencyField = ({
  form,
  $itemSize = CssSize.LARGE,
  max = 100000000,
  label,
  localNamePath,
  fullNamePath,
  disabled,
  readOnly,
  onChange,
  precision = 2,
  step = 1,
  required = false,
  currency = 'USD',
  debounce = false,
  min,
  currencyNamePath, // provide this instead of currency if the CurrencyField is dependent on a dynamic currency input.
}: CurrencyFieldProps): React.ReactElement => {
  // input number needs an input mask like text-mask-core, but that doesnt work with it since it has its own formatter and parser methods, so we roll our own
  if (!localNamePath) localNamePath = fullNamePath;
  const fullValueNamePath = [...fullNamePath, 'value'];
  return (
    <Form.Item
      noStyle
      shouldUpdate={shouldUpdate([
        ...(currencyNamePath ? [currencyNamePath] : []),
        fullValueNamePath,
      ])}
    >
      {({ getFieldValue }) => {
        const currencyVal =
          (currencyNamePath && getFieldValue(currencyNamePath)) || currency;
        return (
          <>
            {' '}
            <MoneyInput
              $itemSize={$itemSize}
              max={max}
              label={label}
              localNamePath={[...(localNamePath ?? []), 'value']}
              fullNamePath={fullValueNamePath}
              disabled={disabled}
              readOnly={readOnly}
              onChange={onChange}
              precision={precision}
              step={step}
              required={required}
              currency={currencyVal}
              debounce={debounce}
              min={min}
              suffix={(localNamePath ?? []).join('-')}
            />
            <StyledHiddenFormItem
              shouldUpdate={(prevValues, curValues) =>
                get(prevValues, fullValueNamePath) !==
                get(curValues, fullValueNamePath)
              }
            >
              {({ getFieldValue }) =>
                getFieldValue(fullValueNamePath) !== undefined &&
                localNamePath && (
                  <HiddenField
                    localNamePath={[...localNamePath, 'currency']}
                    fullNamePath={[...fullNamePath, 'currency']}
                    value={currencyVal}
                    form={form}
                  />
                )
              }
            </StyledHiddenFormItem>
          </>
        );
      }}
    </Form.Item>
  );
};

export default CurrencyField;
