import { RecordType } from '@xbcb/shared-types';
import { Importer } from 'routes/loadableComponents';
import { AppRecordRoute } from '../../../types';
import { appRecordMemoMenuItem } from '../../utils';
import { queryImporter, updateImporter } from './importer.query';
import { AddressV2 } from '__generated__/graphql';

type AddressType = '1' | '2' | '3';

const importerRoute: AppRecordRoute = {
  Page: Importer,
  recordType: RecordType.IMPORTER,
  queries: {
    get: queryImporter,
    update: updateImporter,
  },
  kebabMenuItems: [appRecordMemoMenuItem],

  /* Hide the Importer edit button for users with Forwarder role 
  to prevent users from modifying the record */
  hideDefaultEditButton: ({ accountType }) => {
    return true;
    // return accountType === AccountType.FORWARDER;
  },
  /* Hide the Importer delete kebab menu option for users with Forwarder role 
  to prevents users from deleting the record */
  hideDeleteKebabMenuItem: ({ accountType }) => {
    return true;
    // accountType === AccountType.FORWARDER
  },
  transformRecordToInput: (props) => {
    const { existingRecord } = props;

    const addressTypeLabels: Record<AddressType, string> = {
      '1': 'Mailing',
      '2': 'Financial',
      '3': 'Physical',
    } as const;

    existingRecord?.addressList?.forEach((address: AddressV2) => {
      address.type =
        addressTypeLabels[address.type as AddressType] ?? address.type;
    });

    return existingRecord;
  },
};

export default importerRoute;
