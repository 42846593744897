import { gql } from '@apollo/client';
import { memoFragments } from '@xbcb/client-queries';

const addressFragment = gql`
  fragment addressFields on AddressV2 {
    type
    cityName
    country
    countrySubDivision
    countrySubDivisionName
    line
    postCode
    streetName
    houseNumber
    houseNumberAddition
    postOfficeBox
  }
`;

const addressesFragment = gql`
  fragment addressesFields on Party {
    addressList {
      ...addressFields
    }
  }
  ${addressFragment}
`;

const phoneFragment = gql`
  fragment phoneFields on Phone {
    country
    number
    extension
  }
`;

const recordActionFragment = gql`
  fragment recordActionFields on RecordAction {
    source {
      __typename
      ... on InternalSystem {
        internalSystemName: name
      }
      ... on InternalWebsite {
        internalWebsiteName: name
      }
      ... on ClientSystem {
        clientSystemName: name
      }
    }
    time
    reason {
      code
      text
    }
    client {
      id
    }
    request {
      ipAddress
      userAgent
      requestId
    }
  }
`;

const recordFragment = gql`
  fragment recordFields on Record {
    created {
      ...recordActionFields
    }
    updated {
      ...recordActionFields
    }
    deleted {
      ...recordActionFields
    }
    id
    version
    tags {
      key
      value
    }
  }
  ${recordActionFragment}
`;

const partyFragment = gql`
  fragment partyFields on Party {
    name
    alternateName
    description
    ...addressesFields
    phone {
      ...phoneFields
    }
    email
    pointOfContact {
      name
      title
      email
      phone {
        ...phoneFields
      }
    }
  }
  ${addressesFragment}
  ${phoneFragment}
`;

export const importerFragment = gql`
  fragment importerFields on Importer {
    ...recordFields
    ...partyFields
    memos {
      ...memoFields
    }
    identifiers {
      identification
      identificationType
    }
    externalClientReferences
    importerEntities {
      nodes {
        id
      }
    }
  }
  ${recordFragment}
  ${partyFragment}
  ${memoFragments}
`;
