import { RecordType } from '@xbcb/shared-types';
import { getRecordType } from '@xbcb/core';
import { isDeloreanBasicPhaseEnabled } from 'libs/featureFlags';

export const getRedirectUrlForBroker = (customsBrokerId: string): string => {
  const currentActiveBrokerId =
    localStorage.getItem('ACTIVE_BROKER_ID') || customsBrokerId;
  let redirectURL = '/shipments';
  if (currentActiveBrokerId) {
    const recordType = getRecordType(currentActiveBrokerId) as RecordType;
    if (recordType === RecordType.DE_CUSTOMS_BROKER) {
      redirectURL = '/de-customs-entries';
    } else if (recordType === RecordType.UK_CUSTOMS_BROKER) {
      redirectURL = '/gb-customs-entries';
    } else if (recordType === RecordType.NL_CUSTOMS_BROKER) {
      redirectURL = isDeloreanBasicPhaseEnabled
        ? '/customs-declarations'
        : '/nl-customs-entries';
    } else if (recordType === RecordType.FR_CUSTOMS_BROKER) {
      redirectURL = '/fr-customs-entries';
    } else if (recordType === RecordType.BROKER) {
      redirectURL = '/customs-declarations';
    }
  }

  return redirectURL;
};
