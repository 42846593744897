import {
  TABLES_SAVE,
  TABLES_UPDATE,
  TABLE_REPLACE,
  TABLES_REPLACE,
  TABLE_MOVE_COLUMN,
  TableMoveColumnPayload,
  TablesPayload,
  TableReplacePayload,
} from '../types';
import { get } from 'lodash';
import { unMomentize } from '@xbcb/ui-utils';
import { createAction } from '@reduxjs/toolkit';
import { TableOptions } from '@xbcb/table-utils';

export const tablesSave = createAction<TablesPayload, typeof TABLES_SAVE>(
  TABLES_SAVE,
);
export const tablesUpdate = createAction<TablesPayload, typeof TABLES_UPDATE>(
  TABLES_UPDATE,
);

export const tableReplace = createAction<
  TableReplacePayload,
  typeof TABLE_REPLACE
>(TABLE_REPLACE);
// useful for loading assignment queues in actions/profile
export const tablesReplace = createAction<
  TableReplacePayload[],
  typeof TABLES_REPLACE
>(TABLES_REPLACE);
export const tableMoveColumn = createAction<
  TableMoveColumnPayload,
  typeof TABLE_MOVE_COLUMN
>(TABLE_MOVE_COLUMN);

export interface PersistTableInput extends TableOptions {
  columns?: string[];
  tableName: string;
}
export const persistTable =
  ({
    columns,
    current,
    filters: momentizedFilters,
    tableName,
    pageSize,
    sortField,
    sortOrder,
  }: PersistTableInput) =>
  async (dispatch: any, getState: any) => {
    // TODO update dispatch type
    const state = getState();
    const reduxColumns = get(state, ['tables', tableName, 'columns'], []);
    const filters = unMomentize(momentizedFilters, undefined);
    // const quickKeys = Object.keys(filters).filter((f) => filters[f].quick);
    const table = {
      columns: columns ? columns : reduxColumns,
      current,
      filters,
      tableName,
      pageSize,
      sortField,
      sortOrder,
    };
    dispatch(tableReplace({ tableName, table }));
  };
