import { AnyObject, RecordType } from '@xbcb/shared-types';
import { FormInstance } from 'antd/lib/form';
import { addressesFields } from '@xbcb/party-queries';
import { getOneQueryLite, getRecordFromResponse } from '@xbcb/shared-queries';
import { client as apolloClient } from '@xbcb/apollo-client';
export const updateIorOnEntry = async (
  existingWorkOrder: AnyObject,
  form: FormInstance,
  isPsc = false,
): Promise<void> => {
  const baseFullNamePath = ['ior'];
  const usIorFullNamePath = [...baseFullNamePath, 'usIor'];
  const usIorIdFullNamePath = [...usIorFullNamePath, 'id'];
  const usIorVersionFullNamePath = [...usIorFullNamePath, 'version'];
  const usIorDeletedFullNamePath = [...usIorFullNamePath, 'deleted'];
  const recordType = RecordType.US_IOR;
  const existingUsIorId = existingWorkOrder.ior.usIor.id;
  const newUsIorId = form.getFieldValue(usIorIdFullNamePath);

  // If IOR id in form is different from what's in the existing WO, it means
  // user has changed the IOR and we don't need to update anymore.
  if (newUsIorId !== existingUsIorId) {
    return;
  }

  // embeddedFields contains all the fields of IOR we need to update onSubmit
  let iorEmbeddedFields = `
  ...addressesFields 
  version 
  deleted { 
    time
  }`;
  if (!isPsc) {
    // These fields are not permitted in the input for a PSC
    iorEmbeddedFields = `
      ${iorEmbeddedFields}
      pointOfContact { 
        name 
        email 
        phone { 
          country 
          number 
          extension 
        } 
      }
      payerUnitNumber 
      pmsStatus 
      preferredCbpEntryPaymentMethod
      duns
      unknownDuns
    `;
  }

  const options = { variables: { id: existingUsIorId } };

  const { data, errors } = await apolloClient.query({
    query: getOneQueryLite({
      recordName: recordType,
      fields: iorEmbeddedFields,
      fragments: addressesFields,
      queryName: 'iorEmbeddedFields',
    }),
    variables: options.variables,
    fetchPolicy: 'network-only',
  });
  if (errors) {
    return;
  }
  const record = getRecordFromResponse({ data }, 'get', recordType);
  // embedded/snapshot-ed fields are stored one level above the id + version leaf fields.

  const { id, version, deleted, ...embeddedFields } = record;
  // Mapping the fields from current record to the latest values from the Query response
  embeddedFields.preferredPaymentMethod =
    embeddedFields.preferredCbpEntryPaymentMethod;
  embeddedFields.preferredCbpEntryPaymentMethod = undefined;
  // All the usIor fields that are to be updated with the latest values
  const fieldsToSet = Object.entries(embeddedFields).map(([field, value]) => ({
    name: [...baseFullNamePath, field],
    value,
  }));
  // Adding usIor version to the set of fields to be updated in the form
  fieldsToSet.push({
    name: usIorVersionFullNamePath,
    value: version,
  });
  // Adding usIor deleted to the set of fields to be updated in the form
  fieldsToSet.push({
    name: usIorDeletedFullNamePath,
    value: deleted,
  });
  // Setting the Form and WorkOrder with the most upto date usIor Field values
  form.setFields(fieldsToSet);
  existingWorkOrder.ior.usIor = record;
};
