import { FormInstance } from 'antd/lib/form';
import React from 'react';
import type {
  DeIor,
  DeIorActivation,
  GbIor,
  GbIorActivation,
  NlIor,
  NlIorActivation,
  FrIor,
  FrIorActivation,
} from '@xbcb/api-gateway-client';
import { SingleFormCard } from '@xbcb/display-components';
import { EuIorInfoCard } from '@xbcb/form-components';
import {
  DeIorActivationDetails,
  GbIorActivationDetails,
  NlIorActivationDetails,
  FrIorActivationDetails,
} from '@xbcb/party-schema';
import { PartyRecordType, RecordType } from '@xbcb/shared-types';
import { CssSize, MessageBundle } from '@xbcb/ui-types';
import RecordSelect from 'components/RecordSelect';
import { useCurrentUser } from 'libs/hooks';
import ActivationInfo from 'components/ActivationInfo';
import { useBundle } from '@amzn/react-arb-tools';
import { safeGetMessage } from '@xbcb/ui-utils';
import { getLocalizedRecordName } from 'libs/localizationHelpers';

export type EuIorType = GbIor | DeIor | NlIor | FrIor;

export const pathToEuIor = ['ior', 'ior'];
export type EuIorActivationWorkOrder =
  | DeIorActivation
  | GbIorActivation
  | NlIorActivation
  | FrIorActivation;

export const getEuIorRecordType = (
  recordType: RecordType,
  bundle?: MessageBundle,
): Partial<RecordType> => {
  if (recordType === RecordType.GB_IOR_ACTIVATION) {
    return RecordType.GB_IOR;
  } else if (recordType === RecordType.DE_IOR_ACTIVATION) {
    return RecordType.DE_IOR;
  } else if (recordType === RecordType.NL_IOR_ACTIVATION) {
    return RecordType.NL_IOR;
  } else if (recordType === RecordType.FR_IOR_ACTIVATION) {
    return RecordType.FR_IOR;
  } else {
    throw new Error(
      bundle
        ? safeGetMessage(bundle, 'unsupported_record_type', { recordType })
        : `Unsupported record type for EU IOR Activation: ${recordType}`,
    );
  }
};

export const getActivationRecord = (
  workOrderBrokerId: string,
  euIor?: EuIorType,
):
  | GbIorActivationDetails
  | DeIorActivationDetails
  | NlIorActivationDetails
  | FrIorActivationDetails => {
  // This should have only one record per broker
  const activationBrokerRecord = (
    euIor?.activations as Array<
      | GbIorActivationDetails
      | DeIorActivationDetails
      | NlIorActivationDetails
      | FrIorActivationDetails
    >
  ).filter(
    (activation) => activation.customsBroker.id === workOrderBrokerId,
  )[0];

  return activationBrokerRecord;
};

export type EuIorActivationSummaryProps = {
  form: FormInstance;
  disabled?: boolean;
  readOnly?: boolean;
  record: EuIorActivationWorkOrder;
  recordType: RecordType;
};

const EuIorActivationSummary: React.FC<EuIorActivationSummaryProps> = ({
  form,
  record,
  recordType,
}) => {
  const [bundle] = useBundle('components.EuIorActivation');
  const [recordNameBundle] = useBundle('types.shared.enums.RecordName');
  const { accountType } = useCurrentUser();
  const activationsPath = [...pathToEuIor, 'activations'];
  const workOrderBrokerId = record.broker.customsBroker.id;
  const euIorRecordType = getEuIorRecordType(recordType, bundle);

  return (
    <>
      <SingleFormCard
        title={safeGetMessage(bundle, 'general_info')}
        titlePosition="top"
        topLine={true}
      >
        <RecordSelect
          label={getLocalizedRecordName(
            recordNameBundle,
            euIorRecordType,
            accountType,
          )}
          required
          recordType={euIorRecordType}
          fullNamePath={pathToEuIor}
          form={form}
          disabled={true}
          versioned
          readOnly={true}
          $itemSize={CssSize.SHORT}
          targetBlank
        />
        <EuIorInfoCard
          recordType={euIorRecordType as PartyRecordType}
          form={form}
          disabled
          readOnly
          fullNamePath={pathToEuIor}
        />
      </SingleFormCard>
      <SingleFormCard
        title={safeGetMessage(bundle, 'activation_info')}
        titlePosition="top"
        topLine={true}
      >
        <ActivationInfo
          form={form}
          fullNamePath={activationsPath}
          readOnly
          disabled
          workOrderBrokerId={workOrderBrokerId}
        />
      </SingleFormCard>
    </>
  );
};

export default EuIorActivationSummary;
