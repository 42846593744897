import React from 'react';
import { Modal, message } from 'antd';
import { memoFragments } from '@xbcb/client-queries';
import { checkAccess, UserPermissions } from '@xbcb/client-utils';
import { AccountType, RecordType, RecordStatus } from '@xbcb/shared-types';
import { shipmentBaseFragments } from '@xbcb/shipment-queries';
import { AppRecordOnConfirmPayload, AppRecordRoute } from '../types';
import {
  getShipmentTitleField,
  shipmentTitleOverride,
} from 'libs/getRecordTitleField';
import { Shipment } from '../loadableComponents';
import { executeMutation } from 'libs/executeMutation';
import { InvoiceType } from '@xbcb/finance-types';
import {
  createOneMutation,
  updateOneMutation,
  uncancelOneMutation,
  cancelOneMutation,
} from '@xbcb/shared-queries';
import {
  UpdateShipmentInput,
  CreateInvoiceLinesRequestInput,
} from '@xbcb/api-gateway-client';
import { safeGetMessage, setModal } from '@xbcb/ui-utils';
import { ModalKey } from '@xbcb/ui-types';
import { appRecordMemoMenuItem } from './utils';
import DutyCollectionPartyOptions from 'components/DutyCollectionPartyOptions';
import { addressesFields } from '@xbcb/party-queries';
import { markWorkOrderReadyButtons } from './utils/markWorkOrdersReadyButton';
import ShipmentSecondaryHeader from 'components/ShipmentSecondaryHeader';
import TagForwarderButton from 'components/TagForwarderButton';
import { markWorkOrderHotButtons } from './utils/markWorkOrderHotButton';
import { transformShipment } from 'libs/formTransforms';
import PONumbers from 'components/PONumbers';
import { isAGLForwarder } from '@xbcb/party-utils';
import { usConsumptionEntryFragments } from 'libs/sharedQueries';
import { getLocalizedRecordName } from 'libs/localizationHelpers';

const fields = `
  ...shipmentBaseFields
  status
  externalIntegrationReferences {
    externalIntegrationPartner
    externalIntegrationType
  }
  clientReferences {
    bookingId
  }
  workOrderGroup {
    id
    version
    documents {
      id
    }
    memos {
      ...memoFields
    }
    shipment {
      id 
      version
      preferredDutyCollectPartyType
    }
    workOrders {
      id
      version
      status
      ... on UsConsumptionEntry {
        ...usConsumptionEntryFields
        badges
        arrival {
          firmsCode
        }
        masterBills {
          number
          houseBills {
            number
          }
        }
        containers {
          number
          seal
          type
          weight
          quantity
          description
        }
      }
    }
    forwarders {
      forwarder {
        id
        name
        externalIntegrationReferences {
          externalIntegrationPartner
          externalIntegrationType
        }
        billingDetails {
          disbursement
          subscriptionPlan
          status
        }
      }
    }
    shipper {
      id
      billingDetails {
        disbursement
        subscriptionPlan
        status
        accountingSystemCustomerReferences {
          type
          value
        }
      }
      name
    }
    tags {
      key
      value
    }
  }
  forwarders {
    forwarder {
      tags {
        key
        value
      }
    }
  }
  badges
  legs {
    loadType
    transportationServiceName
    ior {
      ior {
        id
        version
        name
        ...on UsIor {
          iorNumber {
            type
            value
          }
        }
      }
    }
    consignee {
      consignee {
        id
        version
        name
        ...addressesFields
      }
    }
  }
`;

const shipmentRoutes: AppRecordRoute[] = [
  {
    Page: Shipment,
    recordType: RecordType.SHIPMENT,
    fields,
    fragments: `${shipmentBaseFragments}${memoFragments}${addressesFields}${usConsumptionEntryFragments}`,
    getTitleField: getShipmentTitleField,
    kebabMenuItems: [
      appRecordMemoMenuItem,
      {
        key: 'modifyPreferredDutyCollectPartyType',
        text: 'Modify Duty Collection Party',
        show: ({ currentUser }) =>
          currentUser.accountType === AccountType.OPERATOR,
        action: async ({ record, props }) => {
          if (!props) {
            void message.error('Invalid Collection Type');
            return;
          }

          // TODO Had to add shipper in the payload as there is an issue with Sparse update of shipments.
          // Remove this when sparse update works for shipment
          const { shipper } = record;

          const operatorId = record.operator?.id;
          const createInvoiceLinesMutation = createOneMutation({
            recordName: RecordType.INVOICE_LINES_REQUEST,
          });
          const updateShipmentMutation = updateOneMutation({
            recordName: RecordType.SHIPMENT,
          });

          void message.info('Processing Request');

          await executeMutation({
            mutation: updateShipmentMutation,
            variables: {
              version: record.version,
              id: record.id,
              input: {
                // TODO remove this after the sparse update of update shipment starts working
                shipper,
                preferredDutyCollectPartyType: props?.key,
              } as UpdateShipmentInput,
            },
            successMessage: `Duty collector party changed to ${props?.key}`,
          });

          for (const workOrder of record.workOrderGroup.workOrders) {
            await executeMutation({
              mutation: createInvoiceLinesMutation,
              variables: {
                input: {
                  operation: 'AMEND',
                  type: InvoiceType.WORK_ORDER_GROUP,
                  operator: { id: operatorId },
                  workOrder: { id: workOrder.id },
                } as CreateInvoiceLinesRequestInput,
              },
              successMessage: `Invoice for work order ${workOrder.id} Updated`,
            });
          }
        },
        SubMenuOptions: DutyCollectionPartyOptions,
      },
      {
        key: 'addWorkOrder',
        text: 'Add Work Order',
        action: ({ dispatch, record }) => {
          dispatch(
            setModal({
              key: ModalKey.CREATE_WORK_ORDER,
              props: {
                record,
                visible: true,
              },
            }),
          );
        },
      },
    ],
    submitButtons: [
      ...markWorkOrderReadyButtons(),
      ...markWorkOrderHotButtons(),
      {
        key: 'cancelShipment',
        text: ({ currentUser, appRecordBundle, recordNameBundle }) => {
          const recordName = getLocalizedRecordName(
            recordNameBundle,
            RecordType.SHIPMENT,
            currentUser.accountType,
          );
          return safeGetMessage(appRecordBundle, 'cancel_record', {
            recordName,
          });
        },
        show: ({ existingRecord, currentUser }) => {
          const isAglForwarder = existingRecord?.forwarders?.some(
            (forwarder: any) => isAGLForwarder(forwarder?.forwarder),
          );
          return (
            existingRecord.status !== RecordStatus.CANCELED &&
            !isAglForwarder &&
            !currentUser.loading &&
            checkAccess(
              currentUser,
              RecordType.SHIPMENT,
              UserPermissions.UPDATE,
            )
          );
        },
        disable: ({ existingRecord }) => {
          return existingRecord.status === RecordStatus.CANCELED;
        },
        skipValidation: () => true,
        skipUpdateRecord: () => true,
        onSubmit: async ({ updatedRecord, confirmationData }) => {
          const cancelShipmentMutation = cancelOneMutation({
            recordName: RecordType.SHIPMENT,
          });
          await executeMutation({
            mutation: cancelShipmentMutation,
            variables: {
              id: updatedRecord.id,
              version: updatedRecord.version,
              cancellationReason: {
                text: confirmationData?.cancellationReason,
              },
            },
            successMessage: `Shipment canceled`,
          });
        },
        onConfirm: ({ dispatch, isValid }) =>
          new Promise<AppRecordOnConfirmPayload>((resolve, reject) => {
            if (isValid) {
              dispatch(
                setModal({
                  key: ModalKey.CANCEL_SHIPMENT,
                  props: {
                    visible: true,
                    sendConfirmationDecision: resolve,
                  },
                }),
              );
            } else {
              return resolve({ canceled: true });
            }
          }),
      },
      {
        key: 'uncancelShipment',
        text: ({ currentUser, appRecordBundle, recordNameBundle }) => {
          const recordName = getLocalizedRecordName(
            recordNameBundle,
            RecordType.SHIPMENT,
            currentUser.accountType,
          );
          return safeGetMessage(appRecordBundle, 'reopen_record', {
            recordName,
          });
        },
        show: ({ existingRecord, currentUser }) => {
          return (
            existingRecord.status === RecordStatus.CANCELED &&
            !currentUser.loading &&
            checkAccess(
              currentUser,
              RecordType.SHIPMENT,
              UserPermissions.UPDATE,
            )
          );
        },
        skipValidation: () => true,
        skipUpdateRecord: () => true,
        onSubmit: async ({
          updatedRecord,
          currentUser,
          appRecordBundle,
          recordNameBundle,
        }) => {
          const recordName = getLocalizedRecordName(
            recordNameBundle,
            RecordType.SHIPMENT,
            currentUser.accountType,
          );
          const uncancelShipmentMutation = uncancelOneMutation({
            recordName: RecordType.SHIPMENT,
          });
          await executeMutation({
            mutation: uncancelShipmentMutation,
            variables: {
              id: updatedRecord.id,
              version: updatedRecord.version,
            },
            successMessage: safeGetMessage(
              appRecordBundle,
              'record_uncanceled',
              {
                recordName,
              },
            ),
          });
        },
        onConfirm: ({
          isValid,
          currentUser,
          appRecordBundle,
          recordNameBundle,
          sharedBundle,
        }) =>
          new Promise<AppRecordOnConfirmPayload>((resolve, reject) => {
            if (isValid) {
              const recordName = getLocalizedRecordName(
                recordNameBundle,
                RecordType.SHIPMENT,
                currentUser.accountType,
              );
              Modal.confirm({
                title: safeGetMessage(appRecordBundle, 'reopen_record', {
                  recordName,
                }),
                content: safeGetMessage(appRecordBundle, 'confirm_uncancel', {
                  recordName,
                  isShipment: true,
                }),
                className: 'uncancel-shipment-modal',
                okText: safeGetMessage(sharedBundle, 'yes'),
                cancelText: safeGetMessage(sharedBundle, 'no'),
                onOk: async (e) => {
                  resolve({ canceled: false });
                },
              });
            } else {
              return resolve({ canceled: true });
            }
          }),
      },
    ],
    overrideTitle: shipmentTitleOverride,
    SecondaryHeaderContents: [ShipmentSecondaryHeader],
    transformRecordToInput: transformShipment.toForm,
    transformUpdateRecordInput: transformShipment.toUpdateSchema,
    AdditionalTitleContent: (props) => (
      <>
        <PONumbers {...props} />
        <TagForwarderButton workOrderGroup={props.record?.workOrderGroup} />
      </>
    ),
    // There's nothing to edit on the shipment page other than the PO Numbers
    // which can be done directly inside the PO Numbers input, not via the
    // default edit button
    hideDefaultEditButton: () => true,
    hideDeleteKebabMenuItem: () => true, // The operator should use cancel shipment button now
  },
];
export default shipmentRoutes;
