import { WarningStatus } from '@xbcb/work-order-types';
import {
  UsConsumptionEntry,
  UsConsumptionEntryCommercialInvoice,
} from '@xbcb/api-gateway-client';
import { shouldUpsertRelease } from 'libs/axAndSxAcceptedMessages';
import { executeMutation } from 'libs/executeMutation';
import { upsertUsConsumptionEntrySummaryCbp } from 'libs/sharedQueries';
import { FormInstance } from 'antd/lib/form';
import { message } from 'antd';

export const skipUpdateEntryNoOverrideCode = (
  form: FormInstance | undefined,
) => {
  const invoices = form?.getFieldValue(['invoices']) || [];
  return !isOverridedCensusWarnings(invoices);
};

export const submitCensusWarningsOverridesToCBP = async (
  updatedRecord: UsConsumptionEntry,
  form: FormInstance | undefined,
) => {
  const invoices = form?.getFieldValue(['invoices']) || [];
  if (!isOverridedCensusWarnings(invoices)) {
    void message.error(
      `Override Failed. Please select at lease one override code.`,
      5.0,
    );
    return;
  }
  const upsertRelease = shouldUpsertRelease(updatedRecord);
  await executeMutation({
    mutation: upsertUsConsumptionEntrySummaryCbp,
    variables: {
      usConsumptionEntryId: updatedRecord.id,
      input: {
        upsertRelease,
      },
    },
  });
};

const isOverridedCensusWarnings = (
  invoices?: UsConsumptionEntryCommercialInvoice[],
) =>
  invoices?.some((invoice) =>
    invoice?.products?.some((product) =>
      product?.lines?.some((line) =>
        line?.censusWarnings?.some(
          (censusWarning) =>
            censusWarning.warningStatus === WarningStatus.ACTIVE &&
            Boolean(censusWarning.overrideCode),
        ),
      ),
    ),
  );
