import { AppRecordSubmitButton } from 'routes';

import { message } from 'antd';
import { RecordType, RecordStatus, ObjectType } from '@xbcb/shared-types';
import { AppRecordOnConfirmPayload } from '../../types';
import { safeGetMessage, setModal } from '@xbcb/ui-utils';
import { ModalKey } from 'types';
import { checkAccess, UserPermissions } from '@xbcb/client-utils';
import { executeMutation } from 'libs/executeMutation';
import { WorkOrderStatus } from '@xbcb/work-order-types';
import { cancelOneMutation } from '@xbcb/shared-queries';
import { pascalCase } from 'change-case';
import { getLocalizedRecordName } from 'libs/localizationHelpers';

export const cancelWorkOrderButton = (
  recordType: RecordType,
): AppRecordSubmitButton => {
  const workOrderButton: AppRecordSubmitButton = {
    key: `cancel${pascalCase(recordType)}`,
    text: ({ currentUser, appRecordBundle, recordNameBundle }) => {
      const recordName = getLocalizedRecordName(
        recordNameBundle,
        recordType,
        currentUser.accountType,
      );
      return safeGetMessage(appRecordBundle, 'cancel_record', { recordName });
    },
    show: ({ existingRecord, currentUser }) => {
      return (
        ![
          RecordStatus.CANCELED,
          WorkOrderStatus.COMPLETED,
          WorkOrderStatus.REPLACED,
        ].includes(existingRecord.status) &&
        !currentUser.loading &&
        checkAccess(currentUser, recordType, UserPermissions.UPDATE)
      );
    },
    skipValidation: () => true,
    skipUpdateRecord: () => true,
    onConfirm: ({ dispatch, isValid }) =>
      new Promise<AppRecordOnConfirmPayload>((resolve, reject) => {
        if (isValid) {
          dispatch(
            setModal({
              key: ModalKey.CANCEL_WORK_ORDER,
              props: {
                visible: true,
                sendConfirmationDecision: resolve,
              },
            }),
          );
        } else {
          return resolve({ canceled: true });
        }
      }),
    onSubmit: async ({
      updatedRecord,
      currentUser,
      confirmationData,
      appRecordBundle,
      recordNameBundle,
    }) => {
      const { cancelEntryCbpReference, cancellationReason } =
        confirmationData || {};
      if (!cancellationReason) {
        void message.error(
          safeGetMessage(appRecordBundle, 'fill_out_all_fields'),
        );
        return;
      }

      const cancelWorkOrderMutation = cancelOneMutation({
        recordName: ObjectType.WORK_ORDER,
      });
      const recordName = getLocalizedRecordName(
        recordNameBundle,
        recordType,
        currentUser.accountType,
      );
      await executeMutation({
        mutation: cancelWorkOrderMutation,
        variables: {
          id: updatedRecord.id,
          version: updatedRecord.version,
          cancellationReason: {
            text: cancellationReason,
            replacementReferenceNumber: cancelEntryCbpReference,
          },
        },
        successMessage: safeGetMessage(appRecordBundle, 'record_canceled', {
          recordName,
        }),
      });
    },
  };
  return workOrderButton;
};
