import { AccountType } from '@xbcb/shared-types';
import { safeGetMessage } from '@xbcb/ui-utils';
import { WorkOrderStatus } from '@xbcb/work-order-types';
import { isEuCustomsEntry } from '@xbcb/work-order-utils/dist/isEuCustomsEntry';
import { AppRecordKebabMenuItem } from '../../types';
import { setModal } from 'actions';
import { ModalKey } from 'types';

export const appRecordCreateExceptionTaskMenuItem: AppRecordKebabMenuItem = {
  text: ({ appRecordBundle }) =>
    safeGetMessage(appRecordBundle, 'create_exception_task'),
  key: 'createExceptionTask',
  action: async ({ dispatch }) => {
    dispatch(
      setModal({
        key: ModalKey.CREATE_EXCEPTION_TASK,
        props: {
          visible: true,
        },
      }),
    );
  },
  show: ({ currentUser, existingRecord }) => {
    const { id, status } = existingRecord;
    return (
      currentUser.accountType === AccountType.OPERATOR &&
      isEuCustomsEntry(id) &&
      status === WorkOrderStatus.COMPLETED
    );
  },
};
