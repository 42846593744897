import React, { useState } from 'react';
import { Result, Tooltip, message } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  LinkOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { GraphQLResponse } from 'apollo-server-types';
import { useQuery, useMutation } from '@apollo/client';
import { set } from 'lodash';
import { client } from '@xbcb/apollo-client';
import { DocumentSignatureMethod } from '@xbcb/document-types';
import { SignFormContents } from '@xbcb/form-item-components';
import { formatRecordName, safeGet } from '@xbcb/js-utils';
import {
  getOneQuery,
  getRecordFromResponseV2,
  getPendingSubPoas,
  updateTemplateDocumentSignRequestsWithSignaturesMutation,
} from '@xbcb/shared-queries';
import { AnyObject, RecordType } from '@xbcb/shared-types';
import { ModalKey } from '@xbcb/ui-types';
import { useModal } from '@xbcb/ui-utils';
import { useCurrentUser } from 'libs/hooks';
import {
  StyledAnchor,
  StyledButton,
  StyledFlexDiv,
  StyledHeaderParagraph,
  StyledTruncatedParagraph,
  StyledSignFormContentsDiv,
  StyledSpin,
  StyledTable,
} from './styles';
import { TableRowSelection } from 'antd/lib/table/interface';

const PendingSubPoasModalContents = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<(string | number)[]>(
    [],
  );
  const { visible } = useModal(ModalKey.PENDING_SUB_POAS);
  const {
    accountType,
    forwarder = {},
    name: subPoaName,
    originalUser = {},
    title,
  } = useCurrentUser();

  const getTemplateDocumentSignRequestGqlString = getOneQuery({
    recordName: RecordType.TEMPLATE_DOCUMENT_SIGN_REQUEST,
  });
  const updateTemplateDocumentSignRequestsWithSignaturesGqlString =
    updateTemplateDocumentSignRequestsWithSignaturesMutation({});
  const [updateTemplateDocumentSignRequestsWithSignatures] = useMutation(
    updateTemplateDocumentSignRequestsWithSignaturesGqlString,
  );

  const { id: forwarderId } = forwarder;
  const subPoaTitle = title || originalUser.title;

  const getPendingSubPoasVariables = {
    forwarderId,
  };
  const {
    data: subPoaData,
    loading: queryLoading,
    refetch,
  } = useQuery(getPendingSubPoas, {
    skip: !visible,
    variables: getPendingSubPoasVariables,
    notifyOnNetworkStatusChange: true,
  });

  if (queryLoading) {
    return <StyledSpin />;
  }

  const pageLoading = queryLoading || isLoading;

  const pendingSubPoas: AnyObject[] = safeGet(
    subPoaData,
    'getPendingSubPoas',
    [],
  );
  const numberOfPendingSubPoas = pendingSubPoas.length;
  const subPoaIdMap = pendingSubPoas.reduce((map, subPoa) => {
    set(map, [subPoa.subPoaId], subPoa);
    return map;
  }, {} as AnyObject);

  const handleSign = async (
    // Base64 string
    imageData: string,
    method: DocumentSignatureMethod,
    typed: string,
  ) => {
    if (!selectedRowKeys.length) {
      void message.warn('Please select at least one sub POA to sign.', 3.0);
      return;
    }
    if (!imageData && !typed) {
      void message.error('You must provide a signature.', 3.0);
    }
    setIsLoading(true);

    const constructUpdateTemplateDsrInput = async (
      templateDsrId: string,
      additionalFields: AnyObject,
    ) => {
      const response = await client.query({
        query: getTemplateDocumentSignRequestGqlString,
        variables: {
          id: templateDsrId,
        },
      });
      const { version } = getRecordFromResponseV2({
        recordName: RecordType.TEMPLATE_DOCUMENT_SIGN_REQUEST,
        response,
        crudOrSearchType: 'get',
      });
      return {
        additionalFields,
        id: templateDsrId,
        signature: {
          data: imageData,
          method,
          typedSignature: typed,
        },
        signerName: subPoaName,
        signerTitle: subPoaTitle,
        version,
      };
    };

    const constructTemplateDsrInputPromises: Promise<AnyObject>[] = [];
    for (const templateDsrId of selectedRowKeys) {
      const subPoa = safeGet(subPoaIdMap, templateDsrId);
      if (subPoa) {
        constructTemplateDsrInputPromises.push(
          constructUpdateTemplateDsrInput(
            // Antd types the selected rows in a table as string | number
            templateDsrId as string,
            subPoa.additionalFields,
          ),
        );
      }
    }

    const inputs = await Promise.all(constructTemplateDsrInputPromises);
    await updateTemplateDocumentSignRequestsWithSignatures({
      variables: {
        input: {
          inputs,
        },
      },
      update: (cache: AnyObject, response: GraphQLResponse) => {
        const query = {
          query: getPendingSubPoas,
          variables: getPendingSubPoasVariables,
        };
        const pendingSubPoasData = cache.readQuery(query);
        // Get the updated template DSRs from the mutation
        const updatedTemplateDsrs = safeGet(
          response,
          'data.updateTemplateDocumentSignRequestsWithSignatures.records',
          [],
        ).map((templateDsr: AnyObject) => templateDsr.id);
        // Filter out any DSRs that were updated in the mutation
        const updatedTemplateDsrSet = new Set(updatedTemplateDsrs);
        const templateDsrs = pendingSubPoas.filter(
          (pendingSubPoa: AnyObject) =>
            !updatedTemplateDsrSet.has(pendingSubPoa.subPoaId),
        );
        const newData = {
          ...pendingSubPoasData,
          getPendingSubPoas: templateDsrs,
        };
        cache.writeQuery({ ...query, data: newData });
      },
    });
    void message.success(`You have signed ${inputs.length} sub POA(s).`, 3.0);
    setIsLoading(false);
  };

  const handleSelectChange = (selectedRowKeys: (string | number)[]) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const renderTruncatedText = (text: string) => (
    <Tooltip title={text}>
      <StyledTruncatedParagraph>{text}</StyledTruncatedParagraph>
    </Tooltip>
  );

  const AnchorTag = ({ href, text }: { href: string; text: string }) => (
    <StyledAnchor href={href} rel="noopener noreferrer" target="_blank">
      {text}
    </StyledAnchor>
  );

  const formattedUsIorName = formatRecordName({
    accountType,
    recordType: RecordType.US_IOR,
  });

  // There's an open Antd issue related to the value of 'align':
  // https://github.com/ant-design/ant-design/issues/22535.
  // The workaround is to define the value as 'const' which satisfies the TS compiler.
  const getTableColumns = () => [
    {
      title: formattedUsIorName,
      dataIndex: 'ior',
      key: 'ior',
      width: 200,
      render: renderTruncatedText,
    },
    {
      title: 'Officer Name',
      dataIndex: 'officerName',
      key: 'officerName',
      width: 200,
      render: renderTruncatedText,
    },
    {
      title: 'Officer Title',
      dataIndex: 'officerTitle',
      key: 'officerTitle',
      align: 'center' as const,
      width: 150,
    },
    {
      title: (
        <span>
          Master POA Link <LinkOutlined />
        </span>
      ),
      dataIndex: 'masterPoaLink',
      key: 'masterPoaLink',
      align: 'center' as const,
      width: 175,
      render: (url?: string) =>
        url && AnchorTag({ href: url, text: 'Master POA' }),
    },
    {
      title: (
        <span>
          Sub POA Link <LinkOutlined />
        </span>
      ),
      dataIndex: 'subPoaLink',
      key: 'subPoaLink',
      align: 'center' as const,
      width: 150,
      render: (url?: string) =>
        url && AnchorTag({ href: url, text: 'Sub POA' }),
    },
  ];

  const allRowKeys: string[] = [];
  const tableData = pendingSubPoas.map((subPoa: AnyObject) => {
    const { additionalFields, masterPoaLink, subPoaId, subPoaLink } = subPoa;
    const { name } = additionalFields;
    allRowKeys.push(subPoaId);
    const subPoaObj = {
      key: subPoaId,
      ior: name,
      officerName: subPoaName,
      officerTitle: subPoaTitle,
      masterPoaLink,
      subPoaLink,
    };
    return subPoaObj;
  });

  const handleRefetch = async () =>
    await refetch({
      forwarderId,
    });
  const handleSelectAll = () => {
    setSelectedRowKeys(allRowKeys);
  };
  const handleClearAll = () => {
    setSelectedRowKeys([]);
  };

  const rowSelection: TableRowSelection<object> = {
    selectedRowKeys,
    onChange: handleSelectChange as (selectedRowKeys: React.Key[]) => void,
  };

  const tooltipContent = () => (
    <span>
      The {formattedUsIorName} must have an address (including
      city/country/state) and business structure (e.g. Corporation/LLC) in order
      to show up here.
    </span>
  );

  // Pagination isn't enabled until there are more than ten items in the table
  const shouldDisplayButtons = numberOfPendingSubPoas > 10;

  return (
    <>
      <StyledFlexDiv>
        <Tooltip title={tooltipContent}>
          <StyledHeaderParagraph>
            <b>You have {numberOfPendingSubPoas} Sub POA(s) to sign.</b>
          </StyledHeaderParagraph>
        </Tooltip>
        <InfoCircleOutlined />
        <StyledButton
          disabled={pageLoading}
          onClick={handleRefetch}
          shape="circle"
          icon={
            <Tooltip placement="top" title="Synchronize data">
              <SyncOutlined spin={queryLoading} />
            </Tooltip>
          }
        ></StyledButton>
        {shouldDisplayButtons && (
          <>
            <StyledButton disabled={pageLoading} onClick={handleSelectAll}>
              <CheckOutlined /> Select All
            </StyledButton>
            <StyledButton disabled={pageLoading} onClick={handleClearAll}>
              <CloseOutlined /> Clear All
            </StyledButton>
          </>
        )}
      </StyledFlexDiv>
      {tableData.length ? (
        <>
          <StyledTable
            bordered
            dataSource={tableData}
            columns={getTableColumns()}
            rowKey="key"
            rowSelection={rowSelection}
            scroll={{ y: '20rem' }}
            pagination={{ hideOnSinglePage: true }}
          />
          <StyledSignFormContentsDiv>
            <SignFormContents isLoading={pageLoading} sign={handleSign} />
          </StyledSignFormContentsDiv>
        </>
      ) : (
        <Result
          status="success"
          title="All done! There's nothing left to sign."
        />
      )}
    </>
  );
};

export default PendingSubPoasModalContents;
