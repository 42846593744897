import { message } from 'antd';
import { AnyObject, RecordType, ClientSideException } from '@xbcb/shared-types';
import { client, fetchLatestRecord } from '@xbcb/apollo-client';
import { WorkOrderTaskStatus } from '@xbcb/work-order-types';
import { reportError, recordUIEvent } from '@xbcb/ui-utils';
import { getRecordFromResponseV2 } from '@xbcb/shared-queries';
import { assignmentRequest, assignWorkOrderTask } from './queries';
import {
  isDeletedWorkOrder,
  isNotAssignedWorkOrderTask,
  constructRedirectToPath,
} from './util';
import type { WorkOrderTask } from '@xbcb/api-gateway-client';
import { v4 as uuidV4 } from 'uuid';
const loadingMessage = 'Get new assignment request sent';
const primaryTeamTaskMessage = 'Task assigned from your primary team queue';
const supportTeamTaskMessage = 'Task assigned from your support team queue';
const errorMessage = 'No new tasks found, try again later';
interface newAssignmentResponse {
  success: boolean;
  workOrderTasks?: WorkOrderTask[];
  isSupportTeamAssignment?: boolean;
}
export const processNewAssignmentRequest = async (
  user: AnyObject,
): Promise<{ redirectTo?: string }> => {
  void message.loading(loadingMessage);
  const { success, workOrderTasks, isSupportTeamAssignment } =
    await getNewAssignmentRequest(user);

  if (!success || !workOrderTasks) {
    void message.error(errorMessage, 5.0);
    return {};
  }
  const successMessage = isSupportTeamAssignment
    ? supportTeamTaskMessage
    : primaryTeamTaskMessage;

  for (const workOrderTask of workOrderTasks) {
    try {
      const latestRecord = await fetchLatestRecord({
        id: workOrderTask.id,
        recordType: RecordType.WORK_ORDER_TASK,
        fields: `id version status definition { workOrderTaskType } workOrder { id deleted {time} }`,
      });
      if (
        isDeletedWorkOrder(latestRecord) ||
        !isNotAssignedWorkOrderTask(latestRecord)
      ) {
        void message.error(
          `Failed to assign work order task ${workOrderTask?.id}, checking next task.`,
        );
        // We want to track how often these occur even if they are expected
        recordUIEvent('outdated_assignment_task', {
          workOrderTaskId: workOrderTask.id,
          message: 'ShouldSkipMutationException',
        });
        continue;
      }
      const assignWorkOrderTaskVariables = {
        id: latestRecord.id,
        version: latestRecord.version,
        input: {
          status: WorkOrderTaskStatus.ASSIGNED,
          assignee: {
            id: user.id,
          },
        },
      };
      const { errors, data } = await client.mutate({
        mutation: assignWorkOrderTask,
        variables: {
          ...assignWorkOrderTaskVariables,
          idempotencyKey: uuidV4(),
        },
        errorPolicy: 'all',
      });
      if (errors) {
        void message.error(
          `Failed to assign work order task ${workOrderTask?.id}, checking next task.`,
        );

        /**
         * Version mismatches are currently expected behavior, hence {@link getNewAssignmentRequest} returning up to 5 WOTs.
         */
        const unexpectedErrors = errors.filter(
          (error) =>
            error.extensions.code !== ClientSideException.VERSION_MISMATCH,
        );

        if (unexpectedErrors.length > 0) {
          unexpectedErrors.forEach((error) => {
            reportError(error);
          });
        }
        if (errors.length !== unexpectedErrors.length) {
          // Had a version mismatch error
          recordUIEvent('outdated_assignment_task', {
            workOrderTaskId: workOrderTask.id,
            message: 'AssignWorkOrderTaskMutationException',
          });
        }

        continue;
      }
      const path = constructRedirectToPath(
        getRecordFromResponseV2({
          response: { data },
          crudOrSearchType: 'update',
          recordName: RecordType.WORK_ORDER_TASK,
        }),
      );
      void message.success(successMessage);
      return { redirectTo: path };
    } catch (error) {
      reportError(error);
    }
  }
  void message.error(errorMessage, 5.0);
  return {};
};

const getNewAssignmentRequest = async (
  user: AnyObject,
): Promise<newAssignmentResponse> => {
  try {
    const {
      data: {
        WorkOrderTaskAssignmentRequest: {
          workOrderTasks,
          isSupportTeamAssignment,
        },
      },
    } = await client.query({
      query: assignmentRequest,
      variables: {
        operatorUserId: user.id,
        // Adding version 0 for back compatibility.
        // Todo: Remove the param after version dependency is removed from WorkOrderTaskAssignmentRequest api input schema
        operatorUserVersion: 0,
      },
      fetchPolicy: 'network-only',
    });

    return {
      success: true,
      workOrderTasks,
      isSupportTeamAssignment,
    };
  } catch (e) {
    reportError(e);
  }
  return {
    success: false,
  };
};
