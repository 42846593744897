import React from 'react';
import { message } from 'antd';
import { RecordType } from '@xbcb/shared-types';
import { gql, useMutation } from '@apollo/client';
import { StyledButton } from './styles';
import { camelCase } from 'lodash';
import { titleCase } from '../../libs/format';
import { safeGetMessage } from '@xbcb/ui-utils';
import { useBundle } from '@amzn/react-arb-tools';

export const passwordResettableUserTypes = [
  RecordType.FORWARDER_USER,
  RecordType.SHIPPER_USER,
];

export type PasswordResettableUserType =
  (typeof passwordResettableUserTypes)[number];

interface ResetPasswordButtonProp {
  userId: string;
  userType: PasswordResettableUserType;
}

const ResetPasswordButton: React.FC<ResetPasswordButtonProp> = ({
  userId,
  userType,
}) => {
  const [partyBundle] = useBundle('components.Party');
  const mutationName = `reset${titleCase(camelCase(userType))}Password`;
  const resetPasswordMutation = gql`
    mutation resetUserPassword($id: ID!) {
        ${mutationName} (id: $id) {
        email
    }
    }
  `;

  const [resetPassword, { loading: isPasswordBeingReset }] = useMutation(
    resetPasswordMutation,
    {
      onCompleted: (record) => {
        void message.success(
          safeGetMessage(partyBundle, 'temporary_password_sent', {
            email: record[mutationName]?.email,
          }),
        );
      },
      onError: async (error) => {
        void message.error(
          safeGetMessage(partyBundle, 'reset_password_error', {
            error: error.message,
          }),
        );
      },
    },
  );

  const onClick = async () => {
    await resetPassword({
      variables: { id: userId },
    });
  };

  return (
    <StyledButton
      disabled={isPasswordBeingReset}
      onClick={onClick}
      shape="round"
      type="primary"
    >
      {safeGetMessage(partyBundle, 'reset_password')}
    </StyledButton>
  );
};

export default ResetPasswordButton;
