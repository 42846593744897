import { uiStageToBackendStage } from '@xbcb/ui-types';
import { getEnv } from '@xbcb/ui-utils';
import {
  DELOREAN_FEATURE_FLAG_SERVICE,
  DeloreanFeatures,
} from '@xbcb/feature-flags';

export const computeIsDeloreanBasicPhaseEnabled = () => {
  const currentDate = new Date();
  return DELOREAN_FEATURE_FLAG_SERVICE.isEnabled(DeloreanFeatures.BASIC, {
    stage: uiStageToBackendStage[getEnv().stage],
    time: currentDate.toISOString().slice(0, 10),
  });
};

export const isDeloreanBasicPhaseEnabled = computeIsDeloreanBasicPhaseEnabled();
