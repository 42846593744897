import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Form, Button, message } from 'antd';
import { gql, useMutation } from '@apollo/client';
import type {
  CreateWorkOrderTaskInput,
  WorkOrder,
  WorkOrderTask,
  WorkOrderTaskType as ApigWorkOrderTaskType,
} from '@xbcb/api-gateway-client';
import { getRecordType } from '@xbcb/core';
import { FormItem, Select } from '@xbcb/form-item-components';
import { codeToText } from '@xbcb/js-utils';
import { RecordType } from '@xbcb/shared-types';
import { createDataCyValue, CssSize, DataCyPrefix } from '@xbcb/ui-types';
import { useModal } from '@xbcb/ui-utils';
import {
  WorkOrderStatus,
  WorkOrderTaskStatus,
  WorkOrderTaskType,
} from '@xbcb/work-order-types';
import { getBusinessHourDeadline } from '@xbcb/work-order-utils/dist/getBusinessHourDeadline';
import { fetchLatestRecordAndExecuteMutation } from 'libs/fetchLatestRecordAndExecuteMutation';
import { getRecordPath } from 'libs/getRecordPath';
import { useCurrentUser } from 'libs/hooks';
import { updateWorkOrderStatus } from 'libs/sharedQueries';
import { ModalKey } from 'types';

export interface CreateExceptionTaskModalProps {
  record: WorkOrder;
}

const createExceptionTaskMutation = gql`
  mutation createWorkOrderTask(
    $idempotencyKey: String!
    $input: CreateWorkOrderTaskInput!
  ) {
    createWorkOrderTask(idempotencyKey: $idempotencyKey, input: $input) {
      record {
        id
      }
    }
  }
`;

const exceptionTaskTypes = [
  WorkOrderTaskType.EU_CUSTOMS_ENTRY_BROKER_ERROR_EXCEPTION,
  WorkOrderTaskType.EU_CUSTOMS_ENTRY_CUSTOMS_REQUESTED_AMENDMENTS,
  WorkOrderTaskType.EU_CUSTOMS_ENTRY_CUSTOMS_SYSTEM_ERROR_EXCEPTION,
  WorkOrderTaskType.EU_CUSTOMS_ENTRY_SELLER_REQUESTED_AMENDMENTS,
];

const CreateExceptionTaskModal: React.FC<CreateExceptionTaskModalProps> =
  React.memo(({ record }) => {
    const { id: workOrderId, stateMachine, tasks } = record;
    const recordType = getRecordType(workOrderId) as RecordType;
    const { visible, closeModal } = useModal(ModalKey.CREATE_EXCEPTION_TASK);
    const [form] = Form.useForm();
    const history = useHistory();
    const user = useCurrentUser();
    const operatorId = user?.operator?.id;
    const [isLoading, setIsLoading] = useState(false);
    const [createExceptionTask] = useMutation(createExceptionTaskMutation);

    const handleClose = () => {
      closeModal();
    };

    const handleSubmit = async (e: any) => {
      e.preventDefault();
      try {
        await form.validateFields();
      } catch (e) {
        e.errorFields?.forEach(({ errors }: { errors: string[] }) => {
          errors?.forEach((error: string) => void message.error(error));
        });
        return;
      }
      setIsLoading(true);

      // Stop creating if there is an open exception task
      const existingExceptionTask = tasks?.find(
        (task: WorkOrderTask) =>
          (exceptionTaskTypes as unknown as ApigWorkOrderTaskType[]).includes(
            task.definition.workOrderTaskType,
          ) &&
          [
            WorkOrderTaskStatus.NOT_ASSIGNED,
            WorkOrderTaskStatus.ASSIGNED,
            WorkOrderTaskStatus.IN_PROGRESS,
            WorkOrderTaskStatus.BLOCKED,
          ].includes(task.status as WorkOrderTaskStatus),
      );
      if (existingExceptionTask) {
        void message.error(
          `Another exception task is still open. Please close it before creating a new exception task.`,
        );
        setIsLoading(false);
        return;
      }

      // Stop creating if task definition not found in the state machine
      const { exceptionTaskType } = form.getFieldsValue() || {};
      const taskDefinition = stateMachine?.states?.find((state) => {
        return (
          state.taskDefinition.workOrderTaskType ===
          (exceptionTaskType as ApigWorkOrderTaskType)
        );
      })?.taskDefinition;
      if (!taskDefinition) {
        void message.error(
          `Task definition for this exception task ${codeToText(
            exceptionTaskType,
          )} not found`,
        );
        setIsLoading(false);
        return;
      }

      const input: CreateWorkOrderTaskInput = {
        workOrder: { id: workOrderId },
        operator: { id: operatorId },
        definition: {
          id: taskDefinition.id,
          version: taskDefinition.version,
        },
        deadline: getBusinessHourDeadline(360), // 30 business days
      };

      try {
        await createExceptionTask({
          variables: {
            idempotencyKey: `${workOrderId}_${exceptionTaskType}_${tasks?.length}`,
            input,
          },
        });
        void message.success(`Exception task created successfully.`);

        // Reopen work order
        await fetchLatestRecordAndExecuteMutation({
          id: workOrderId,
          fields: 'status tasks { id definition { workOrderTaskType }}',
          recordType,
          // Currently only EU Customs Entry WOs support manual exception task creation
          mutation: updateWorkOrderStatus,
          successMessage: `Work Order is now reopened.`,
          constructVariablesWithIdVersion: (id, version) => ({
            id: id,
            version: version,
            input: {
              status: WorkOrderStatus.IN_PROGRESS,
            },
          }),
        });

        // Redirect user to the created task page
        history.push(
          `${getRecordPath({
            recordType,
            id: workOrderId,
          })}?task=${exceptionTaskType}`,
        );

        // In case redirect fails
        setIsLoading(false);
        closeModal();
      } catch (e) {
        void message.error(e.message);
        setIsLoading(false);
        return;
      }
    };

    const exceptionTaskTypeOptions = exceptionTaskTypes.map((taskType) => ({
      key: taskType,
      value: taskType,
      label: codeToText(taskType),
      'data-cy': createDataCyValue(
        DataCyPrefix.EXCEPTION_TASK_TYPE_OPTION,
        taskType.toString(),
      ),
    }));

    return (
      <Modal
        key={workOrderId}
        open={visible}
        width={600}
        title="Create Exception Task"
        maskClosable={!isLoading}
        closable={false}
        onCancel={handleClose}
        footer={[
          <Button
            onClick={handleClose}
            size="large"
            key="cancelCreateExceptionTask"
          >
            Cancel
          </Button>,
          <Button
            onClick={handleSubmit}
            type="primary"
            size="large"
            loading={isLoading}
            htmlType="submit"
            key="createExceptionTask"
          >
            Create
          </Button>,
        ]}
      >
        <Form form={form}>
          <FormItem
            $itemSize={CssSize.MEDIUM}
            $inline
            label="Exception Task Type"
            name={['exceptionTaskType']}
            rules={[
              {
                required: true,
                message: 'Please select an exception task type',
              },
            ]}
          >
            <Select
              placeholder="Select a task"
              options={exceptionTaskTypeOptions}
              data-cy={createDataCyValue(DataCyPrefix.EXCEPTION_TASK_TYPE)}
              data-testid="exception-task-type-select"
            />
          </FormItem>
        </Form>
      </Modal>
    );
  });

export default CreateExceptionTaskModal;
