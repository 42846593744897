import { DataSourceItemObject } from 'antd/lib/auto-complete';
import { Rule } from 'antd/lib/form';
import { Form, Select } from 'antd';
import moment from 'moment';
import { NamePath } from 'rc-field-form/lib/interface';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { FormItem } from '@xbcb/form-item-components';
import { formatHTS, getHtsDataFromHierarchy } from '@xbcb/hts-client-utils';
import { getEnv } from '@xbcb/ui-env';
import { UiStage } from '@xbcb/ui-types';
import HTSMask from 'libs/htsMask';
import { getArrivalDateMoment, getHtsSuggestions } from 'libs/htsHelpers';
import { validateEffectiveHTS } from 'libs/validateEffectiveHTS';
import { useArrivalDate, usePrevious } from 'libs/hooks';
import { RootState } from 'reducers';
import { StyledAutoComplete } from './styles';

export interface HTSNumberProps {
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
  field: NamePath;
  fullNamePath: NamePath;
  hideLabel?: boolean;
  validate?: boolean;
  ISFvalidate?: boolean;
  label?: string;
  onChange?: any;
  inBondDate?: moment.Moment;
}

const HTSNumber: React.FC<HTSNumberProps> = ({
  disabled,
  readOnly,
  required = false,
  field,
  fullNamePath,
  hideLabel,
  validate = false,
  ISFvalidate,
  label = 'HTS Number',
  onChange,
  inBondDate,
}) => {
  // Stage check for https://app.asana.com/0/1206682900582391/1206720860904156/f
  // Remove after ops approval/gamma testing
  const { stage } = getEnv();
  const isProd = stage === UiStage.PROD;
  const form = Form.useFormInstance();
  const arrivalDate = useArrivalDate();
  const theme = useTheme();
  const htsHierarchy = useSelector(
    (state: RootState) => state.hts.usHtsHierarchy.htsHierarchy,
  );
  const [options, setOptions] = useState<DataSourceItemObject[]>([]);
  const htsMask = useRef(new HTSMask());

  // State variables to prevent resetting form state if the HTS number is unchanged onSelect
  const htsNumber = Form.useWatch(fullNamePath, form);
  const previousHtsNumber = usePrevious(htsNumber);

  const htsEffectiveDate = inBondDate || moment(arrivalDate);
  /* istanbul ignore next (AntD validators don't fire in Jest tests) */
  const validateHTS = async (_: Rule, value: string) => {
    const arrivalDate = getArrivalDateMoment(form) || moment();
    if (!value && !required) return;
    validateEffectiveHTS(value, arrivalDate, htsHierarchy);
  };
  // only to the 6 digit level
  /* istanbul ignore next (AntD validators don't fire in Jest tests) */

  const validateISF = async (_: Rule, value: string) => {
    if (!value && !required) return;
    const raw = formatHTS(value);
    const htsObj = getHtsDataFromHierarchy(raw, htsHierarchy);
    if (!htsObj) {
      throw new Error('Invalid HTS Code');
    } else {
      return;
    }
  };

  const getOptions = (value: string) => {
    const raw = formatHTS(value);
    const suggestions = getHtsSuggestions(
      raw,
      htsHierarchy,
      htsEffectiveDate || moment(),
    );
    const opts = suggestions.sort().map((x) => {
      return { value: x, text: x };
    });

    setOptions(raw ? opts : []);
  };

  const handleChange = (value: string) => {
    let hts = htsMask.current.handleChange(value);
    if (!hts) {
      hts = '';
    }

    return hts;
  };

  const handleSelect = (value: string | undefined) => {
    if (onChange && formatHTS(value) !== formatHTS(previousHtsNumber)) {
      // onChange resets various form state across multiple tabs;
      // don't want to clear existing values if the HTS number
      // is the same
      onChange(value);
    }
    if (value) {
      getOptions(value);
    }
  };

  /**
   * Original change handler; delete after stage check is removed.
   */

  const handleChangeProd = (value: string) => {
    let hts = htsMask.current.handleChange(value);
    if (!hts) {
      hts = '';
    }

    const raw = formatHTS(hts);
    const suggestions = getHtsSuggestions(
      raw,
      htsHierarchy,
      htsEffectiveDate || moment(),
    );
    const opts = suggestions.sort().map((x) => {
      return { value: x, text: x };
    });
    setOptions(raw ? opts : []);

    return hts;
  };

  /**
   * Stage check logic for https://app.asana.com/0/1206682900582391/1206720860904156/f
   */

  const SelectComponent = isProd ? (
    <StyledAutoComplete
      options={options}
      dropdownMatchSelectWidth={false}
      disabled={disabled}
      onSelect={onChange}
      placeholder="NNNN.NN.NNNN"
      notFoundContent="None found"
      aria-label="HTS Number"
    />
  ) : (
    <Select
      options={options}
      showSearch
      allowClear
      dropdownMatchSelectWidth={false}
      disabled={disabled}
      onSelect={handleSelect}
      onSearch={getOptions}
      placeholder="NNNN.NN.NNNN"
      notFoundContent="None found"
      aria-label="HTS Number"
      filterOption={false}
    />
  );

  return (
    <FormItem
      // We want to rerun the validation whenever the arrival date changes
      // to capture the current effective HTS date
      dependencies={[
        ['arrival', 'importDate'],
        ['arrival', 'inBond', 'estimatedEntryDate'],
      ]}
      getValueFromEvent={isProd ? handleChangeProd : handleChange}
      label={hideLabel ? undefined : label}
      name={field}
      rules={[
        {
          validator: validate
            ? validateHTS
            : ISFvalidate
            ? validateISF
            : undefined,
          required,
          message: ' ',
        },
      ]}
      $itemSize={theme.size.short}
      $readOnly={readOnly}
    >
      {SelectComponent}
    </FormItem>
  );
};
export default HTSNumber;
