import React from 'react';
import { FormOutlined } from '@ant-design/icons';
import { TableProps } from 'antd';
import {
  ForwarderUserUsIorPermissionName,
  OperatorUserWorkOrderTaskPermissionName,
} from '@xbcb/client-types';
import { checkAccess, UserPermissions } from '@xbcb/client-utils';
import { safeGet } from '@xbcb/js-utils';
import {
  AnyObject,
  ObjectType,
  KeywordSearchOperator,
  TextSearchOperator,
  SortOrder,
  AccountType,
  RecordType,
} from '@xbcb/shared-types';
import { EsDataType, RecordTableFilters } from '@xbcb/table-utils';
import { MessageBundle, ModalKey } from '@xbcb/ui-types';
import { WorkOrderTaskStatus } from '@xbcb/work-order-types';
import CreateShipmentModal from 'components/CreateShipmentModal';
import CreateUsPostSummaryCorrectionModal from 'components/CreateUsPostSummaryCorrectionModal';
import CreateReportReconciliationRequestModal from 'components/CreateReportReconciliationRequestModal';
import CreateUsIorContinuousBondRequestModal from 'components/CreateUsIorContinuousBondRequestModal';
import PendingSubPoasModal from 'components/PendingSubPoasModal';
import { generateCreateModalKey } from 'libs/generateCreateModalKey';
import CreateTemplateButton from 'components/CreateTemplateButton';
import { TableQueryResult } from 'libs/sharedQueries/queries/useTableQuery';
import { SortOption } from 'types';
import CreateBulkMilestoneUploadRequestModal from 'components/CreateBulkMilestoneUploadRequestModal';
import BulkUploadTemplateDownloadButton from 'components/BulkUploadTemplateDownloadButton';
import CreateBulkChargesUploadRequestModal from 'components/CreateBulkChargesUploadRequestModal';
import CreateBulkComplianceUploadRequestModal from 'components/CreateBulkComplianceUploadRequestModal';
import CommonBulkUploadTemplateButton from 'components/CommonBulkUploadTemplateButton';
import CreateButtonModal from 'components/CreateButtonModal';
import { safeGetMessage } from '@xbcb/ui-utils';

export type CreateDefaultFiltersProps = {
  user: any;
};

export type CreateDefaultFilters = (
  props: CreateDefaultFiltersProps,
) => RecordTableFilters;

export type ShouldShowArgs = {
  user: AnyObject;
};
export type ExtraHeaderContent = {
  Component: React.ReactNode;
  shouldShow?: (args: ShouldShowArgs) => boolean;
};
export type CheckboxProps = {
  disabled?: boolean;
};

export type InlineTableProps = {
  isDownloadButtonDisabled?: boolean;
  objectType: ObjectType;
  tableQueryResult: TableQueryResult;
  readOnly?: boolean;
  // when provided, these filters will always be included in the search table query.
  // e.g. tasks in the assignment table should only show for the user that's currently logged in
  createDefaultFilters?: CreateDefaultFilters;
  // if provided, it will override any existing sort option in the table.
  // e.g. the assignment table should always sort by the deadline in descending order
  staticSortOption?: SortOption;
  disableRowClick?: boolean;
  disableSorting?: boolean;
  path?: string; // optional custom path
  additionalColumns?: AnyObject;
  showCustomizeTableDrawer: boolean;
  setShowCustomizeTableDrawer: (value: boolean) => void;
};

export type AppTableProps = {
  // Can be used to have a custom title above the table. By default `formatRecordName`
  // will be used with `pluralize: true` so it only needs to be provided if a special
  // use case is needed. For example work order task => Assignment Queue
  getTitle?: ({
    accountType,
    bundle,
  }: {
    accountType: AccountType;
    bundle: MessageBundle;
  }) => React.ReactNode;
  objectType: ObjectType;
  showCreateButton?: (user: AnyObject) => boolean;
  showExportButton?: boolean;
  showExportDropdown?: boolean;
  showUploadButton?: boolean;
  extraHeaderContent?: ExtraHeaderContent[];
  readOnly?: boolean;
  // when provided, these filters will always be included in the search table query.
  // e.g. tasks in the assignment table should only show for the user that's currently logged in
  createDefaultFilters?: CreateDefaultFilters;
  // if provided, it will override any existing sort option in the table.
  // e.g. the assignment table should always sort by the deadline in descending order
  staticSortOption?: SortOption;
  disableRowClick?: boolean;
  disableSorting?: boolean;
  path?: string; // optional custom path
  rowSelection?: TableProps<AnyObject>['rowSelection'];
  // prefix name for the zip file for bulk download
  zipPrefixName?: string;
};

export const appTableRoutes: AppTableProps[] = [
  {
    objectType: ObjectType.DE_CUSTOMS_ENTRY,
  },
  {
    objectType: ObjectType.GB_CUSTOMS_ENTRY,
  },
  {
    objectType: ObjectType.NL_CUSTOMS_ENTRY,
  },
  {
    objectType: ObjectType.FR_CUSTOMS_ENTRY,
  },
  {
    objectType: ObjectType.CUSTOMS_DECLARATION,
  },
  {
    objectType: ObjectType.US_CONSUMPTION_ENTRY,
  },
  {
    objectType: ObjectType.DELIVERY_ORDER,
  },
  {
    objectType: ObjectType.US_POST_SUMMARY_CORRECTION,
    extraHeaderContent: [
      {
        Component: (
          <CreateButtonModal
            modal={CreateUsPostSummaryCorrectionModal}
            modalKey={generateCreateModalKey(
              ObjectType.US_POST_SUMMARY_CORRECTION,
            )}
          />
        ),
        shouldShow: ({ user }) => {
          const { accountType, loading } = user;
          // Only Operator Users should be able to create PSCs
          return !loading && accountType === AccountType.OPERATOR;
        },
      },
    ],
  },
  {
    objectType: ObjectType.US_ISF,
  },
  {
    objectType: ObjectType.US_IOR_CONTINUOUS_BOND_REQUEST,
    extraHeaderContent: [
      {
        Component: (
          <CreateButtonModal
            modal={CreateUsIorContinuousBondRequestModal}
            modalKey={generateCreateModalKey(
              ObjectType.US_IOR_CONTINUOUS_BOND_REQUEST,
            )}
          />
        ),
      },
    ],
  },
  {
    objectType: ObjectType.US_IOR_ACTIVATION,
  },
  {
    objectType: ObjectType.DE_IOR_ACTIVATION,
  },
  {
    objectType: ObjectType.GB_IOR_ACTIVATION,
  },
  {
    objectType: ObjectType.NL_IOR_ACTIVATION,
  },
  {
    objectType: ObjectType.FR_IOR_ACTIVATION,
  },
  {
    objectType: ObjectType.IMPORTER_ENTITY_ACTIVATION,
  },
  {
    objectType: ObjectType.US_IN_BOND,
  },
  {
    objectType: ObjectType.US_TYPE86_ENTRY,
  },
  {
    getTitle: ({ bundle }) => safeGetMessage(bundle, 'assignment_queue'),
    objectType: ObjectType.WORK_ORDER_TASK,
    createDefaultFilters: ({ user }: AnyObject): AnyObject => {
      // TODO develop a permission based auto filtering and view model
      const { name, operatorUserPermissions } = user;
      const filters: AnyObject = {
        // only find tasks that are not canceled/completed
        status: {
          operator: KeywordSearchOperator.NOT_ONE_OF,
          type: EsDataType.KEYWORD,
          value: [
            WorkOrderTaskStatus.CANCELED,
            WorkOrderTaskStatus.CLOSED,
            WorkOrderTaskStatus.COMPLETED,
          ],
        },
      };
      const hasOperatorUserWorkOrderTaskAdminPermission =
        operatorUserPermissions?.workOrderTask?.find(
          (permission: any) =>
            permission.name === OperatorUserWorkOrderTaskPermissionName.ADMIN,
        );
      // if current operator user is not an ADMIN, only find tasks that are assigned to the current user
      if (!hasOperatorUserWorkOrderTaskAdminPermission)
        filters.assigneeName = {
          operator: TextSearchOperator.EQUALS,
          type: EsDataType.TEXT,
          value: name,
        };
      return filters;
    },
    staticSortOption: {
      field: 'deadline',
      order: SortOrder.ASC,
    },
    disableSorting: true,
    path: '/assignments',
  },
  {
    objectType: ObjectType.GB_IOR,
  },
  {
    objectType: ObjectType.DE_IOR,
  },
  {
    objectType: ObjectType.NL_IOR,
  },
  {
    objectType: ObjectType.FR_IOR,
  },
  {
    showCreateButton: () => true,
    objectType: ObjectType.US_IOR,
    extraHeaderContent: [
      {
        Component: (
          <CreateButtonModal
            modal={PendingSubPoasModal}
            modalKey={ModalKey.PENDING_SUB_POAS}
            modalContent={<FormOutlined />}
            shape={'circle'}
          />
        ),
        shouldShow: ({ user }: AnyObject): boolean => {
          const { accountType, forwarderUserPermissions = {}, loading } = user;
          const usIorPermissions: { name: string }[] = safeGet(
            forwarderUserPermissions,
            'usIor',
            [],
          );
          const hasSubPoaAuthorizationPermission = usIorPermissions.some(
            ({ name }) =>
              name === ForwarderUserUsIorPermissionName.SUB_POA_AUTHORITY,
          );
          return (
            !loading &&
            accountType === AccountType.FORWARDER &&
            hasSubPoaAuthorizationPermission
          );
        },
      },
    ],
  },
  {
    objectType: ObjectType.IMPORTER,
  },
  {
    showCreateButton: () => true,
    objectType: ObjectType.FACILITY,
  },
  {
    getTitle: ({ bundle }) => safeGetMessage(bundle, 'escalation_messages'),
    objectType: ObjectType.WORK_ORDER_TASK_ESCALATION_MESSAGE,
  },
  {
    showCreateButton: () => true,
    showUploadButton: true,
    objectType: ObjectType.PRODUCT,
  },
  {
    objectType: ObjectType.SHIPMENT,
    extraHeaderContent: [
      {
        Component: (
          <CreateButtonModal
            modal={CreateShipmentModal as React.FC}
            modalKey={generateCreateModalKey(ObjectType.SHIPMENT)}
          />
        ),
        shouldShow: ({ user }) =>
          !user.loading &&
          checkAccess(user, RecordType.SHIPMENT, UserPermissions.CREATE),
      },
    ],
  },
  {
    objectType: ObjectType.INVOICE,
    showExportButton: false,
    showExportDropdown: true,
    rowSelection: {
      preserveSelectedRowKeys: true,
      getCheckboxProps: (record: any) => ({
        // TODO: enable line below once downloadLink is added to the invoice query
        // disabled: !record.content.downloadLink,
      }),
    },
    zipPrefixName: 'invoices',
  },
  {
    getTitle: ({ bundle }) => safeGetMessage(bundle, 'inbox'),
    objectType: ObjectType.EXCHANGE_MESSAGE,
    createDefaultFilters: (): AnyObject => {
      // only show emails
      const filters: AnyObject = {
        from: {
          operator: KeywordSearchOperator.EXISTS,
        },
      };
      return filters;
    },
  },
  {
    objectType: ObjectType.REPORT_RECONCILIATION_REQUEST,
    extraHeaderContent: [
      {
        Component: (
          <CreateButtonModal
            modal={CreateReportReconciliationRequestModal as React.FC}
            modalKey={generateCreateModalKey(
              ObjectType.REPORT_RECONCILIATION_REQUEST,
            )}
          />
        ),
      },
    ],
  },
  {
    objectType: ObjectType.BULK_MILESTONE_UPLOAD_REQUEST,
    extraHeaderContent: [
      {
        Component: <CommonBulkUploadTemplateButton templateType="Milestone" />,
      },
      {
        Component: (
          <CreateButtonModal
            modal={CreateBulkMilestoneUploadRequestModal}
            modalKey={ModalKey.CREATE_BULK_MILESTONE_UPLOAD_REQUEST}
          />
        ),
        shouldShow: ({ user }) =>
          !user.loading &&
          checkAccess(
            user,
            RecordType.BULK_MILESTONE_UPLOAD_REQUEST,
            UserPermissions.CREATE,
          ),
      },
    ],
  },
  {
    objectType: ObjectType.BULK_CHARGES_UPLOAD_REQUEST,
    extraHeaderContent: [
      {
        Component: <CommonBulkUploadTemplateButton templateType="Charges" />,
      },
      {
        Component: (
          <CreateButtonModal
            modal={CreateBulkChargesUploadRequestModal}
            modalKey={ModalKey.CREATE_BULK_CHARGES_UPLOAD_REQUEST}
          />
        ),
        shouldShow: ({ user }) =>
          !user.loading &&
          checkAccess(
            user,
            RecordType.BULK_CHARGES_UPLOAD_REQUEST,
            UserPermissions.CREATE,
          ),
      },
    ],
  },
  {
    objectType: ObjectType.BULK_COMPLIANCE_UPLOAD_REQUEST,
    extraHeaderContent: [
      {
        Component: (
          <BulkUploadTemplateDownloadButton
            templateTag={{
              key: 'bulkUploadTemplate',
              value: 'Compliance',
            }}
          />
        ),
      },
      {
        Component: (
          <CreateButtonModal
            modal={CreateBulkComplianceUploadRequestModal}
            modalKey={ModalKey.CREATE_BULK_COMPLIANCE_UPLOAD_REQUEST}
          />
        ),
        shouldShow: ({ user }) =>
          !user.loading &&
          checkAccess(
            user,
            RecordType.BULK_COMPLIANCE_UPLOAD_REQUEST,
            UserPermissions.CREATE,
          ),
      },
    ],
  },
  {
    objectType: ObjectType.DOCUMENT_TEMPLATE,
    extraHeaderContent: [
      {
        Component: <CreateTemplateButton />,
        shouldShow: ({ user }) =>
          !user.loading && user.accountType === AccountType.FORWARDER,
      },
    ],
  },
  {
    objectType: ObjectType.SUBJECT_MATTER_EXPERT_TEAM,
    showCreateButton: () => true,
  },
  {
    showCreateButton: () => true,
    objectType: ObjectType.SHIPPER,
  },
  {
    objectType: ObjectType.ASSIGNMENT_TEAM,
    showCreateButton: () => true,
  },
  {
    objectType: ObjectType.BUSINESS_SUPPORT_TEAM,
    showCreateButton: () => true,
  },
  {
    getTitle: () => 'Commodity Groups',
    objectType: ObjectType.COMMODITY_GROUP,
    showCreateButton: () => true,
  },
  {
    showUploadButton: true,
    showCreateButton: () => true,
    objectType: ObjectType.SUPPLIER,
  },
  {
    showCreateButton: () => true,
    objectType: ObjectType.TRUCKER,
  },
  {
    showCreateButton: () => true,
    objectType: ObjectType.CUSTOMS_AGENT,
  },
  {
    showCreateButton: () => true,
    objectType: ObjectType.FORWARDER,
  },
  {
    showCreateButton: () => true,
    objectType: ObjectType.US_CONSIGNEE,
  },
  {
    showCreateButton: () => true,
    objectType: ObjectType.OPERATOR_USER,
  },
  {
    showCreateButton: (user) => !user?.forwarder?.globalLogisticsProfileId,
    objectType: ObjectType.FORWARDER_USER,
  },
  {
    showCreateButton: (user) => !user?.shipper?.globalLogisticsProfileId,
    objectType: ObjectType.SHIPPER_USER,
  },
  {
    objectType: ObjectType.PROCESSING_ERROR,
  },
];
