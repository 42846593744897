import React from 'react';
import { ErrorBoundary, Loading } from '@xbcb/feedback-components';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { AccountType, ObjectType } from '@xbcb/shared-types';
import { useSelector } from 'react-redux';
import { locationToObjectType } from '@xbcb/ui-utils';
import { RootState } from '../../reducers';
import { useCurrentUser, CurrentUser } from 'libs/hooks';
import { getRedirectUrlForBroker } from 'libs/getRedirectUrlForBroker';
import { checkAccess } from '@xbcb/client-utils';
import { AuthenticatedRoutesProvidedProps } from 'routes/types';
import { isGlitchWatchUiAdminViewEnabled } from 'libs/featureFlags';
import { isMonsEnv } from '@xbcb/ui-env';

const isMons = isMonsEnv();

const AuthenticationBoundary: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const location = useLocation();
  const { pathname } = location;
  const {
    userToken,
    customType = '',
    userEmail,
  } = useSelector(({ userLogin }: RootState) => {
    return {
      userEmail: userLogin.email,
      userToken: userLogin.userToken,
      customType: userLogin['custom:type'],
    };
  });
  const objectType = locationToObjectType(pathname);

  const activated = !['onboarding', 'pending'].includes(customType);
  const user = useCurrentUser();
  if (user.loading) {
    return <Loading isLoading />;
  } else {
    const loggedIn = isMons || !!userToken;
    // Operator Users should not be allowed to modify themselves
    if (loggedIn && !user.root && pathname.endsWith(`users/${user.id}`)) {
      return <Redirect to={`/profile`} />;
    }

    // Forwarder users should not be allowed to modify the forwarder
    // and shipper users should not be allowed to modify the shipper
    if (
      loggedIn &&
      (pathname.endsWith(`forwarders/${user.account?.id}`) ||
        pathname.endsWith(`shippers/${user.account?.id}`))
    )
      return <Redirect to={`/company`} />;

    let hasReadPermission = false;
    if (objectType) {
      hasReadPermission =
        objectType === ObjectType.SHIPMENT || // TODO: remove this default shipments read permission once we have some kind of homepage
        checkAccess(user, objectType as ObjectType, 'EXPLICIT_READ');
    }

    const childrenWithProps = React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        const providedProps: AuthenticatedRoutesProvidedProps = {
          customType,
          userEmail,
          activated, // We need this field to decide routes, can't delete
        };
        return React.cloneElement(child, providedProps);
      } else {
        return child;
      }
    });
    // company page is for customers (forwarderUser/shipperUser) to configure their company (forwarder/shipper) settings.
    // all the customer should always have read access to their company page, but technically it should also go through checkAccess. Currently we don't have permission setting for its own company, but should implement once it's done.(https://app.asana.com/0/1182673616136511/1199898906089028/f)
    if (pathname === '/company') {
      hasReadPermission = user.accountType !== AccountType.OPERATOR;
    }

    // CUSTOMS_AGENT should only be shown for operator users
    if (
      pathname.startsWith('/customs-agents') &&
      user.accountType !== AccountType.OPERATOR
    ) {
      hasReadPermission = false;
    }

    if (
      pathname.startsWith('/processing-errors') &&
      isGlitchWatchUiAdminViewEnabled(user as CurrentUser)
    ) {
      hasReadPermission = true;
    }

    const hasPermissionToView =
      [
        '/',
        '/profile',
        '/search',
        '/help',
        '/queries',
        '/classifications',
        '/migrate',
        '/action',
      ].includes(pathname) ||
      (activated && hasReadPermission);

    return (
      <ErrorBoundary>
        <Route>
          {loggedIn ? (
            hasPermissionToView || !activated ? (
              <ErrorBoundary>{childrenWithProps}</ErrorBoundary>
            ) : (
              <Redirect to={getRedirectUrlForBroker(user.customsBroker?.id)} />
            )
          ) : (
            <Redirect to={`/login?redirect=${pathname}${location.search}`} />
          )}
        </Route>
      </ErrorBoundary>
    );
  }
};

export default AuthenticationBoundary;
