import React from 'react';
import { useLocation } from 'react-router-dom';
import { Tooltip, Form, message } from 'antd';
import { isEqual } from 'lodash';
import { useMutation } from '@apollo/client';
import type { Shipment, Ior } from '@xbcb/api-gateway-client';
import { Select } from '@xbcb/form-item-components';
import { cloneJson } from '@xbcb/js-utils';
import { updateOneMutation } from '@xbcb/shared-queries';
import { RecordType, ObjectType } from '@xbcb/shared-types';
import { locationToObjectType } from '@xbcb/ui-utils';
import { getPoNumbersFromWorkOrderGroup } from 'libs/poNumbers';
import { AppRecordProvidedProps } from 'routes';
import {
  StyledForm,
  StyledFormItem,
  StyledTitleDiv,
  StyledTitleSubTextSpan,
} from './styles';

const getValueFromEvent = (val: string | string[]) => {
  if (Array.isArray(val)) {
    return val.map((v) => v.toUpperCase());
  } else {
    return val;
  }
};

const displayName = 'Ref Number(s)';

const PoNumbers: React.FC<AppRecordProvidedProps> = ({ record, disabled }) => {
  const [form] = Form.useForm();
  const { pathname } = useLocation();
  const [updateShipment, { loading: isUpdateShipmentLoading }] = useMutation(
    updateOneMutation({
      recordName: RecordType.SHIPMENT,
      fields: `id version legs { poNumbers } workOrderGroup { tags { key value } }`,
    }),
    {
      onError: async (error) => {
        void message.error(
          `Sorry, the following error occurred: ${error}. Please try again later.`,
          3.0,
        );
      },
    },
  );
  const objectType = locationToObjectType(pathname);
  const isShipmentPage = objectType === ObjectType.SHIPMENT;
  const { id, version, legs, workOrderGroup } = cloneJson<Shipment>(
    record || {},
  );
  const poNumbersNamePath = 'poNumbers';
  const handleBlur = () => {
    const poNumbers = form.getFieldValue(poNumbersNamePath);
    if (
      !isEqual(
        poNumbers,
        getPoNumbersFromWorkOrderGroup(
          isShipmentPage ? workOrderGroup : record?.group,
        ),
      )
    ) {
      legs?.forEach((leg) => {
        if (leg.ior?.ior) {
          // leg.ior.ior only takes IdInput, don't include the other values like
          // name and version
          leg.ior.ior = { id: leg.ior.ior.id } as Ior;
        }
        // We know poNumbers will be `string[]` since the Select component is
        // `tags` mode
        // TODO this logic would technically apply every poNumber to every leg.
        // Since we typically only ever currently work with one leg in the UI but
        // we may need to reconsider this in the future if there are multiple
        // legs editable in the UI.
        if (poNumbers) leg.poNumbers = poNumbers;
      });
      // The only thing we are updating are the shipment legs which have the
      // poNumbers
      void updateShipment({ variables: { id, version, input: { legs } } });
    }
  };
  if (!record) return null;
  return (
    <Tooltip
      placement="top"
      title={
        <StyledTitleDiv>
          <span>{displayName}</span>
          {!isShipmentPage && (
            // No need to show the sub text while on the shipment pge, it's
            // already editable
            <StyledTitleSubTextSpan>
              Editable from the shipment page
            </StyledTitleSubTextSpan>
          )}
        </StyledTitleDiv>
      }
    >
      {/* Need to define a new form since this component is not surrounded by the
    form AppRecord renders. We pass down the same form instance though that way
    the values are contained in the same form instance. */}
      <StyledForm form={form}>
        <StyledFormItem
          // TODO consider taking a label prop and then showing
          // this label when provided. There were some places in
          // legacy we rendered the label, but not on the shipment
          // page which is the only use case in CBMS currently
          // label="PO numbers(s)"
          name={poNumbersNamePath}
          getValueFromEvent={getValueFromEvent}
          $inline
          $removeSpaceBottom
          // In the form we store the poNumber tags (tags with key ===
          // PO_NUMBER) as `poNumbers` since we don't want to display all of
          // the WOG tags in the poNumbers box. We need to get such tags from
          // the WOG. The schema field (workOrderGroup vs group) is different
          // depending on whether it's a shipment or a WO
          initialValue={getPoNumbersFromWorkOrderGroup(
            isShipmentPage ? workOrderGroup : record?.group,
          )}
        >
          <Select
            mode="tags"
            maxTagCount={3}
            disabled={!isShipmentPage || isUpdateShipmentLoading}
            placeholder={displayName}
            notFoundContent="Type then press enter to add"
            tokenSeparators={[',', ' ', '/']}
            onBlur={handleBlur}
          />
        </StyledFormItem>
      </StyledForm>
    </Tooltip>
  );
};

export default PoNumbers;
