import React, { ComponentType, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  openModal as openModalAction,
  closeModal as closeModalAction,
  setModal as setModalAction,
} from 'actions';
import { ModalKey } from 'types';

type InputComponent = React.ElementType;

export interface WithModalProps {
  [key: string]: any;
}

const withModal =
  (key: ModalKey): ((C: InputComponent) => ComponentType<WithModalProps>) =>
  (C: InputComponent) => {
    const WithModal: React.FC<WithModalProps> = (props) => {
      const dispatch = useDispatch();

      const openModal = useCallback(() => {
        dispatch(openModalAction({ key }));
      }, [dispatch]);

      const closeModal = useCallback(() => {
        dispatch(closeModalAction({ key }));
      }, [dispatch]);

      const setModal = useCallback(
        (props: Parameters<typeof setModalAction>[0]['props']) => {
          dispatch(setModalAction({ key, props }));
        },
        [dispatch],
      );

      return (
        <C
          openModal={openModal}
          closeModal={closeModal}
          setModal={setModal}
          {...props}
        />
      );
    };

    return WithModal;
  };

export default withModal;
