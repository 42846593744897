import { AccountType, AnyObject } from '@xbcb/shared-types';
import { safeGetMessage } from '@xbcb/ui-utils';
import { WorkOrderConfirmationApprovalStatus } from '@xbcb/work-order-types';
import { AppRecordKebabMenuItem } from '../../types';
import { executeMutation } from 'libs/executeMutation';
import { isRecordDeleted } from 'libs/workOrderConditions';
import { reviewWorkOrderConfirmation } from 'libs/sharedQueries/queries/reviewWorkOrderConfirmation.query';

const getPendingEntryConfirmationId = (confirmations: AnyObject[]) => {
  const pendingConfirmation = confirmations?.find((woc) => {
    return woc.status === WorkOrderConfirmationApprovalStatus.PENDING;
  });
  return pendingConfirmation?.id;
};

export const approveEntryConfirmation: AppRecordKebabMenuItem = {
  text: ({ appRecordBundle }) =>
    safeGetMessage(appRecordBundle, 'approve_entry_confirmation'),
  key: 'approveEntryConfirmationRequest',

  action: async ({ record }) => {
    const pendingEntryConfirmationId = getPendingEntryConfirmationId(
      record?.confirmations,
    );
    await executeMutation({
      mutation: reviewWorkOrderConfirmation,
      variables: {
        id: pendingEntryConfirmationId,
        status: WorkOrderConfirmationApprovalStatus.APPROVED,
      },
      successMessage: 'Entry confirmation request is successfully approved.',
    });
  },
  show: ({ existingRecord, currentUser }) => {
    const pendingEntryConfirmationExists = getPendingEntryConfirmationId(
      existingRecord?.confirmations,
    );
    return (
      currentUser.accountType === AccountType.OPERATOR &&
      !isRecordDeleted(existingRecord) &&
      pendingEntryConfirmationExists
    );
  },
};
