import { Location } from 'history';
import { WorkOrderTask } from '__generated__/graphql';
import getQueryParameters from '../../../libs/getQueryParameters';

export const getWorkOrderTask = (
  location: Location,
  tasks: WorkOrderTask[],
): WorkOrderTask | undefined => {
  const queryParameters = getQueryParameters(location);
  const workOrderTaskType = queryParameters['task'];
  return tasks?.find(
    (task: WorkOrderTask) =>
      task.definition?.workOrderTaskType === workOrderTaskType,
  );
};
