import { WorkOrderTaskType } from '@xbcb/work-order-types';
import type { WorkOrder } from '@xbcb/api-gateway-client';
import { safeGetMessage, setModal } from '@xbcb/ui-utils';
import { AppRecordSubmitButton } from 'routes';
import { ModalKey } from 'types';
import { AppRecordOnConfirmPayload } from '../../types';
import {
  disableWorkOrderTaskCompletionButton,
  eu1PDocumentsPresent,
  showWorkOrderTaskCompletionButton,
} from 'libs/workOrderTaskCompletionButton';
import { findWorkOrderTaskAndModifyStepCompletion } from './findWorkOrderTaskAndModifyStepCompletion';

type CompleteWorkOrderTaskButtonProps = {
  skipValidationForWorkOrderTaskTypes?: WorkOrderTaskType[];
  skipUpdateRecordForWorkOrderTaskTypes?: WorkOrderTaskType[];
};

export const completeWorkOrderTaskButton = (
  props?: CompleteWorkOrderTaskButtonProps,
): AppRecordSubmitButton => {
  const completeTaskButton: AppRecordSubmitButton = {
    // this button is shown only while in the work order task view, and only for
    // tasks that have non-system only steps
    key: 'completeTask',
    text: ({ appRecordBundle }) =>
      safeGetMessage(appRecordBundle, 'complete_task'),
    show: ({
      existingRecord,
      workOrderTaskType,
      workOrderTaskEvaluatedState,
    }) => {
      return showWorkOrderTaskCompletionButton(
        existingRecord as WorkOrder,
        workOrderTaskType,
        workOrderTaskEvaluatedState,
      );
    },
    onConfirm: ({ dispatch, isValid, existingRecord, workOrderTaskType }) =>
      new Promise<AppRecordOnConfirmPayload>((resolve) => {
        if (isValid) {
          dispatch(
            setModal({
              key: ModalKey.COMPLETE_WORK_ORDER_TASK,
              props: {
                visible: true,
                success: false,
                workOrderTaskType: workOrderTaskType,
                workOrder: existingRecord,
                onOk: () => {
                  dispatch(
                    setModal({
                      key: ModalKey.COMPLETE_WORK_ORDER_TASK,
                      props: { submitting: true },
                    }),
                  );
                  resolve({ canceled: false });
                },
                onCancel: () => resolve({ canceled: true }),
              },
            }),
          );
        } else {
          return resolve({ canceled: true });
        }
      }),
    skipValidation: ({ workOrderTaskType }): boolean => {
      return Boolean(
        workOrderTaskType &&
          props?.skipValidationForWorkOrderTaskTypes?.length &&
          props?.skipValidationForWorkOrderTaskTypes.some(
            (taskType) => taskType === workOrderTaskType,
          ),
      );
    },
    skipUpdateRecord: ({ workOrderTaskType }): boolean => {
      return Boolean(
        workOrderTaskType &&
          props?.skipUpdateRecordForWorkOrderTaskTypes?.length &&
          props?.skipUpdateRecordForWorkOrderTaskTypes.some(
            (taskType) => taskType === workOrderTaskType,
          ),
      );
    },
    onSubmit: async ({ updatedRecord, workOrderTaskType, dispatch }) => {
      dispatch(
        setModal({
          key: ModalKey.COMPLETE_WORK_ORDER_TASK,
          props: { submitting: true },
        }),
      );
      if (workOrderTaskType) {
        try {
          await findWorkOrderTaskAndModifyStepCompletion({
            workOrder: updatedRecord,
            workOrderTaskType,
          });
          dispatch(
            setModal({
              key: ModalKey.COMPLETE_WORK_ORDER_TASK,
              props: { success: true, submitting: false },
            }),
          );
        } catch {
          dispatch(
            setModal({
              key: ModalKey.COMPLETE_WORK_ORDER_TASK,
              props: { submitting: false },
            }),
          );
        }
      }
      dispatch(
        setModal({
          key: ModalKey.COMPLETE_WORK_ORDER_TASK,
          props: { submitting: false },
        }),
      );
    },
    disableTooltipText: ({ existingRecord, workOrderTaskType }) => {
      if (!workOrderTaskType) return '';
      return (
        eu1PDocumentsPresent(existingRecord, workOrderTaskType).reason || ''
      );
    },
    disable: ({ existingRecord, workOrderTaskType }) => {
      if (workOrderTaskType) {
        return (
          disableWorkOrderTaskCompletionButton(
            existingRecord,
            workOrderTaskType,
          ) ||
          !eu1PDocumentsPresent(existingRecord, workOrderTaskType)
            .documentsPresent
        );
      }
      return false;
    },
  };
  return completeTaskButton;
};
