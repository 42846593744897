import { createAction } from '@reduxjs/toolkit';

export const SET_LOCALIZATION_CONTEXT = 'SET_LOCALIZATION_CONTEXT';

export interface SetLocalizationContextAction {
  locale: string;
}

export const setLocalizationContext =
  createAction<SetLocalizationContextAction>(SET_LOCALIZATION_CONTEXT);
