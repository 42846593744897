import { useMutation, gql } from '@apollo/client';
import { Form, message, Modal } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import type {
  UsIor,
  TemplateDocumentSignRequest,
} from '@xbcb/api-gateway-client';
import { getRecordIdTag } from '@xbcb/document-components';
import { DocumentTag } from '@xbcb/document-types';
import { POAMethod } from '@xbcb/party-types';
import { getOneQuery } from '@xbcb/shared-queries';
import { RecordType } from '@xbcb/shared-types';
import { CssSize } from '@xbcb/ui-types';
import {
  reportError,
  useModal,
  useSearchDocuments,
  safeGetMessage,
} from '@xbcb/ui-utils';
import RecordSelect from 'components/RecordSelect';
import controlClick from 'libs/controlClick';
import { getRecordPath } from 'libs/getRecordPath';
import { linkModalContent, openLinkModal } from 'libs/linkModal';
import { documentTypeToSignLinkTypeMap } from 'libs/documentTypeMap';
import { usIorFields, usIorFragments } from 'libs/sharedQueries';
import { ModalKey } from 'types';
import { StyledH4, StyledH3, StyledButton } from './styles';
import { useCurrentUser, useCustomsBrokerId } from 'libs/hooks';
import { useBundle } from '@amzn/react-arb-tools';

const getUsIorQuery = getOneQuery({
  recordName: RecordType.US_IOR,
  fields: usIorFields,
  fragments: usIorFragments,
});

const LINK_FORWARDER = gql`
  mutation linkForwarderToUsIor(
    $id: ID!
    $version: Int!
    $forwarderId: ID!
    $customsBrokerId: ID!
    $input: LinkForwarderToIorInput!
  ) {
    linkForwarderToUsIor(
      id: $id
      version: $version
      forwarderId: $forwarderId
      customsBrokerId: $customsBrokerId
      input: $input
    ) {
      templateDocumentSignRequest {
        url
        documentTags
      }
      linked
    }
  }
`;

type LinkForwarderToUsIorModalProps = { record: UsIor };

const LinkForwarderToUsIorModal: React.FC<LinkForwarderToUsIorModalProps> = ({
  record,
}) => {
  const [partyBundle] = useBundle('components.Party');
  const {
    iorNumber,
    name: importerName,
    id: importerId,
    version: importerVersion,
    shippers,
  } = record || {};
  const [form] = Form.useForm();
  const {
    closeModal,
    visible,
    modalProps,
  }: Omit<ReturnType<typeof useModal>, 'modalProps'> & {
    modalProps: {
      existingImporterId?: string;
      existingImporterName?: string;
      // if this is coming in as a prop, we're trying to activate a new importer
      // that is a duplicate. So we know which forwarder we want to link to.
      // TODO can we remove this?
      linkForwarderId?: string;
    };
  } = useModal(ModalKey.LINK_FORWARDER);
  const { existingImporterId, existingImporterName, linkForwarderId } =
    modalProps;
  const history = useHistory();
  const { pathname } = useLocation();
  const currentUser = useCurrentUser();
  const customsBrokerId = useCustomsBrokerId();

  const forwarderBaseNamePath = ['forwarder'];

  const operatorId = currentUser?.operator?.id;
  const recordIdTag = getRecordIdTag({
    pathname,
    recordId: importerId,
    operatorId,
  });

  const { documents } = useSearchDocuments({
    operatorId,
    tags: [recordIdTag],
  });

  const hasDirectPoa = documents.some(({ documentTags }) =>
    documentTags?.includes(DocumentTag.DIRECT_POWER_OF_ATTORNEY),
  );

  const handleClose = () => {
    closeModal();
    form.resetFields();
  };

  const [linkForwarder, { loading }] = useMutation(LINK_FORWARDER, {
    refetchQueries: () => [
      { query: getUsIorQuery, variables: { id: importerId } },
    ],
    onCompleted: async (data) => {
      const { templateDocumentSignRequest, linked } = data.linkForwarderToUsIor;
      const { url, documentTags } = (templateDocumentSignRequest ||
        {}) as TemplateDocumentSignRequest;

      if (linked)
        void message.success(safeGetMessage(partyBundle, 'forwarder_linked'));
      handleClose();

      if (url) {
        const modalContent = linkModalContent({
          link: url,
          type: documentTypeToSignLinkTypeMap.get(documentTags[0]),
          heading: safeGetMessage(partyBundle, 'sign_link_created'),
        });
        openLinkModal(modalContent);
      } else if (existingImporterId) {
        history.push(
          getRecordPath({
            recordType: RecordType.US_IOR,
            id: existingImporterId,
          }),
        );
      }
    },
  });
  const handleSubmit = async (evt: any) => {
    evt.preventDefault();

    const { forwarder } = form.getFieldsValue();

    try {
      await form.validateFields();
    } catch (e) {
      void message.error(
        safeGetMessage(partyBundle, 'please_fill_out_all_the_fields'),
      );
      return;
    }

    try {
      await linkForwarder({
        variables: {
          id: existingImporterId || importerId,
          version: importerVersion,
          forwarderId: forwarder?.id,
          customsBrokerId,
          input: {
            poaMethod: POAMethod.DIRECT,
            generateMasterPoa: false,
            // This occurs after trying to activate an importer that is a
            // duplicate of an already duplicated importer.
            terminateImporterId: existingImporterId ? importerId : undefined,
          },
        },
      });
    } catch (e) {
      reportError(e);
    }
  };
  return (
    <Modal
      maskClosable={!loading}
      open={visible}
      width={400}
      closable={true}
      footer={null}
      onCancel={handleClose}
      destroyOnClose
    >
      <Form form={form} initialValues={{ forwarder: { id: linkForwarderId } }}>
        <StyledH3>
          {existingImporterId ? (
            <b>
              {safeGetMessage(partyBundle, 'cannot_activate_importer_name', {
                importerName,
              })}
            </b>
          ) : (
            safeGetMessage(partyBundle, 'link_forwarded_to_importer_name', {
              importerName,
            })
          )}
        </StyledH3>
        <RecordSelect
          $itemSize={CssSize.SHORT_MEDIUM}
          recordType={RecordType.FORWARDER}
          form={form}
          localNamePath={forwarderBaseNamePath}
          fullNamePath={forwarderBaseNamePath}
          horizontal
          required
          isLoading={loading}
          getExcludedOptions={() => {
            const existingRelatedForwarderIds: string[] = [];
            shippers.nodes.forEach(({ forwarders }) =>
              forwarders.nodes.forEach(({ id }) =>
                existingRelatedForwarderIds.push(id),
              ),
            );
            return existingRelatedForwarderIds;
          }}
          readOnly={Boolean(linkForwarderId)}
          disabled={Boolean(linkForwarderId)}
          targetBlank
        />
        {existingImporterId ? (
          <>
            <StyledH4>
              {safeGetMessage(partyBundle, 'ior_number_already_used', {
                iorNumber,
                link: () => (
                  <a
                    target="blank"
                    onClick={(event) =>
                      controlClick({
                        history,
                        event,
                        path: getRecordPath({
                          recordType: RecordType.US_IOR,
                          id: existingImporterId,
                        }),
                        targetBlank: true,
                      })
                    }
                  >
                    {existingImporterName}
                  </a>
                ),
              })}
            </StyledH4>
            <StyledH4>
              {safeGetMessage(partyBundle, 'if_you_click_proceed_below', {
                existingImporterName,
              })}
            </StyledH4>
          </>
        ) : hasDirectPoa ? (
          <>
            <StyledH4>
              {safeGetMessage(partyBundle, 'already_has_direct_poa', {
                importerName,
              })}
            </StyledH4>
          </>
        ) : (
          <StyledH4>
            {safeGetMessage(partyBundle, 'does_not_have_poa', { importerName })}
          </StyledH4>
        )}
        <StyledButton
          onClick={handleSubmit}
          loading={loading}
          danger={existingImporterId ? true : false}
          htmlType="submit"
          size="large"
          type="primary"
        >
          {safeGetMessage(partyBundle, 'proceed_submit', {
            existingImporterId,
          })}
        </StyledButton>
      </Form>
    </Modal>
  );
};

export default LinkForwarderToUsIorModal;
