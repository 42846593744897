import React, { useState, ReactNode, useEffect } from 'react';
import { get, pick } from 'lodash';
import { useBundle } from '@amzn/react-arb-tools';
import handleRecordCreate from 'libs/handleRecordCreate';
import { Button, Form, message } from 'antd';
import AppPage from 'components/AppPage';
import { useMutation, useQuery } from '@apollo/client';
import {
  mutateRecord,
  retryMutation,
  updateQueryInCache,
} from '@xbcb/apollo-client';
import {
  createOneMutation,
  SearchQuery,
  SearchType,
  updateOneMutation,
  createSearchQueryVariables,
} from '@xbcb/shared-queries';
import { RecordType, Tag } from '@xbcb/shared-types';
import { pluralize } from '@xbcb/js-utils';
import { camelCase, paramCase } from 'change-case';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { AppRecordCreateProps } from 'routes';
import { cloneReactChildren } from 'libs/cloneReactChildren';
import { getBreadcrumbRoutes } from 'libs/getBreadcrumbRoutes';
import { useCurrentUser } from 'libs/hooks';
import { documentFragments } from 'libs/sharedQueries';
import { DataCyPrefix } from '@xbcb/ui-types';
import {
  reportError,
  documentInputFields,
  prepareInitialValues,
  safeGetMessage,
} from '@xbcb/ui-utils';
import { AppRecordCreateProvidedProps } from '../../routes/types';
import { getLocalizedRecordName } from 'libs/localizationHelpers';

type OnClick = (event: React.MouseEvent<HTMLElement>) => void;

const AppRecordCreate: React.FC<AppRecordCreateProps> = ({
  children,
  recordType, // e.g. usConsumptionEntry
  validateCreate,
  transformCreateRecordInput,
  fields,
  fragments,
  quickCreate,
  onCreate,
  hideCreateButton,
  shipperId,
}) => {
  const [sharedBundle] = useBundle('shared');
  const [recordNameBundle] = useBundle('types.shared.enums.RecordName');
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const { recordId } = useParams<{ recordId: string }>();
  const user = useCurrentUser();
  const { accountType } = user;
  const operatorId = user?.operator?.id;
  const idName = recordType ? `${camelCase(recordType)}Id` : '';

  const recordTypeName = getLocalizedRecordName(
    recordNameBundle,
    recordType,
    accountType,
  );

  const createMutation = createOneMutation({
    recordName: recordType,
    fields,
    fragments,
  });
  const [createRecord, { loading: createMutationLoading, error: createError }] =
    useMutation(createMutation);

  const updateMutation = updateOneMutation({
    recordName: RecordType.DOCUMENT,
    fields: '...documentFields',
    fragments: documentFragments,
  });
  const [
    updateDocument,
    { loading: updateMutationLoading, error: updateError },
  ] = useMutation(updateMutation);

  const searchQuery = SearchQuery({
    recordName: RecordType.DOCUMENT,
    fields: '...documentFields',
    fragments: documentFragments,
  });
  const searchQueryVariables = createSearchQueryVariables({
    deletedTimeExists: false,
    operatorId,
    sortOptions: [
      {
        field: 'createdTime',
      },
    ],
    tags: [
      {
        key: idName,
        value: recordId ? recordId : operatorId,
      },
    ],
  });
  const {
    data: searchData,
    loading: searchLoading,
    error: searchError,
  } = useQuery(searchQuery, {
    skip: !operatorId,
    variables: searchQueryVariables,
  });

  const initialValues = prepareInitialValues({ recordType });
  const routes = getBreadcrumbRoutes({ location });

  const childProps: AppRecordCreateProvidedProps = {
    form,
    editMode: true,
    accountType: user.accountType,
    hideDocs: quickCreate, // quick create opens the form in a simple modal and doesn't display content in the right-content of the full record page
    recordType,
    // See explanation inside packages/react/src/routes/types.ts for more information.
    clearTitle: () => setTitle(''),
  };

  const childrenWithProps = cloneReactChildren({
    children,
    childProps,
  });

  const [title, setTitle] = useState('');
  useEffect(() => setTitle(initialValues.name || ''), [initialValues.name]);
  useEffect(() => {
    // Reference: https://github.com/ant-design/ant-design/issues/7659#issuecomment-580688874
    document.querySelectorAll('.ant-select-selector input').forEach((e) => {
      e.setAttribute('autocomplete', 'none');
      // you can put any value but NOT "off" or "false" because they DO NOT work
    });
  });

  let titleReactNode: ReactNode;
  if (title.length === 0) {
    titleReactNode = <i>New {recordTypeName}</i>;
  } else if (title.length > 40) {
    titleReactNode = <span>{title.substr(0, 37) + '...'}</span>;
  } else {
    titleReactNode = <span>{title.substr(0, 37)}</span>;
  }

  const redirectUser = (id: string) => {
    const formValues = form.getFieldsValue();
    if (!quickCreate) {
      history.push(`/${pluralize(paramCase(recordType))}/${id}`);
      return;
    }
    const { name } = formValues;
    void message.success(
      `New ${recordTypeName} ${name ? `"${name}" ` : ''}created`,
      5.0,
    );
  };

  const replaceTags = (newId: string, tags: Tag[]) =>
    tags.reduce((arr: Tag[], { key, value }) => {
      let newValue = value;
      if (value === operatorId) newValue = newId;
      arr.push({ key, value: newValue });
      return arr;
    }, []);

  const mutationVariables = (record: any, newId: string) => {
    const documentInput = pick(record, documentInputFields);
    const { id, tags, version } = record;
    const newTags = replaceTags(newId, tags);
    return {
      id,
      version,
      input: {
        ...documentInput,
        tags: newTags,
      },
    };
  };
  const optimisticUpdateParams = {
    gqlQueryString: searchQuery,
    updatedQueryType: 'search' as SearchType,
    variables: searchQueryVariables,
  };
  const create: OnClick = async () => {
    let createdRecord: any;
    const { results } = get(searchData, [`searchDocuments`]) || {};
    try {
      createdRecord = await handleRecordCreate({
        form,
        recordType,
        validateCreate,
        transformCreateRecordInput,
        createRecord,
        user,
        shipperId,
        onCreate,
      });
      if (createdRecord) {
        const documentPromises: Promise<any>[] = [];
        results.forEach((document: any) => {
          documentPromises.push(
            mutateRecord({
              mutationType: 'update',
              mutation: updateDocument,
              mutationVariables: mutationVariables(document, createdRecord.id),
              recordType: RecordType.DOCUMENT,
              optimisticUpdateParams,
            }),
          );
        });
        await Promise.all(documentPromises);
        results.forEach((document: any) => {
          updateQueryInCache({
            crudType: 'delete',
            gqlQueryString: searchQuery,
            record: document,
            recordType: RecordType.DOCUMENT,
            updatedQueryType: 'search',
            variables: searchQueryVariables,
          });
        });
        redirectUser(createdRecord.id);
      }
    } catch (e) {
      // TODO switch to error code from back-end once it's implemented properly
      if ((e as any).message.includes('latest version')) {
        const retryMutationPromises: Promise<any>[] = [];
        results.forEach((document: any) => {
          retryMutationPromises.push(
            retryMutation({
              fields: '...documentFields',
              fragments: documentFragments,
              id: document.id,
              mutation: updateDocument,
              mutationType: 'update',
              mutationVariables: mutationVariables(document, createdRecord.id),
              recordType: RecordType.DOCUMENT,
              optimisticUpdateParams,
            }),
          );
        });
        await Promise.all(retryMutationPromises);
        results.forEach((document: any) =>
          updateQueryInCache({
            crudType: 'delete',
            gqlQueryString: searchQuery,
            record: document,
            recordType: RecordType.DOCUMENT,
            updatedQueryType: 'search',
            variables: searchQueryVariables,
          }),
        );
        redirectUser(createdRecord.id);
      } else {
        reportError(e);
        void message.error(
          safeGetMessage(sharedBundle, 'general_error_message'),
          5.0,
        );
      }
    }
  };

  return (
    <AppPage
      title={titleReactNode}
      routes={quickCreate ? undefined : routes}
      extra={
        hideCreateButton ? undefined : (
          <Button
            disabled={createMutationLoading}
            key="create"
            onClick={create}
            data-cy={DataCyPrefix.APP_RECORD_CREATE_BUTTON}
          >
            {safeGetMessage(sharedBundle, 'create')}
          </Button>
        )
      }
    >
      <Form
        form={form}
        initialValues={initialValues}
        onFieldsChange={(changedFields) => {
          changedFields.forEach(({ name: fieldName, value: fieldValue }) => {
            if (Array.isArray(fieldName) && fieldName[0] === 'name') {
              setTitle(fieldValue || '');
            }
          });
        }}
      >
        {childrenWithProps}
      </Form>
    </AppPage>
  );
};

export default AppRecordCreate;
