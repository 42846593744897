import { createReducer } from '@reduxjs/toolkit';
import { setLocalizationContext } from 'actions';

const defaultState = {
  locale: 'en-US',
};

const localizationContextReducer = createReducer(defaultState, (builder) =>
  builder.addCase(setLocalizationContext, (state, { payload }) => {
    state.locale = payload.locale;
  }),
);

export default localizationContextReducer;
