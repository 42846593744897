import { DateTime } from 'luxon';
import { showValidationErrors } from '@xbcb/ui-utils';
import { KebabMenuItemKey, AdditionalFormError } from '@xbcb/ui-types';
import { AppRecordKebabMenuItem } from '../../types';
import { client as apolloClient } from '@xbcb/apollo-client';
import { getOneQuery, createOneMutation } from '@xbcb/shared-queries';
import {
  WorkOrderTaskCustomField,
  CreateDeliveryOrderDocumentGenerationRequestInput,
  UsConsumptionEntry,
  UsType86Entry,
  Tag,
} from '__generated__/graphql';
import {
  AccountType,
  WorkOrderTaskCustomFieldType,
  RecordType,
} from '@xbcb/shared-types';
import { message } from 'antd';
import { WorkOrderMilestoneName } from '@xbcb/work-order-types';
import { isDeliveryOrderAutomationEnabled } from '../../../libs/featureFlags/deliveryOrderAutomationFeatureFlags';
import { isExamOrHoldWorkOrderTask } from './isExamOrHoldWorkOrderTask';
import { Location } from 'history';
import { getWorkOrderTask } from './getWorkOrderTask';
import { executeMutation } from 'libs/executeMutation';
import { DeliveryOrderDocumentGenerationType } from '@xbcb/document-types';
import SteamShipLineCodes from '@xbcb/codes/codes/SteamShipLineCodes.json';
import get from 'lodash/get';

const fields = 'id version';
const lastFreeDateAddDefault = 5;
const multiLineCommodity = 'FAK';

const createDeliveryOrderDocumentGenerationRequestMutation = createOneMutation({
  recordName: RecordType.DELIVERY_ORDER_DOCUMENT_GENERATION_REQUEST,
  fields,
});

const validateGenerateExamDeliveryOrderRequest = (
  record: UsConsumptionEntry | UsType86Entry,
  location: Location,
): AdditionalFormError[] => {
  const validationErrors: AdditionalFormError[] = [];

  if (!record.exam?.site) {
    validationErrors.push({
      title: 'Missing exam site code in Exam/Hold Tab',
      messages: ['Exam site code is required'],
    });
  }
  const workOrderTask = getWorkOrderTask(location, record?.tasks);

  if (workOrderTask?.customFields) {
    const terminalCode = getCustomsFieldValue(
      workOrderTask?.customFields,
      'Terminal',
      WorkOrderTaskCustomFieldType.Text,
    );
    if (!terminalCode) {
      validationErrors.push({
        title: 'Missing Terminal code in Custom Fields Tab',
        messages: ['Terminal code is required'],
      });
    }
  }
  return validationErrors;
};

const getCustomsFieldValue = (
  customsFields: WorkOrderTaskCustomField[],
  label: string,
  customFieldType: WorkOrderTaskCustomFieldType,
): string | Date | undefined => {
  const field = customsFields?.find(
    (field) =>
      field.label === label &&
      field.customFieldType === customFieldType &&
      (field.textValue || field.recordId || field.dateValue),
  );

  return field
    ? customFieldType === WorkOrderTaskCustomFieldType.Date
      ? field?.dateValue ?? undefined
      : field?.textValue ?? field?.recordId ?? undefined
    : undefined;
};

const generateExamDeliveryOrderDgrInput = async (
  record: UsConsumptionEntry | UsType86Entry,
  location: Location,
): Promise<CreateDeliveryOrderDocumentGenerationRequestInput> => {
  const {
    id,
    conveyance,
    masterBills,
    containers,
    entryNumber,
    exam,
    milestones,
  } = record;

  const task = getWorkOrderTask(location, record?.tasks);

  let firmsCode;
  let truckerName = '';
  let releaseDate;
  let tracker;
  if (task?.customFields?.length) {
    firmsCode = getCustomsFieldValue(
      task?.customFields,
      'Terminal',
      WorkOrderTaskCustomFieldType.Text,
    );

    const truckerId = getCustomsFieldValue(
      task?.customFields,
      'Trucker',
      WorkOrderTaskCustomFieldType.Trucker,
    );

    if (truckerId) {
      const getTruckerQuery = getOneQuery({
        recordName: RecordType.TRUCKER,
        fields: 'id name scac',
      });

      const { data: truckerData } = await apolloClient.query({
        query: getTruckerQuery,
        variables: { id: truckerId },
      });

      const { name, scac } = truckerData.trucker;
      truckerName =
        name && scac ? `${name} - ${scac}` : name ? name : scac ? scac : '';
    }

    tracker = getCustomsFieldValue(
      task?.customFields,
      'Tracker',
      WorkOrderTaskCustomFieldType.Text,
    );

    releaseDate = getCustomsFieldValue(
      task?.customFields,
      'Exam Release Date',
      WorkOrderTaskCustomFieldType.Date,
    );
  }

  const pendingPickUpCreatedTime = milestones?.find(
    (milestone) =>
      milestone.name ===
      WorkOrderMilestoneName.CUSTOMS_ENTRY_PENDING_PICKUP_AT_TERMINAL,
  )?.created.time;

  let lastFreeDate;
  if (pendingPickUpCreatedTime) {
    lastFreeDate = DateTime.fromISO(pendingPickUpCreatedTime)
      .setZone('utc')
      .plus({ days: lastFreeDateAddDefault })
      .toFormat('yyyy-MM-dd');
  }

  const products = record?.invoices?.[0]?.products;

  const commodity =
    products?.length === 1 && products[0]?.lines?.length === 1
      ? products[0].lines[0]?.description
      : multiLineCommodity;

  const tags: Tag[] = [
    {
      key: `${id.split('_')[0]}Id`,
      value: id,
    },
  ];

  if (record.group?.id) {
    tags.push({
      key: 'workOrderGroupId',
      value: record.group?.id,
    });
  }

  const clientIdentifier =
    record.group?.shipment?.clientIdentifier ||
    record.group?.shipment?.friendlyId;
  if (clientIdentifier) {
    tags.push({
      key: 'SHIPMENT_CLIENT_IDENTIFIER',
      value: clientIdentifier,
    });
  }
  if (record.group?.shipment?.id) {
    tags.push({
      key: 'shipmentId',
      value: record.group?.shipment?.id,
    });
  }

  let deliveryOrderContainer;
  if (containers?.length) {
    deliveryOrderContainer = containers.reduce(
      (acc, container) => {
        return {
          number: acc.number || container.number,
          seal: acc.seal || container.seal,
          type: acc.type || container.type,
          quantity: (acc.quantity as number) + (container.quantity ?? 0),
          weight: (acc.weight as number) + (container.weight ?? 0),
        };
      },
      { number: '', seal: '', type: '', quantity: 0, weight: 0 },
    );
  }

  const masterBill = masterBills && masterBills[0]?.number;
  const houseBill =
    masterBills &&
    masterBills[0]?.houseBills &&
    masterBills[0]?.houseBills[0]?.number;

  const steamShipLineScac = masterBill
    ? get(SteamShipLineCodes, [masterBill.substring(0, 4)])
    : '';

  const deliveryOrderConveyance = conveyance.conveyanceName;
  const facilityName = exam?.site;
  const arrivalDate = record?.arrival?.importDate;

  const input = {
    conveyance: {
      name: deliveryOrderConveyance,
    },
    masterBill,
    houseBill,
    destination: {
      facilityName,
    },
    entryNumber,
    containers: [
      {
        number: deliveryOrderContainer?.number,
        seal: deliveryOrderContainer?.seal,
        type: deliveryOrderContainer?.type,
        quantity: deliveryOrderContainer?.quantity,
        weight: deliveryOrderContainer?.weight,
      },
    ],
    pickup: {
      firmsCode,
      lastFreeDate,
    },
    truckerName,
    releaseDate,
    arrivalDate,
    commodity,
    steamShipLineScac,
    deliveryOrderType: DeliveryOrderDocumentGenerationType.EXAM_DELIVERY_ORDER,
    additionalFields: {
      tracker,
    },
    tags,
  } as CreateDeliveryOrderDocumentGenerationRequestInput;

  return input;
};

export const appRecordGenerateExamDeliveryOrderMenuItem: AppRecordKebabMenuItem =
  {
    text: 'Generate Exam Delivery Order',
    key: KebabMenuItemKey.GENERATE_EXAM_DELIVERY_ORDER,
    action: async ({ record, location }) => {
      const errors = validateGenerateExamDeliveryOrderRequest(record, location);
      if (errors.length) {
        showValidationErrors(errors);
        return;
      }
      try {
        const input = await generateExamDeliveryOrderDgrInput(record, location);
        await executeMutation({
          mutation: createDeliveryOrderDocumentGenerationRequestMutation,
          variables: { input },
          successMessage:
            'Successfully created Delivery Order Generation Request',
        });
      } catch (e) {
        void message.error(
          `Failed to Create Delivery Order Document Generation Request`,
        );
      }
    },
    show: ({ currentUser, workOrderTaskType }) =>
      currentUser.accountType === AccountType.OPERATOR &&
      isExamOrHoldWorkOrderTask(workOrderTaskType) &&
      isDeliveryOrderAutomationEnabled,
  };
