import React from 'react';
import { TooltipPlacement } from 'antd/lib/tooltip';
import { StyledList, StyledTooltip } from './styles';

type ArrayTooltipProps = {
  array: any[];
  placement?: TooltipPlacement;
  children?: React.ReactNode;
};

const limit = 15;
// may need a general refactor
// refactor to use styled-components w/ an $underline prop instead of having consumers set the class name. Better yet, handle the underline logic directly in this component and don't have a prop or a classname.
const ArrayTooltip: React.FC<ArrayTooltipProps> = ({
  array,
  children,
  placement = 'bottom',
}) => {
  if (!Array.isArray(array) || array.length < 1) return <>{children}</>;

  const list = (
    <StyledList>
      {array.slice(0, limit).map((element, i) => (
        <li key={i}>
          <span>{element}</span>
        </li>
      ))}
      {array.length > limit && (
        <li key="more">
          <span>{array.length - limit} more...</span>
        </li>
      )}
    </StyledList>
  );

  return (
    <StyledTooltip placement={placement} title={list}>
      <div>{children}</div>
    </StyledTooltip>
  );
};

export default ArrayTooltip;
