import React from 'react';
import { Checkbox, Select, Tooltip } from 'antd';
import {
  getCodes,
  getNumber,
  getAN,
  maxLength,
  selectFilter,
  show,
  shouldUpdate,
} from '@xbcb/ui-utils';
import { get } from 'lodash';
import Aoc from 'components/Aoc';
import drugAOCs from 'libs/drugAOCs';
import { FormInstance } from 'antd/lib/form';
import { CssSize, NamePath } from '@xbcb/ui-types';
import {
  StyledInfoCircleOutlined,
  StyledCheckboxFormItem,
  StyledFormItem,
} from './styles';
import { AocCode } from 'components/Aoc/aocFields';

import { clearFields } from 'libs/clearFields';
import { ModeOfTransport } from '@xbcb/shipment-types';

const { Option } = Select;

interface FdaAocsProps {
  form: FormInstance;
  fullNamePath: NamePath;
  localNamePath: NamePath;
  readOnly?: boolean;
  required?: boolean;
  disabled?: boolean;
  subjectToPN?: boolean;
  mot?: any;
}

// TODO Some of the FOO AOCs are not done, potentially others.
// TODO All the optional AOCs
const FdaAocs = ({
  form,
  localNamePath,
  fullNamePath,
  readOnly,
  required,
  disabled,
  subjectToPN,
  mot,
}: FdaAocsProps) => {
  const { getFieldValue } = form;
  const foodAcidityIndustryCodes = get(
    getCodes(),
    'CBP.CATAIR.PGA.foodAcidityIndustry',
    [],
  );
  const devAOCs = get(getCodes(), 'CBP.CATAIR.PGA.DEVAOC', {});
  const exemptions = Object.entries(
    get(getCodes(), 'USFDA.facilityExemption', {}),
  ).map(([code, description]) => (
    <Option key={code} value={code}>
      {`${code} - ${description}`}
    </Option>
  ));

  const programCode = getFieldValue([...fullNamePath, 'programCode']);
  const processingCode = getFieldValue([...fullNamePath, 'processingCode']);
  const iuc = getFieldValue([...fullNamePath, 'intendedUse']);
  const productCode = getFieldValue([...fullNamePath, 'fdaProductCode']) || '';

  const industryCode = productCode.slice(0, 2);
  const pic = productCode.charAt(4);
  const lacf =
    foodAcidityIndustryCodes.includes(industryCode) && ['F', 'E'].includes(pic);
  const af = foodAcidityIndustryCodes.includes(industryCode) && pic === 'I';
  const showFoodAcidity = lacf || af;
  const pnConditionsMet = getFieldValue([
    ...fullNamePath,
    'priorNotice',
    'conditionsNotApplicable',
  ]);
  const showFacilityRegistration = subjectToPN && !pnConditionsMet;
  const showRailCarNumber =
    subjectToPN && !pnConditionsMet && mot === ModeOfTransport.RAIL;
  const {
    showDrugRegistration,
    showDrugListing,
    showDrugApplication,
    showInvestigationalNewDrug,
  } = drugAOCs({ programCode, processingCode, iuc });

  const showAOCHeader =
    showDrugRegistration ||
    showDrugListing ||
    showDrugApplication ||
    showInvestigationalNewDrug ||
    showFacilityRegistration ||
    showRailCarNumber ||
    showFoodAcidity ||
    (programCode === 'DEV' && devAOCs[iuc]);

  const showDevAOC = (aoc: string, iuc: string) => {
    if (programCode !== 'DEV') return false;
    if (get(devAOCs, [iuc, 'M'], []).includes(aoc)) {
      return 'M';
    } else if (get(devAOCs, [iuc, 'C'], []).includes(aoc)) {
      return 'C';
    }
    return;
  };

  return (
    <div className="fda-form">
      {showAOCHeader && <h3>Affirmation of Compliance</h3>} {/* for PG23 */}
      {showDrugRegistration && (
        <Aoc
          code={AocCode.REG}
          form={form}
          localNamePath={localNamePath}
          fullNamePath={fullNamePath}
          required={required}
          size={CssSize.TINY_SHORT}
          inline={true}
          mask={getNumber(9)}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
      {showDrugListing && (
        <Aoc
          code={AocCode.DLS}
          form={form}
          localNamePath={localNamePath}
          fullNamePath={fullNamePath}
          required={required}
          size={CssSize.TINY_SHORT}
          inline={true}
          mask={getNumber(10)}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
      {showDrugApplication && (
        <Aoc
          code={AocCode.DA}
          form={form}
          localNamePath={localNamePath}
          fullNamePath={fullNamePath}
          required={required}
          size={CssSize.TINY_SHORT}
          inline={true}
          mask={getNumber(6)}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
      {showInvestigationalNewDrug && (
        <Aoc
          code={AocCode.IND}
          form={form}
          localNamePath={localNamePath}
          fullNamePath={fullNamePath}
          required={required}
          size={CssSize.SHORT}
          inline={true}
          mask={getNumber(6)}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
      {showFacilityRegistration && (
        <StyledFormItem
          shouldUpdate={shouldUpdate([
            [...fullNamePath, 'foodFacilityRegistration', 'exempt'],
          ])}
          required={required}
        >
          {() => {
            const facilityExempt = getFieldValue([
              ...fullNamePath,
              'foodFacilityRegistration',
              'exempt',
            ]);
            return (
              <>
                {facilityExempt ? (
                  <Aoc
                    code={AocCode.FME}
                    form={form}
                    localNamePath={localNamePath}
                    fullNamePath={fullNamePath}
                    required={required}
                    inline={true}
                    disabled={disabled}
                    readOnly={readOnly}
                  >
                    <Select
                      disabled={disabled}
                      showSearch
                      allowClear
                      filterOption={selectFilter}
                      notFoundContent="None Found"
                      dropdownMatchSelectWidth={false}
                    >
                      {exemptions}
                    </Select>
                  </Aoc>
                ) : (
                  <Aoc
                    code={processingCode === 'NSF' ? AocCode.GFR : AocCode.PFR}
                    form={form}
                    localNamePath={localNamePath}
                    fullNamePath={fullNamePath}
                    size={CssSize.SHORT}
                    inline={true}
                    mask={getNumber(11)}
                    disabled={disabled}
                    readOnly={readOnly}
                    required={required}
                  />
                )}
                {show({
                  readOnly,
                  form,
                  field: [
                    ...fullNamePath,
                    'foodFacilityRegistration',
                    'exempt',
                  ],
                }) && (
                  <StyledCheckboxFormItem
                    name={[
                      ...localNamePath,
                      'foodFacilityRegistration',
                      'exempt',
                    ]}
                    rules={[{ required: false }]}
                    $itemSize={CssSize.MICRO_TINY}
                    valuePropName="checked"
                    $readOnly={readOnly}
                    required={required}
                  >
                    <Checkbox
                      onChange={clearFields({
                        form,
                        fullNamePath: [
                          ...fullNamePath,
                          'foodFacilityRegistration',
                        ],
                        fields: ['exemption'],
                      })}
                      disabled={disabled}
                    >
                      Exempt?
                    </Checkbox>
                  </StyledCheckboxFormItem>
                )}
              </>
            );
          }}
        </StyledFormItem>
      )}
      {/* TODO Implement the ability to have multiple rail car numbers */}
      {showRailCarNumber && (
        <Aoc
          code={AocCode.RNO}
          form={form}
          localNamePath={localNamePath}
          fullNamePath={fullNamePath}
          required={required}
          mask={maxLength(14)}
          size={CssSize.TINY_SHORT}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
      {showFoodAcidity && (
        <>
          <h4 className="acidity-header">
            {af ? 'Acidified Food' : 'Low Acid Canned Food'}
            <Tooltip
              placement="right"
              title={
                af
                  ? 'An acidified food (AF) is a low-acid food to which acid(s) or acid food(s) are added and which has a finished equilibrium pH of 4.6 or below and a water activity (aw) greater than 0.85.'
                  : 'A low-acid canned food (LACF) is any food (other than alcoholic beverages) with a finished equilibrium pH greater than 4.6 and a water activity greater than 0.85, excluding tomatoes and tomato products having a finished equilibrium pH less than 4.7.'
              }
            >
              <StyledInfoCircleOutlined />
            </Tooltip>
          </h4>
          <Aoc
            code={AocCode.FCE}
            form={form}
            required={required}
            localNamePath={localNamePath}
            fullNamePath={fullNamePath}
            mask={getNumber(5)}
            size={CssSize.TINY_SHORT}
            inline={true}
            disabled={disabled}
            readOnly={readOnly}
          />
          <Aoc
            code={AocCode.SID}
            form={form}
            localNamePath={localNamePath}
            fullNamePath={fullNamePath}
            required={required}
            mask={getNumber(11)}
            size={CssSize.TINY_SHORT}
            inline={true}
            disabled={disabled}
            readOnly={readOnly}
          />
          {/* TODO: NEED TO IMPLEMENT CONTAINERS (PG28) for AF/LACF*/}
          <Aoc
            code={AocCode.VOL}
            form={form}
            localNamePath={localNamePath}
            fullNamePath={fullNamePath}
            size={CssSize.TINY_SHORT}
            inline={true}
            disabled={disabled}
            readOnly={readOnly}
            required={required}
          />
        </>
      )}
      {showDevAOC('PM#', iuc) && (
        <Aoc
          code={AocCode.PM}
          form={form}
          localNamePath={localNamePath}
          fullNamePath={fullNamePath}
          size={CssSize.MEDIUM}
          required={required && showDevAOC('PM#', iuc) === 'M'}
          mask={getAN(9)}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
      {showDevAOC('DDM', iuc) && (
        <Aoc
          code={AocCode.DDM}
          form={form}
          localNamePath={localNamePath}
          fullNamePath={fullNamePath}
          size={CssSize.SHORT}
          required={required && showDevAOC('DDM', iuc) === 'M'}
          mask={getNumber(10)}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
      {showDevAOC('DEV', iuc) && (
        <Aoc
          code={AocCode.DEV}
          form={form}
          localNamePath={localNamePath}
          fullNamePath={fullNamePath}
          mask={getNumber(10)}
          size={CssSize.SHORT_MEDIUM}
          required={required && showDevAOC('DEV', iuc) === 'M'}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
      {showDevAOC('DFE', iuc) && (
        <Aoc
          code={AocCode.DFE}
          form={form}
          localNamePath={localNamePath}
          fullNamePath={fullNamePath}
          mask={getNumber(10)}
          size={CssSize.SHORT_MEDIUM}
          required={required && showDevAOC('DFE', iuc) === 'M'}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
      {showDevAOC('IDE', iuc) && (
        <Aoc
          code={AocCode.IDE}
          form={form}
          localNamePath={localNamePath}
          fullNamePath={fullNamePath}
          size={CssSize.SHORT}
          required={required && showDevAOC('IDE', iuc) === 'M'}
          mask={getAN(7)}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
      {showDevAOC('LST', iuc) && (
        <Aoc
          code={AocCode.LST}
          form={form}
          localNamePath={localNamePath}
          fullNamePath={fullNamePath}
          size={CssSize.TINY_SHORT}
          required={required && showDevAOC('LST', iuc) === 'M'}
          mask={getAN(7)}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
      {showDevAOC('DA', iuc) && (
        <Aoc
          code={AocCode.DA}
          form={form}
          localNamePath={localNamePath}
          fullNamePath={fullNamePath}
          size={CssSize.MEDIUM}
          required={required && showDevAOC('DA', iuc) === 'M'}
          mask={getAN(8)}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
      {showDevAOC('IND', iuc) && (
        <Aoc
          code={AocCode.IND}
          form={form}
          localNamePath={localNamePath}
          fullNamePath={fullNamePath}
          size={CssSize.SHORT}
          required={required && showDevAOC('IND', iuc) === 'M'}
          mask={getNumber(6)}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
      {showDevAOC('LWC', iuc) && (
        <Aoc
          code={AocCode.LWC}
          form={form}
          localNamePath={localNamePath}
          fullNamePath={fullNamePath}
          required={required && showDevAOC('LWC', iuc) === 'M'}
          checkbox={true}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
      {showDevAOC('IRC', iuc) && (
        <Aoc
          code={AocCode.IRC}
          form={form}
          localNamePath={localNamePath}
          fullNamePath={fullNamePath}
          required={required && showDevAOC('IRC', iuc) === 'M'}
          checkbox={true}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
    </div>
  );
};

export default FdaAocs;
