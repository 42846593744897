import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { RootState, useTypedSelector } from 'reducers';
import { useSelector } from 'react-redux';
import {
  AccountType,
  KeywordSearchOperator,
  AnyObject,
} from '@xbcb/shared-types';
import {
  AuthenticationContext,
  Login,
  SearchCustomsBrokersQuery,
} from 'libs/sharedQueries';

import { isMonsEnv } from '@xbcb/ui-env';
import { isSSOEnabled } from 'libs/featureFlags';
import { getUserAccountType } from 'libs/getUserAccountType';

const isMons = isMonsEnv();

// TODO use autogenerated return type from apollo client when ready -> https://app.asana.com/0/1206674078292078/1209100648515887/f
export const useCurrentUser = () => {
  const location = useLocation();
  const currentActiveBrokerId: string | undefined = useSelector(
    (state: RootState) => state.activeBroker.currentActiveBroker,
  );
  const { customType = '', userToken = '' } = useTypedSelector(
    ({ userLogin }: RootState) => ({
      userToken: userLogin.userToken,
      customType: userLogin['custom:type'],
    }),
  );

  const shouldUseAuthenticationContext = isMons && isSSOEnabled;

  const activated = !['onboarding', 'pending'].includes(customType);
  const variables = isMons ? {} : { cognitoSub: userToken };
  const isMigratePage = location.pathname === '/migrate';
  const skipQueries = isMons ? false : !userToken || !activated;
  const { data, error, loading } = useQuery(
    shouldUseAuthenticationContext ? AuthenticationContext : Login,
    {
      variables,
      skip: isMigratePage || skipQueries, // AppHeader tries to do a query when not logged in
    },
  );

  const { data: queryResponse } = useQuery(SearchCustomsBrokersQuery, {
    variables: {
      input: {
        searchCriteria: {
          id: {
            operator: KeywordSearchOperator.ONE_OF,
            values: [currentActiveBrokerId],
          },
        },
      },
    },
    skip: skipQueries || !currentActiveBrokerId,
  });

  const user = shouldUseAuthenticationContext
    ? data?.authenticationContext?.authenticatedUserContext?.user
    : data?.login?.user;

  if (user && !error) {
    const customsBroker = queryResponse?.searchCustomsBrokers?.results[0];
    const assumedUser = user.assumedUser;
    const currentUser = assumedUser ? assumedUser : user;
    const accountType = getUserAccountType(currentUser as any);
    // If this is ever true then we probably added a new user type and missed adding it in the map in getUserAccountType
    if (!accountType) {
      throw new Error('Unknown account type for the user');
    }
    const account = currentUser[accountType.toLowerCase()];
    return {
      ...currentUser,
      ...(assumedUser && { originalUser: user }),
      accountType,
      account,
      ...(customsBroker &&
        Object.keys(customsBroker).length > 0 && { customsBroker }),
    };
  }
  if (loading) return { loading: true };

  // TODO temporary - decide how to handle
  // eslint-disable-next-line no-console
  if (error) console.error(error);
  return {};
};

export type CurrentUser = AnyObject & {
  id: string;
  accountType: AccountType;
  businessSupportTeams?: { nodes: { id: string }[] };
};
