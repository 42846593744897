import { Form } from 'antd';
import React, { memo } from 'react';
import { INLTSwitch as Switch } from '@xbcb/form-item-components';
import { StyledEpaFormDiv, StyledFlexDiv, StyledInfoTooltip } from './styles';
import VehiclesAndEnginesForm from 'components/VehiclesAndEnginesForm';
import { PgaFormBaseProps } from '../../types';
import { PgaFlag } from 'libs/pgaHelpers';
import { PesticidesForm } from '../PesticidesForm';

const EpaForm: React.FC<PgaFormBaseProps> = (props) => {
  const { pgaFlag, disabled, localNamePath, fullNamePath } = props;
  const form = Form.useFormInstance();
  const programCode = Form.useWatch([...fullNamePath, 'programCode'], form);

  return (
    <StyledEpaFormDiv>
      {PgaFlag.subjectToTsca(pgaFlag) && (
        <Switch
          field={[...localNamePath, 'isTscaCompliant']}
          disabled={disabled}
          onWord="Compliant"
          offWord="Not Applicable"
          label="TSCA Certification"
          aria-label="TSCA Certification"
        />
      )}
      <>
        {programCode === 'ODS' && (
          <StyledFlexDiv>
            <Switch
              disabled={disabled}
              field={[...localNamePath, 'isImageSubmitted']}
              onWord="Submitted"
              offWord="Not Applicable"
              label="Non Objection Notice"
            />
            <StyledInfoTooltip title="An image of the EPA-issued Non Objection Notice is conditionally required if the ODS has been previously used (i.e., not virgin)." />
          </StyledFlexDiv>
        )}
      </>

      {PgaFlag.subjectToVehiclesAndEngines(pgaFlag) && (
        <VehiclesAndEnginesForm {...props} />
      )}
      {PgaFlag.subjectToPesticides(pgaFlag) && <PesticidesForm {...props} />}
    </StyledEpaFormDiv>
  );
};

export default memo(EpaForm);
