import type { WorkOrderTask, OperatorUser } from '@xbcb/api-gateway-client';
import {
  OperatorUserTeamRole,
  SubjectMatterSubCategory,
} from '@xbcb/shared-types';
import { message } from 'antd';
import { findOperatorWithLeastTasks } from 'routes/appRecordRoutes/utils/findOperatorWithLeastTasks';

export const getEscalatedToOperatorUser = async (
  task: WorkOrderTask,
  isEscalatedToSme: boolean,
  category: string,
  subCategory: string,
): Promise<OperatorUser | undefined> => {
  if (isEscalatedToSme) {
    // Escalate to the SME with least number of tasks currently escalated
    const subjectMatterExperts =
      (task?.assignmentTeam?.subjectMatterExpertTeam?.operatorUsers?.edges
        .filter((edge) =>
          edge.relationship?.expertiseCategories?.some(
            (expertiseCategories) =>
              expertiseCategories.category === category &&
              (!expertiseCategories.subCategories?.length ||
                expertiseCategories.subCategories?.includes(
                  subCategory as SubjectMatterSubCategory,
                )),
          ),
        )
        .map((edge) => edge.node) as OperatorUser[]) || [];
    if (!subjectMatterExperts.length) {
      void message.error(
        'No Subject Matter Expert found to escalate this task.',
        5.0,
      );
      return undefined;
    }
    // Exclude Away SMEs
    const notAwaySubjectMatterExperts = subjectMatterExperts?.filter(
      (user) => !user.away,
    );
    if (!notAwaySubjectMatterExperts.length) {
      void message.error(
        'All Subject Matter Experts are currently away. Please try again later.',
        5.0,
      );
      return undefined;
    }
    return await findOperatorWithLeastTasks(notAwaySubjectMatterExperts);
  }
  // Escalate to the primary team lead, or secondary team lead if primary team lead is away
  const assigneeRelationship = task?.assignmentTeam?.operatorUsers?.edges.find(
    (edge) => edge.node?.id === task?.assignee?.id,
  )?.relationship;
  const assigneeGroupNumber =
    assigneeRelationship?.groups?.find((group) =>
      group.roles.includes(OperatorUserTeamRole.OPERATOR),
    )?.groupNumber ?? 0; // default to the first group if no group is the assignee is not on any group

  let primaryTeamLead;
  let secondaryTeamLead;
  task?.assignmentTeam?.operatorUsers?.edges.forEach((edge) => {
    const group = edge.relationship?.groups?.find(
      (group) => group.groupNumber === assigneeGroupNumber,
    );
    if (group) {
      if (group.roles?.includes(OperatorUserTeamRole.PRIMARY_TEAM_LEAD)) {
        primaryTeamLead = edge.node;
      }
      if (group.roles?.includes(OperatorUserTeamRole.SECONDARY_TEAM_LEAD)) {
        secondaryTeamLead = edge.node;
      }
    }
  });
  const escalatedTo =
    !primaryTeamLead || (primaryTeamLead as OperatorUser).away
      ? secondaryTeamLead
      : primaryTeamLead;
  if (!escalatedTo) {
    void message.error(
      'Team lead for this task is currently away. Please try again later.',
      5.0,
    );
    return undefined;
  }
  return escalatedTo;
};
