import { get } from 'lodash';
import { AnyObject } from '@xbcb/shared-types';
import { AocCode, aocFieldsMap } from 'components/Aoc/aocFields';
import {
  AdditionalFormError,
  NamePath,
  ValidationErrorType,
} from '@xbcb/ui-types';

export const hasTradeNameRequirement = ({
  performanceStandard,
  programCode,
  processingCode,
}: {
  performanceStandard?: boolean;
  programCode?: string;
  processingCode?: string;
}): boolean =>
  performanceStandard ||
  (programCode === 'TOB' && processingCode === 'CSU') ||
  (programCode === 'BIO' && processingCode !== 'HCT') || // trade name is optional for HCT (Human Cells & Tissue)
  (!!programCode && ['MVS', 'REI'].includes(programCode));

export const packagingMissingError = (
  tariffNamePath: NamePath,
): AdditionalFormError => ({
  title: 'Packaging Missing',
  type: ValidationErrorType.GENERAL,
  path: tariffNamePath,
  messages: ['At least one level of packaging is needed'],
});

/* FDA helpers */
export const hasDrugFormRequirement = (
  programCode: string,
  processingCode = '',
): boolean => programCode === 'DRU' && ['OTC', 'PRE'].includes(processingCode);

export const hasDeviceFormRequirement = (programCode: string): boolean =>
  programCode === 'RAD';

export const hasLaboratoryRequirement = (
  programCode?: string,
  processingCode?: string,
): boolean => programCode === 'TOB' && processingCode === 'INV';

type ProgramProcessingCodesPair = {
  program: string;
  processing?: string;
};

const INTENDED_USE_REQUIREMENTS: ProgramProcessingCodesPair[] = [
  { program: 'DEV' },
  { program: 'DRU' },
  { program: 'RAD' },
  { program: 'TOB', processing: 'INV' },
  { program: 'VME', processing: 'ADR' },
  { program: 'COS' },
  { program: 'FOO', processing: 'CCW' },
  { program: 'VME', processing: 'ADE' },
];

export const hasIntendedUseRequirement = (
  programCode?: string,
  processingCode?: string,
): boolean => {
  return INTENDED_USE_REQUIREMENTS.some(({ program, processing }) => {
    if (programCode !== program) return false;
    if (!processing) return true;
    if (processing.startsWith('!'))
      return processingCode !== processing.substring(1);
    return processingCode === processing;
  });
};

export const requireDevAOC =
  (programCode: string, intendedUseCode: string, devAOCs: AnyObject) =>
  (aoc: string): boolean | string => {
    if (programCode !== 'DEV') return false;
    if (get(devAOCs, [intendedUseCode, 'M'], []).includes(aoc)) {
      return 'M';
    } else if (get(devAOCs, [intendedUseCode, 'C'], []).includes(aoc)) {
      return 'C';
    }
    return false;
  };

export const getFoodAcidityAocFields = (
  showFoodAcidity: boolean,
): string[][] => {
  if (showFoodAcidity) {
    return [
      aocFieldsMap[AocCode.FCE],
      aocFieldsMap[AocCode.SID],
      aocFieldsMap[AocCode.VOL],
    ];
  }
  return [];
};

export const getDrugAocFields = ({
  showDrugRegistration,
  showDrugListing,
  showDrugApplication,
  showInvestigationalNewDrug,
}: {
  showDrugRegistration?: boolean;
  showDrugListing?: boolean;
  showDrugApplication?: boolean;
  showInvestigationalNewDrug?: boolean;
}): string[][] => {
  const drugAocFields: string[][] = [];
  if (showDrugRegistration) {
    drugAocFields.push(aocFieldsMap[AocCode.REG]);
  }
  if (showDrugListing) {
    drugAocFields.push(aocFieldsMap[AocCode.DLS]);
  }
  if (showDrugApplication) {
    drugAocFields.push(aocFieldsMap[AocCode.DA]);
  }
  if (showInvestigationalNewDrug) {
    drugAocFields.push(aocFieldsMap[AocCode.IND]);
  }
  return drugAocFields;
};

export const intendedUseManufacturerError = (
  tariffNamePath: NamePath,
  intendedUse: string,
): AdditionalFormError => ({
  title: 'Intended Use Code Manufacturer',
  type: ValidationErrorType.GENERAL,
  path: tariffNamePath,
  messages: [
    `Manufacturer must be foreign for Intended Use Code ${intendedUse}`,
  ],
});

/* HNT helpers */
export const hasGlazingManufacturerRequirement = (
  categoryCode?: string,
): boolean => categoryCode === 'REI7';

export const hasTireManufacturerRequirement = (
  categoryCode?: string,
): boolean => categoryCode === 'REI1';

export const hasManufactureMonthRequirement = (
  conformanceDeclaration: string,
): boolean => conformanceDeclaration === '1';

export const hasVehicleRequirement = (programCode?: string): boolean =>
  programCode === 'MVS';

/* The 3 requirements below are only applicable to MVS */
export const hasEmbassyCountryRequirement = (
  conformanceDeclaration: string,
): boolean => conformanceDeclaration === '6';

export const hasPassportRequirement = (
  conformanceDeclaration: string,
): boolean => conformanceDeclaration === '5';

export const hasDriveSideRequirement = (
  conformanceDeclaration: string,
): boolean => ['2B', '3'].includes(conformanceDeclaration);

export const hasRegisteredImporterNumberRequirement = (
  conformanceDeclaration: string,
): boolean => ['3', '13'].includes(conformanceDeclaration);

export const hasVehicleEligibilityNumberAndDotBondRequirement = (
  conformanceDeclaration: string,
): boolean => conformanceDeclaration === '3';

export const hasPermissionLetterRequirement = (
  conformanceDeclaration: string,
  iorIsVehicleManufacturer?: boolean,
): boolean =>
  (!iorIsVehicleManufacturer && conformanceDeclaration === '7') ||
  ['10', '13'].includes(conformanceDeclaration);

/* TTB helpers */
export const hasImporterPermitRequirement = (processingCode: string): boolean =>
  !['T51', 'T52', 'T54', 'T55'].includes(processingCode);

export const hasLabelApprovalRequirement = (programCode: string): boolean =>
  ['BER', 'DSP', 'WIN'].includes(programCode);

export const hasCountryRequirement = (programCode: string): boolean =>
  ['DSP', 'WIN'].includes(programCode);

export const hasQuantityRequirement = (processingCode: string): boolean =>
  ['T51', 'T52', 'T54', 'T55'].includes(processingCode);

/* APH helpers */
export const subjectToLaceyAct = (pgaFlag: string): boolean =>
  ['AL1', 'AL2'].includes(pgaFlag);

export const subjectToAphCore = (pgaFlag: string): boolean =>
  ['AQ1', 'AQ2'].includes(pgaFlag);
