import { Form, Button, message } from 'antd';
import { omit } from 'lodash';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useModal } from '@xbcb/ui-utils';
import { AnyObject, RecordType } from '@xbcb/shared-types';
import { createOneMutation } from '@xbcb/shared-queries';
import { productFragments } from '@xbcb/compliance-queries';
import ProductPageContents from 'components/ProductPageContents';
import { ModalKey } from 'types';
import { StyledModal } from './styles';
import handleRecordCreate from 'libs/handleRecordCreate';
import { useCurrentUser, usePrevious } from 'libs/hooks';
import { validateProductCreate } from 'libs/validateProduct';
import { transformProduct } from 'libs/formTransforms';
import AppPage from 'components/AppPage';

const CloneProductModal: React.FC = () => {
  const [form] = Form.useForm();
  const user = useCurrentUser();
  const history = useHistory();
  const recordType = RecordType.PRODUCT;
  const createMutation = createOneMutation({
    recordName: recordType,
    fields: `...productFragment`,
    fragments: productFragments,
  });
  const [createRecord] = useMutation(createMutation);

  const { visible, closeModal, modalProps } = useModal(ModalKey.CLONE_PRODUCT);
  const {
    onCreate,
    redirect,
    record,
  }: {
    onCreate: (value: AnyObject) => void;
    redirect: boolean;
    record: AnyObject;
  } = modalProps;
  const baseProduct = omit(record, [
    'id',
    'version',
    'created',
    'updated',
    'deleted',
  ]);

  const recordId = record?.id;

  const previousId = usePrevious(recordId);
  useEffect(() => {
    if (previousId !== recordId) {
      form.setFieldsValue(baseProduct);
    }
  }, [baseProduct, form, previousId, recordId]);

  return (
    <StyledModal
      maskClosable
      open={visible}
      width={1024}
      destroyOnClose
      onCancel={closeModal}
      footer={null}
    >
      <AppPage
        title={'Clone Product'}
        extra={
          <Button
            key="cloneProduct"
            type="primary"
            onClick={async () => {
              try {
                await handleRecordCreate({
                  form,
                  recordType,
                  transformCreateRecordInput: transformProduct.toCreateSchema,
                  validateCreate: validateProductCreate,
                  createRecord,
                  clean: true,
                  user,
                  onCreate,
                  history: redirect ? history : undefined,
                  closeModal,
                  closeOnFailure: false,
                });
              } catch (e) {
                void message.error('Unable to clone the product');
              }
            }}
          >
            Clone
          </Button>
        }
      >
        <Form form={form}>
          <ProductPageContents form={form} record={baseProduct} />
        </Form>
      </AppPage>
    </StyledModal>
  );
};

export default CloneProductModal;
