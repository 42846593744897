import type { AdCvdCase } from '@xbcb/api-gateway-client';
import { getEffectiveCaseRate } from '@xbcb/entry-utils/dist/lib/util';
import { CssSpace, NamePath } from '@xbcb/ui-types';
import { getCountryCodes } from '@xbcb/ui-utils';
import { Button, Collapse, Tooltip } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { formatCaseRate } from 'libs/format';
import { find, get } from 'lodash';
import React, { useState } from 'react';
import { StyledCaseDiv, StyledExclamationCircleOutlined } from './styles';

export type LineItemCaseInfoProps = {
  currentCase?: AdCvdCase;
  form: FormInstance;
  htsNumber: string;
  fullNamePath: NamePath;
  queryCbpForCaseInfo: (caseId: string) => Promise<void>;
};
const LineItemCaseInfo: React.FC<LineItemCaseInfoProps> = ({
  currentCase,
  form,
  htsNumber,
  fullNamePath,
  queryCbpForCaseInfo,
}) => {
  const { getFieldValue } = form;
  const [hasQueried, setHasQueried] = useState(false);

  const handleClick = (caseNumber: string) => {
    setHasQueried(true);
    void queryCbpForCaseInfo(caseNumber);
  };

  const updateQueryMessage = hasQueried
    ? 'Query submitted to CBP. Please wait a few minutes then reload the page.'
    : 'If the data displayed is out of date, trigger a new query to CBP to request updated values.';

  const entryDate =
    getFieldValue(['arrival', 'inBond', 'estimatedEntryDate']) ||
    getFieldValue(['arrival', 'importDate']);
  const currentRate =
    currentCase !== undefined &&
    getEffectiveCaseRate(entryDate, currentCase.rates);
  const effectiveDate = currentRate ? currentRate.effectiveDate : '';
  const caseTariff =
    currentCase &&
    find(currentCase.tariffs, {
      htsNumber: htsNumber.replace(/\./g, ''),
    });
  const countryCodes = getCountryCodes();
  const selectedCountryCode = getFieldValue([
    ...fullNamePath.slice(0, -1), // select country code is in previous layer
    'origin',
    'countryCode',
  ]);
  const errorIcon = <StyledExclamationCircleOutlined />;
  return (
    <StyledCaseDiv>
      {currentCase && (
        <div>
          <p>{currentCase.name}</p>
          {!caseTariff && (
            <p>
              {errorIcon}
              <b>DOES NOT APPLY TO HTS {htsNumber}</b>
            </p>
          )}
          {currentCase.countryCode !== selectedCountryCode && (
            <p>
              <b>
                {errorIcon}
                DOES NOT APPLY TO COO{' '}
                {get(
                  countryCodes,
                  [selectedCountryCode, 'name'],
                  '',
                ).toUpperCase()}
              </b>
            </p>
          )}
          <p>
            Country of origin:{' '}
            {get(countryCodes, [currentCase.countryCode, 'name'])}
          </p>
          <p>Manufacturer: {currentCase.manufacturerName}</p>
          <p>Seller: {currentCase.sellerName}</p>
          <p>
            Rate: <b>{formatCaseRate(currentRate) || 'No Valid Rate Found'}</b>{' '}
            (effective {effectiveDate})
          </p>
          <p>Effective: {currentCase.effectiveDate}</p>
          <Collapse bordered={false}>
            <Collapse.Panel header="Details" key="x">
              <p>
                Bond or cash: {get(currentCase, ['bondOrCash', 0, 'indicator'])}
              </p>
              <p>
                {htsNumber} added: {get(caseTariff, 'addedDate')}
              </p>
            </Collapse.Panel>
          </Collapse>

          <Tooltip title={updateQueryMessage}>
            <Button
              type="primary"
              disabled={hasQueried}
              onClick={() => handleClick(currentCase.caseNumber)}
              style={{ marginTop: CssSpace.SPACE_3 }}
            >
              Query CBP
            </Button>
          </Tooltip>
        </div>
      )}
    </StyledCaseDiv>
  );
};
export default LineItemCaseInfo;
