import type { Ior } from '@xbcb/api-gateway-client';
import { message } from 'antd';

// See: https://t.corp.amazon.com/V847929445

export const CORPORATE_CERTIFICATION_KEY = 'cc';
export const DIRECT_POWER_OF_ATTORNEY = 'direct-poa';

const ccIorValidator = (ior: Ior) => {
  const { stateCode, countryCode } =
    ior.addresses?.physical || ior.addresses?.mailing || {};
  if (!stateCode || !countryCode) {
    void message.error('IOR missing country or state');
    return false;
  }
  return true;
};

const directPoaIorValidator = (ior: Ior) => {
  const { stateCode, countryCode, address, city } =
    ior.addresses?.physical || ior.addresses?.mailing || {};
  if (!stateCode || !countryCode || !address || !city) {
    void message.error('IOR needs to have address, city, state, country.');
    return false;
  }
  return true;
};

const validatorMap = {
  [CORPORATE_CERTIFICATION_KEY]: ccIorValidator,
  [DIRECT_POWER_OF_ATTORNEY]: directPoaIorValidator,
};

export const validateIorSignLink = (ior: Ior, key: string) => {
  const validator = validatorMap[key as keyof typeof validatorMap];
  if (!validator) {
    return true;
  }
  return validator(ior);
};
