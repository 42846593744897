import { AppRecordSubmitButton } from 'routes';

import { message } from 'antd';
import { RecordType, TagKey } from '@xbcb/shared-types';
import { AppRecordOnConfirmPayload } from '../../types';
import { setModal, reportError, retryQueryUntilValid } from '@xbcb/ui-utils';
import { ModalKey } from 'types';
import { checkAccess, UserPermissions } from '@xbcb/client-utils';
import { executeMutation } from 'libs/executeMutation';
import {
  DeleteUsCbpEntryReleaseReason,
  UsCbpEntryReleaseStatus,
  UsCbpEntrySummaryStatus,
  WorkOrderBadge,
  WorkOrderMilestoneName,
  WorkOrderStatus,
} from '@xbcb/work-order-types';
import { paramCase } from 'change-case';
import {
  cloneWorkOrderMutation,
  createWorkOrderMilestoneMutation,
  deleteUsConsumptionEntryReleaseCbp,
  deleteUsConsumptionEntrySummaryCbp,
  updateUsConsumptionEntry,
} from 'libs/sharedQueries';
import { client } from '@xbcb/apollo-client';
import pluralize from 'pluralize';
import { constructDeleteUsConsumptionEntryReleaseCbpInput } from 'libs/deleteUsConsumptionEntryRelease';
import { getOneQueryLite } from '@xbcb/shared-queries';
import { History } from 'history';
import type { UsConsumptionEntry } from '@xbcb/api-gateway-client';

interface HandleRefileProps {
  updatedRecord: UsConsumptionEntry;
  history: History<unknown>;
  cbpCancellationReason: DeleteUsCbpEntryReleaseReason;
  releaseReplacementNumber?: string;
}

const messageDuration = 10;

export const handleRefile = async (
  handleRefileProps: HandleRefileProps,
): Promise<void> => {
  const {
    updatedRecord,
    history,
    cbpCancellationReason,
    releaseReplacementNumber,
  } = handleRefileProps;
  // Clone this entry first, as we would rather have two active than none active
  try {
    // Essentially executeMutation, but we need to grab the data returned to redirect
    const { data, errors } = await client.mutate({
      mutation: cloneWorkOrderMutation,
      variables: {
        cloneWorkOrderId: updatedRecord.id,
        input: { badge: WorkOrderBadge.REFILE },
      },
    });

    if (errors) {
      void message.error('Cloning work order failed', messageDuration);
      return;
    }

    void message.open({
      type: 'success',
      content: 'Successfully cloned entry, redirecting...',
      duration: messageDuration,
      key: 'clone-success',
    });

    const cloneId = data.cloneWorkOrder.clonedWorkOrder.id;

    // Perform updates on entry
    await executeMutation({
      mutation: createWorkOrderMilestoneMutation,
      variables: {
        idempotencyKey: `${updatedRecord.id}:${WorkOrderMilestoneName.WORK_ORDER_REPLACED}`,
        input: {
          allowDuplicate: false,
          name: WorkOrderMilestoneName.WORK_ORDER_REPLACED,
          workOrder: {
            id: updatedRecord.id,
            version: updatedRecord.version,
          },
        },
      },
      successMessage: `Work Order Replaced milestone on ${updatedRecord.entryNumber} successfully created`,
    });
    await executeMutation({
      mutation: updateUsConsumptionEntry,
      variables: {
        id: updatedRecord.id,
        version: updatedRecord.version,
        input: {
          status: WorkOrderStatus.REPLACED,
          tags: [
            ...(updatedRecord.tags || []),
            {
              key: TagKey.DUPLICATE_WO_ID,
              value: cloneId,
            },
          ],
          cbpCancellationReason: cbpCancellationReason,
          releaseReplacementNumber,
        },
        idempotencyKey: `${updatedRecord.id}_UPDATE_FOR_CANCELLATION`,
      },
      successMessage: `Original Work Order ${updatedRecord.entryNumber} successfully updated`,
    });

    // Redirects operator to clone entry
    history.push(
      `/${pluralize(paramCase(RecordType.US_CONSUMPTION_ENTRY))}/${cloneId}`,
    );

    message.destroy('clone-success');
  } catch (e) {
    reportError(e);
    return;
  }
};

interface HandleCancelProps {
  updatedRecord: UsConsumptionEntry;
  currentUser: any;
  cancellationReason: DeleteUsCbpEntryReleaseReason;
  cancelEntryCbpReference?: string;
}

export const handleCancel = async (
  handleCancelProps: HandleCancelProps,
): Promise<void> => {
  const { updatedRecord, cancellationReason, cancelEntryCbpReference } =
    handleCancelProps;
  if (
    updatedRecord.entryNumber &&
    updatedRecord.summaryStatus &&
    updatedRecord.summaryStatus !== UsCbpEntrySummaryStatus.DELETED
  ) {
    await executeMutation({
      mutation: deleteUsConsumptionEntrySummaryCbp,
      variables: { usConsumptionEntryId: updatedRecord.id },
      successMessage: `Successfully submitted delete Entry Summary ${updatedRecord.entryNumber} request to CBP`,
    });

    void message.open({
      type: 'loading',
      content: 'Awaiting response from CBP',
      duration: 20,
      key: 'awaiting-response',
    });
  } else {
    void message.error(
      `Entry Summary ${updatedRecord.entryNumber} not valid for deletion`,
      messageDuration,
    );
  }

  const requeryResult = await retryQueryUntilValid<{
    summaryStatus: UsCbpEntrySummaryStatus;
    releaseStatus: UsCbpEntryReleaseStatus;
  }>({
    client,
    recordName: RecordType.US_CONSUMPTION_ENTRY,
    query: getOneQueryLite({
      recordName: RecordType.US_CONSUMPTION_ENTRY,
      fields: 'summaryStatus releaseStatus',
      queryName: 'getEntrySummaryAndReleaseStatus',
    }),
    variables: {
      id: updatedRecord.id,
      version: undefined,
    },
    validator: ({ summaryStatus }) =>
      Boolean(
        summaryStatus &&
          [
            UsCbpEntrySummaryStatus.DELETED,
            UsCbpEntrySummaryStatus.REJECTED,
          ].includes(summaryStatus),
      ),
    crudOrSearchType: 'get',
    retries: 4,
    timeoutDelay: 5000,
  });

  message.destroy('awaiting-response');

  if (!requeryResult) {
    void message.error(
      `Error refetching entry data, could not submit delete Entry Release ${updatedRecord.entryNumber} request`,
      messageDuration,
    );
    return;
  }

  const { releaseStatus } = requeryResult;

  if (releaseStatus !== UsCbpEntryReleaseStatus.DELETED) {
    await executeMutation({
      mutation: deleteUsConsumptionEntryReleaseCbp,
      variables: {
        usConsumptionEntryId: updatedRecord.id,
        input: constructDeleteUsConsumptionEntryReleaseCbpInput(
          cancellationReason,
          cancelEntryCbpReference,
        ),
      },
      successMessage: `Successfully submitted delete Entry Release ${updatedRecord.entryNumber} request to CBP`,
    });
  } else {
    void message.error(
      `Entry Release ${updatedRecord.entryNumber} not valid for deletion`,
      messageDuration,
    );
  }
};

export const cancelEntryWithCbpButton = (): AppRecordSubmitButton => {
  const name = `Cancel Entry`;
  const workOrderButton: AppRecordSubmitButton = {
    key: `cancelEntryWithCbpButton`,
    text: () => name,
    show: ({ existingRecord, currentUser }) => {
      return (
        // From ops team, we only want to show button when entry is completed
        // and the summary status is filed to prevent accidentally being called
        // when summary is in another status
        existingRecord.status === WorkOrderStatus.COMPLETED &&
        existingRecord.summaryStatus === UsCbpEntrySummaryStatus.FILED &&
        !currentUser.loading &&
        checkAccess(
          currentUser,
          RecordType.US_CONSUMPTION_ENTRY,
          UserPermissions.UPDATE,
        ) &&
        checkAccess(
          currentUser,
          RecordType.US_CONSUMPTION_ENTRY,
          UserPermissions.DELETE_CBP_TRANSACTION,
        ) &&
        checkAccess(
          currentUser,
          RecordType.US_CONSUMPTION_ENTRY,
          UserPermissions.DELETE_RELEASE_CBP_TRANSACTION,
        )
      );
    },
    skipValidation: () => true,
    skipUpdateRecord: () => true,
    onConfirm: ({ dispatch, isValid }) =>
      new Promise<AppRecordOnConfirmPayload>((resolve) => {
        if (isValid) {
          dispatch(
            setModal({
              key: ModalKey.CANCEL_ENTRY_WITH_CBP,
              props: {
                visible: true,
                sendConfirmationDecision: resolve,
              },
            }),
          );
        } else {
          return resolve({ canceled: true });
        }
      }),
    onSubmit: async ({
      updatedRecord,
      confirmationData,
      history,
      currentUser,
    }) => {
      const { cancelEntryCbpReference, cancellationReason, isRefile } =
        confirmationData || {};

      if (
        !cancellationReason ||
        !(cancellationReason in DeleteUsCbpEntryReleaseReason)
      ) {
        void message.error('Please select a valid cancellation reason');
        return;
      }

      if (isRefile) {
        await handleRefile({
          updatedRecord: updatedRecord,
          history,
          cbpCancellationReason:
            cancellationReason as DeleteUsCbpEntryReleaseReason,
          releaseReplacementNumber: cancelEntryCbpReference,
        });
      } else {
        // Just a cancellation
        await handleCancel({
          updatedRecord,
          currentUser,
          cancellationReason:
            cancellationReason as DeleteUsCbpEntryReleaseReason,
          cancelEntryCbpReference,
        });
      }
    },
  };
  return workOrderButton;
};
