import React from 'react';
import { FormInstance } from 'antd/lib/form';
import { CssSize, NamePath } from '@xbcb/ui-types';
import { StyledUnitAssistInputMinusButtonWrapperDiv } from './styles';
import CurrencyField from '../CurrencyField';
import { LabelAbove } from '@xbcb/static-text-components';
import { MinusButton } from '@xbcb/button-components';

const lineLevelNamePathDepth = 4;

export interface UnitAssistInputProps {
  form: FormInstance;
  fullNamePath: NamePath;
  localNamePath: NamePath;
  max?: number;
  readOnly?: boolean;
  disabled?: boolean;
  step?: number;
  $itemSize?: string;
  precision?: number;
  required?: boolean;
  currency?: string;
  debounce?: boolean;
  currencyNamePath?: NamePath;
  min?: number;
}

const UnitAssistInput: React.FC<UnitAssistInputProps> = ({
  form,
  disabled = false,
  fullNamePath,
  localNamePath,
  required,
  readOnly,
  debounce,
  currency,
  currencyNamePath,
  $itemSize,
  max,
  min,
  precision,
}: UnitAssistInputProps) => {
  const { getFieldValue, setFields } = form;
  const lineLevelPath = [...fullNamePath.slice(0, lineLevelNamePathDepth)];

  // Set the line-level assist (applicable if we're on the entry page)
  const handleSetUnitAssist = (val = 0) => {
    const lineLevelQuantity = getFieldValue([...lineLevelPath, 'quantity']);

    if (lineLevelQuantity) {
      const lineLevelAssist = val * lineLevelQuantity;
      const lineLevelAssistPath = [...lineLevelPath, 'totalAssist', 'value'];
      setFields([
        {
          name: lineLevelAssistPath,
          value: lineLevelAssist,
        },
      ]);
    }
  };

  // Remove assist at the unit level and at the line-level (if we're on the entry page)
  const removeAssist = () => {
    setFields([
      {
        name: [...fullNamePath],
        value: undefined,
      },
      {
        name: [...lineLevelPath, 'totalAssist'],
        value: undefined,
      },
    ]);
  };

  return (
    <>
      <CurrencyField
        form={form}
        currency={currency}
        currencyNamePath={currencyNamePath}
        debounce={debounce}
        label={<LabelAbove text="Unit Assist" />}
        fullNamePath={fullNamePath}
        localNamePath={localNamePath}
        $itemSize={$itemSize || CssSize.TINY_SHORT}
        precision={precision}
        required={required}
        disabled={disabled}
        max={max}
        readOnly={readOnly}
        min={min}
        onChange={handleSetUnitAssist}
      />
      {!readOnly && (
        <StyledUnitAssistInputMinusButtonWrapperDiv>
          <MinusButton
            label="unit assist"
            firstRow={true}
            onRemove={removeAssist}
            className="container-minus"
            disabled={disabled}
          />
        </StyledUnitAssistInputMinusButtonWrapperDiv>
      )}
    </>
  );
};

export default UnitAssistInput;
