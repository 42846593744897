// TODO: this component needs a refactor

import React from 'react';
import { message, Form } from 'antd';
import { Select, FormItem } from '@xbcb/form-item-components';
import { selectFilter, getCodes, shouldUpdate } from '@xbcb/ui-utils';
import { get } from 'lodash';
import { validateFdaProductCode } from 'libs/validateFdaProductCode';
import {
  StyledProductCodeHeader,
  StyledLinkDiv,
  StyledProductCodeBuilderHeader,
  StyledButton,
  StyledFormItem,
} from './styles';

const { Option } = Select;

const display = (code: string, characters: number) =>
  code || Array(characters + 1).join('_');

const dash = (
  <Option key={'dash'} value="-">
    None
  </Option>
);

interface FdaProductCodeBuilderProps {
  allowedIndustryCodes?: any;
  onSubmit?: any;
  fdaProductCode: string;
}

const FdaProductCodeBuilder = ({
  allowedIndustryCodes,
  onSubmit,
  fdaProductCode = '',
}: FdaProductCodeBuilderProps) => {
  const FdaProductCodes = JSON.parse(
    JSON.stringify(getCodes().USFDA.product.industry),
  );
  // clean json
  Object.keys(FdaProductCodes).forEach((industryCode) => {
    if (
      (allowedIndustryCodes && !allowedIndustryCodes.includes(industryCode)) ||
      !FdaProductCodes[industryCode].class ||
      industryCode === '00'
    ) {
      delete FdaProductCodes[industryCode];
    } else {
      Object.keys(FdaProductCodes[industryCode].class).forEach((classCode) => {
        if (!FdaProductCodes[industryCode].class[classCode].product) {
          delete FdaProductCodes[industryCode].class[classCode];
        }
      });
    }
  });
  const industryCodes = FdaProductCodes;

  const [form] = Form.useForm();

  if (fdaProductCode) {
    form.setFields([
      { name: 'industry', value: fdaProductCode.slice(0, 2) || undefined },
      { name: 'class', value: fdaProductCode.slice(2, 3) || undefined },
      { name: 'subClass', value: fdaProductCode.slice(3, 4) || undefined },
      { name: 'pic', value: fdaProductCode.slice(4, 5) || undefined },
      { name: 'product', value: fdaProductCode.slice(5, 7) || undefined },
    ]);
  }

  const handleIndustryChange = (val: any) => {
    const productClass = get(industryCodes, [val, 'class']);
    const subClass = get(industryCodes, [val, 'subClass']);
    const PIC = get(industryCodes, [val, 'PIC']);
    let s;
    if (val && !subClass) s = '-';
    if (val && subClass && Object.keys(subClass).length === 1) {
      s = Object.keys(subClass)[0];
    }
    let p;
    if (val && !PIC) p = '-';
    if (val && PIC && Object.keys(PIC).length === 1) p = Object.keys(PIC)[0];
    let c;
    if (val && Object.keys(productClass).length === 1)
      c = Object.keys(productClass)[0];
    form.setFields([
      { name: ['class'], value: c },
      { name: ['subClass'], value: s },
      { name: ['pic'], value: p },
      { name: ['product'], value: undefined },
    ]);
  };

  const handleClassChange = (classCode: any, industryCode: string) => {
    const product = get(industryCodes, [
      industryCode,
      'class',
      classCode,
      'product',
    ]);
    let p = undefined;
    if (classCode && industryCode && Object.keys(product).length === 1)
      p = Object.keys(product)[0];
    form.setFields([{ name: ['product'], value: p }]);
  };

  const industryCode = () => form.getFieldValue('industry');
  const classCode = () => form.getFieldValue(['class']);
  const subClassCode = () => form.getFieldValue(['subClass']);
  const pic = () => form.getFieldValue(['pic']);
  const productCode = () => form.getFieldValue(['product']);

  const industryCodeObj = () => {
    return get(industryCodes, [industryCode()], {});
  };

  const classObj = () => get(industryCodeObj(), 'class', {});

  const fullCode = () =>
    `${display(industryCode(), 2)}${display(classCode(), 1)}${display(
      subClassCode(),
      1,
    )}${display(pic(), 1)}${display(productCode(), 2)}`;

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      const fdaProductCode = fullCode();
      validateFdaProductCode(fdaProductCode);
      onSubmit(fdaProductCode);
    } catch (e) {
      const errorMessage =
        e?.message || 'Please fill out all the fields marked in red first';
      void message.error(errorMessage);
    }
  };

  return (
    <Form form={form}>
      <StyledProductCodeBuilderHeader>
        FDA Product Code Builder
      </StyledProductCodeBuilderHeader>
      <StyledFormItem shouldUpdate={shouldUpdate([['industry']])}>
        {() => {
          const industryOptions = Object.keys(industryCodes).map(
            (industryCode) => (
              <Option key={industryCode} value={industryCode}>
                {industryCode} - {get(industryCodes, [industryCode, 'name'])}
              </Option>
            ),
          );
          return (
            <FormItem
              name="industry"
              rules={[{ required: true, message: ' ' }]}
            >
              <Select
                showSearch
                onChange={handleIndustryChange}
                placeholder="Industry"
                filterOption={selectFilter}
                notFoundContent="Industry Not Found"
                allowClear
              >
                {industryOptions}
              </Select>
            </FormItem>
          );
        }}
      </StyledFormItem>
      <StyledFormItem shouldUpdate={shouldUpdate([['industry']])}>
        {() => {
          const classOptions = Object.keys(classObj()).map(
            (classCode, index) => (
              <Option key={index} value={classCode}>
                {classCode} - {get(classObj(), [classCode, 'name'])}
              </Option>
            ),
          );
          return (
            <FormItem
              name={'class'}
              rules={[{ required: true, message: ' ' }]}
              noStyle
            >
              <Select
                showSearch
                placeholder="Class"
                onChange={(val) => {
                  handleClassChange(val, industryCode());
                }}
                filterOption={selectFilter}
                notFoundContent={
                  industryCode()
                    ? 'Class Not Found'
                    : 'Choose an Industry first'
                }
                allowClear
              >
                {classOptions}
              </Select>
            </FormItem>
          );
        }}
      </StyledFormItem>
      <StyledFormItem shouldUpdate={shouldUpdate([['industry']])}>
        {() => {
          const subClassObj = get(industryCodeObj(), 'subClass');
          const subClassOptions = subClassObj
            ? Object.keys(subClassObj).map((subClassCode, index) => (
                <Option key={index} value={subClassCode}>
                  {subClassCode} - {get(subClassObj, subClassCode)}
                </Option>
              ))
            : [dash];
          return (
            <FormItem
              name={'subClass'}
              rules={[{ required: true, message: ' ' }]}
            >
              <Select
                showSearch
                defaultActiveFirstOption
                placeholder="Sub Class"
                filterOption={selectFilter}
                notFoundContent={
                  industryCode()
                    ? 'Sub Class Not Found'
                    : 'Choose an Industry first'
                }
                allowClear
              >
                {subClassOptions}
              </Select>
            </FormItem>
          );
        }}
      </StyledFormItem>
      <StyledFormItem shouldUpdate={shouldUpdate([['industry']])}>
        {() => {
          const picObj = get(industryCodeObj(), 'PIC');
          const picOptions = picObj
            ? Object.keys(picObj).map((pic, index) => (
                <Option key={index} value={pic}>
                  {pic} - {get(picObj, pic)}
                </Option>
              ))
            : [dash];
          return (
            <FormItem name={'pic'} rules={[{ required: true, message: ' ' }]}>
              <Select
                showSearch
                placeholder="Product Identification"
                filterOption={selectFilter}
                notFoundContent={
                  industryCode()
                    ? 'Product Identification Not Found'
                    : 'Choose an Industry first'
                }
                allowClear
              >
                {picOptions}
              </Select>
            </FormItem>
          );
        }}
      </StyledFormItem>
      <StyledFormItem shouldUpdate={shouldUpdate([['class']])}>
        {() => {
          const productObj = get(classObj(), [classCode(), 'product'], {});
          const productOptions = Object.keys(productObj).map(
            (productCode, index) => (
              <Option key={index} value={productCode}>
                {productCode} - {get(productObj, productCode)}
              </Option>
            ),
          );
          return (
            <FormItem
              name={'product'}
              rules={[{ required: true, message: ' ' }]}
            >
              <Select
                showSearch
                placeholder="Product"
                filterOption={selectFilter}
                notFoundContent={
                  industryCode()
                    ? classCode()
                      ? 'Product Not Found'
                      : 'Choose a Class first'
                    : 'Choose an Industry first'
                }
                allowClear
              >
                {productOptions}
              </Select>
            </FormItem>
          );
        }}
      </StyledFormItem>
      <StyledFormItem
        shouldUpdate={shouldUpdate([
          ['industry'],
          ['class'],
          ['subClass'],
          ['pic'],
          ['product'],
        ])}
      >
        {() => <StyledProductCodeHeader>{fullCode()}</StyledProductCodeHeader>}
      </StyledFormItem>
      <StyledButton size="large" onClick={handleSubmit}>
        Submit
      </StyledButton>
      <StyledLinkDiv>
        <a
          href="https://www.fda.gov/forindustry/importprogram/resources/ucm462993.htm"
          target="_blank"
          rel="noreferrer"
        >
          What is a product code?
        </a>
        <a
          href="https://www.accessdata.fda.gov/scripts/ora/pcb/index.cfm?action=main.pcb"
          target="_blank"
          rel="noreferrer"
        >
          FDA's product code builder
        </a>
      </StyledLinkDiv>
    </Form>
  );
};

export default FdaProductCodeBuilder;
