import React, { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, Redirect, useParams } from 'react-router-dom';
import { AccountType, RecordType } from '@xbcb/shared-types';
import { fetchHtsHierarchy, menuCollapse } from 'actions';
import {
  AppRecordProps,
  appTableRoutes,
  appRecordRoutes,
  appRecordCreateRoutes,
  appRecordInterfaceRoutes,
  appRecordUploadRoutes,
  independentRoutes,
  ExtraHeaderContent,
} from 'routes';
import LoadingSpinner from 'components/LoadingSpinner/component';
import { recordTypeToPath } from 'libs/recordTypeToPath';
import { checkAccess, UserPermissions } from '@xbcb/client-utils';
import { getRedirectUrlForBroker } from 'libs/getRedirectUrlForBroker';
import { StyledLayout, StyledMenuButton } from './styles';
import AppSiderMenu from 'components/AppSiderMenu';
import { RootState } from 'reducers';
import { StyledSider } from 'components/AppSider';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { useApolloClient, QueryResult } from '@apollo/client';
import { initServices } from '../../services';
import { apolloLogout } from 'libs/logout';
import { customDomainRuntimeInfo } from 'libs/sharedQueries';
import { reportError, processCustomDomainRuntimeInfo } from '@xbcb/ui-utils';
import { MQTT_CONNECT } from 'actions/mqtt';
import { useCurrentUser } from 'libs/hooks';

const AppRecordCreate = lazy(
  () =>
    import(
      /* webpackChunkName: 'AppRecordCreate' */ 'components/AppRecordCreate'
    ),
);
const AppRecordUpload = lazy(
  () =>
    import(
      /* webpackChunkName: 'AppRecordUpload' */ 'components/AppRecordUpload'
    ),
);
const AppTable = lazy(
  () => import(/* webpackChunkName: 'AppTable' */ 'components/AppTable'),
);

const AppRecordInterface = lazy(
  () =>
    import(
      /* webpackChunkName: 'AppRecordInterface' */ 'components/AppRecordInterface'
    ),
);
const AppRecord = lazy(
  () => import(/* webpackChunkName: 'AppRecord' */ 'components/AppRecord'),
);

const LinkAction = lazy(
  () => import(/* webpackChunkName: 'LinkAction' */ 'components/LinkAction'),
);

const AppRecordKeyWrapper: React.FC<AppRecordProps> = (props) => {
  const { recordId } = useParams<{ recordId: string }>();
  return <AppRecord key={recordId} {...props} />;
};

const useFetchSecondaryData = () => {
  /* 
      Any requests for global data that are
      non-blocking (the app can function without them).
      These requests are made after initialization is complete.
    */
  const apolloClient = useApolloClient();
  const [hasFetchedHtsHierarchy, setHasFetchedHtsHierarchy] = useState(false);
  const [
    isLoadingCustomDomainRuntimeInfo,
    setIsLoadingCustomDomainRuntimeInfo,
  ] = useState(true);
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const userId = user?.id;
  useEffect(() => {
    const fetchSecondaryData = async () => {
      dispatch(fetchHtsHierarchy());
      setHasFetchedHtsHierarchy(true);

      if (isLoadingCustomDomainRuntimeInfo) {
        try {
          // get custom domain runtime info
          const runtimeInfoResult = await apolloClient.query({
            query: customDomainRuntimeInfo,
          });
          processCustomDomainRuntimeInfo(runtimeInfoResult as QueryResult);
          initServices(); // butter, sentry, etc... rely on environment variables from this runtime info
          if (userId) dispatch(MQTT_CONNECT(userId));
          setIsLoadingCustomDomainRuntimeInfo(false);
        } catch (e) {
          reportError(e);
          await apolloLogout();
        }
      }
    };
    if (!hasFetchedHtsHierarchy) {
      void fetchSecondaryData();
    }
  }, [
    hasFetchedHtsHierarchy,
    dispatch,
    userId,
    apolloClient,
    isLoadingCustomDomainRuntimeInfo,
  ]);
};

export interface ActivatedRoutesProps {
  accountType: AccountType;
  user: any;
}

export const ActivatedRoutes: React.FC<ActivatedRoutesProps> = ({
  accountType,
  user,
}) => {
  useFetchSecondaryData();

  const isCollapsed = useSelector(
    (state: RootState) => state.menuCollapse.menuCollapse,
  );

  const dispatch = useDispatch();

  const toggleMenu = () => {
    dispatch(menuCollapse(!isCollapsed));
  };

  return (
    <StyledLayout collapsed={isCollapsed}>
      <StyledSider collapsed={isCollapsed}>
        <StyledMenuButton onClick={toggleMenu}>
          {isCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </StyledMenuButton>
        <AppSiderMenu />
      </StyledSider>
      <Suspense fallback={<LoadingSpinner />}>
        <Switch>
          {appRecordCreateRoutes.map(
            ({ Page, blacklist, recordType, ...otherProps }) => {
              const path = `${recordTypeToPath(recordType)}/create`;
              return (
                <Route key={path} path={path}>
                  {blacklist && blacklist.accountTypes.includes(accountType) ? (
                    <Redirect to={blacklist.redirectTo} />
                  ) : (
                    <AppRecordCreate recordType={recordType} {...otherProps}>
                      <Page />
                    </AppRecordCreate>
                  )}
                </Route>
              );
            },
          )}
          {appRecordUploadRoutes.map(
            ({ Page, path: customPath, recordType, ...otherProps }) => {
              const defaultPath = `${recordTypeToPath(recordType)}`;
              const path = customPath || `${defaultPath}/upload`;
              return (
                <Route key={path} path={path}>
                  <AppRecordUpload recordType={recordType} {...otherProps}>
                    <Page />
                  </AppRecordUpload>
                </Route>
              );
            },
          )}
          {appRecordRoutes.map(
            ({ Page, recordType, path: customPath, ...otherProps }) => {
              const path =
                customPath || `${recordTypeToPath(recordType)}/:recordId`;
              return (
                <Route key={path} path={path}>
                  <AppRecordKeyWrapper recordType={recordType} {...otherProps}>
                    <Page />
                  </AppRecordKeyWrapper>
                </Route>
              );
            },
          )}
          {appTableRoutes.map(
            ({
              objectType,
              showCreateButton = () => false,
              showExportButton,
              showUploadButton,
              extraHeaderContent,
              getTitle,
              path: customPath,
              ...otherProps
            }) => {
              const headerContent: React.ReactNode[] | undefined =
                extraHeaderContent
                  ?.filter(
                    ({ shouldShow }) => !shouldShow || shouldShow({ user }),
                  )
                  .map(({ Component }) => Component);
              const path = customPath || `${recordTypeToPath(objectType)}`;
              const userCanCreate = checkAccess(
                user,
                objectType as RecordType,
                UserPermissions.CREATE,
              );

              const willShowCreateButton = () =>
                showCreateButton(user) && !user.loading && userCanCreate;

              const willShowUploadButton =
                showUploadButton &&
                !user.loading &&
                userCanCreate &&
                checkAccess(
                  user,
                  objectType as RecordType,
                  UserPermissions.UPDATE,
                );

              return (
                <Route key={path} path={path}>
                  <AppTable
                    showCreateButton={willShowCreateButton}
                    showUploadButton={willShowUploadButton}
                    extraHeaderContent={
                      headerContent as unknown as ExtraHeaderContent[]
                    }
                    objectType={objectType}
                    showExportButton={
                      showExportButton &&
                      !user.loading &&
                      checkAccess(
                        user,
                        objectType as RecordType,
                        UserPermissions.READ,
                        // TODO: Create a permission level for EXPORT
                      )
                    }
                    getTitle={getTitle}
                    {...otherProps}
                  />
                </Route>
              );
            },
          )}
          {appRecordInterfaceRoutes.map(
            ({ Page, recordInterfaceType, path, ...otherProps }) => {
              return (
                <Route key={path} path={path}>
                  <AppRecordInterface
                    recordInterfaceType={recordInterfaceType}
                    {...otherProps}
                  >
                    <Page />
                  </AppRecordInterface>
                </Route>
              );
            },
          )}
          {independentRoutes.map(({ path, Page }) => {
            return (
              <Route key={path} path={path}>
                <Page />
              </Route>
            );
          })}
          <Route path="/action">
            <LinkAction />
          </Route>

          <Redirect to={getRedirectUrlForBroker(user.customsBroker?.id)} />
        </Switch>
      </Suspense>
    </StyledLayout>
  );
};
export default ActivatedRoutes;
